<template>
  <fw-panel :title="title" :subtitle="subtitle" boxed="xs" custom-class="bg-white" class="my-5">
    <template v-if="canManage && view !== 'final'" #toolbar>
      <div class="flex gap-3">
        <fw-button
          v-if="results.length > 0 && canEdit"
          label="Anular ordenação"
          type="link"
          @click.native="undoSeriation"
        >
          Anular ordenação
        </fw-button>
        <b-dropdown
          v-if="results.length > 0 && canEdit"
          scrollable
          max-height="500"
          aria-role="list"
          position="is-bottom-left"
        >
          <fw-button-dropdown slot="trigger" type="xlight" class="flex flex-col" label="Selecionar candidatos por" />
          <b-dropdown-item
            v-for="(seltype, a) in selectionTypes"
            :key="a"
            aria-role="menu-item"
            :focusable="true"
            @click.native="changeSelectionType(a)"
          >
            <div class="font-medium">{{ selectionTypes[a].text }}</div>
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </template>

    <RecycleScroller
      v-if="results.length"
      v-slot="{ item }"
      :items="results"
      :item-size="50"
      :buffer="200"
      :key-field="'key'"
    >
      <div class="border-b border-gray-100 flex gap-2">
        <Person
          :key="item.key"
          :person="item"
          :selectable="false"
          :clickable="true"
          class="flex-1"
          :class="{ 'text-gray-500': item.rejected }"
          @click.native="openApplication(item.candidate_key)"
        >
          <template #leading>
            <div
              v-if="item.position"
              class="rounded-full border text-center mx-2 mr-3 mt-1 font-semibold"
              :class="{
                'border-gray-500 text-gray-500': item.rejected,
                'border-primary text-primary': !item.rejected,
              }"
              style="height: 28px; width: 28px; line-height: 26px"
            >
              {{ item.position }}
            </div>
          </template>

          <template #options>
            <div class="flex gap-5 items-center" @click.stop>
              <div
                :class="{ 'text-gray-500 font-medium': item.rejected, 'text-primary font-semibold': !item.rejected }"
              >
                {{ item.score | formatDecimal }}
              </div>
              <div>
                <OnoffOption
                  v-if="canManage && view !== 'final' && canEdit"
                  v-model="item.rejected"
                  :options="['Aprovado', 'Não aprovado']"
                  :disabled="!currentViewChecks.can_cancel_seriation"
                  :selected-option="item.rejected ? 'Não aprovado' : 'Aprovado'"
                  @input="toggleForNextStep(item.key, $event)"
                />
                <fw-tag v-else-if="!item.rejected" type="primary" class="w-28 flex items-center justify-center">
                  Aprovado
                </fw-tag>
                <fw-tag v-else-if="item.rejected" type="medium" class="w-28 flex items-center justify-center">
                  Não aprovado
                </fw-tag>
              </div>
            </div>
          </template>
        </Person>
      </div>
    </RecycleScroller>

    <fw-panel-info v-else-if="allCandidatesRejected && !mustRunSeriation" centered clean class="p-3">
      Não foi aprovado nenhum candidato.
    </fw-panel-info>
    <fw-panel-info v-else-if="view === 'final' && !canShowResults" centered clean class="p-3">
      A ordenação final de resultados ficará disponível depois de lacrada a útlima reunião da fase avaliativa.
    </fw-panel-info>
    <div v-else-if="mustRunSeriation" centered clean class="px-3 py-5 flex flex-col gap-3 items-center justify-center">
      <div>
        <fw-button v-if="canManage && view !== 'final' && mustRunSeriation" type="primary" @click.native="runSeriation">
          <template #icon>
            <fw-icon-robot class="w-5 h-5" />
          </template>
          Gerar ordenação
        </fw-button>
      </div>
      <div class="text-xs text-gray-500">
        Execute o algorítmo de seriação para gerar a ordenação dos candidatos nesta fase avaliativa.
      </div>
    </div>
    <fw-panel-info v-else centered empty class="p-3">
      Sem resultados para apresentar.
    </fw-panel-info>
  </fw-panel>
</template>

<script>
import Person from '@/fw-modules/fw-core-vue/ui/components/cards/Person.vue'
import OnoffOption from '@/fw-modules/fw-core-vue/ui/components/form/onoffOption'
import { RecycleScroller } from 'vue-virtual-scroller'

export default {
  components: {
    Person,
    RecycleScroller,
    OnoffOption,
  },

  props: {
    view: {
      type: String,
      default: 'curriculum',
    },

    subtitle: {
      type: String,
      default: '',
    },

    title: {
      type: String,
      default: 'Resultado intercalar',
    },

    results: {
      type: Array,
      default() {
        return []
      },
    },

    currentViewChecks: {
      type: Object,
      default() {
        return {}
      },
    },

    canShowResults: {
      type: Boolean,
      default: false,
    },

    showEdit: {
      type: Boolean,
      default: false,
    },

    allCandidatesRejected: {
      type: Boolean,
      default: false,
    },

    canManage: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      selectMode: 'position',
      selectModeIndex: 0,
      selectionTypes: [
        {
          text: 'posição',
          value: 'position',
        },
        {
          text: 'pontuação',
          value: 'score',
        },
      ],
    }
  },

  computed: {
    mustRunSeriation() {
      return (!this.results || this.results.length === 0) && this.currentViewChecks.can_run_seriation
    },
    canEdit() {
      return this.currentViewChecks.can_cancel_seriation || this.showEdit
    },
  },

  methods: {
    openApplication(candidateKey) {
      this.$emit('open-application', candidateKey)
    },

    runSeriation() {
      this.$emit('run-seriation')
    },

    undoSeriation() {
      this.$emit('undo-seriation')
    },

    toggleForNextStep(userKey, rawValue) {
      console.log('toggleForNextStep', rawValue)
      let forRejection = []
      if (rawValue === 1) {
        forRejection = this.results.filter(el => el.key == userKey || el.rejected)
      } else {
        forRejection = this.results.filter(el => el.rejected && el.key != userKey)
      }

      console.log('Not going to next step:', forRejection)
      this.$emit('reject-candidates', forRejection)
    },

    changeSelectionType(index) {
      let message = 'Número de candidados a selecionar:'
      this.selectModeIndex = index
      this.selectMode = this.selectionTypes[index].value
      if (this.selectMode == 'score') {
        message = 'Selecionar candidados com pontuação igual ou superior a:'
      }

      this.$buefy.dialog.prompt({
        message: message,
        inputAttrs: {
          type: 'number',
          placeholder: 'Candidatos a selecionar',
          min: this.selectMode == 'score' ? 0 : 1,
          max: this.selectMode == 'score' ? 100 : this.results.length,
          step: this.selectMode == 'score' ? 0.1 : 1,
          oninput: "validity.valid||(value='');",
        },
        trapFocus: true,
        onConfirm: value => this.runSelection(value),
      })
    },

    runSelection(value) {
      let rejectionList = []

      if (this.selectMode === 'position') {
        let number = parseInt(value)
        rejectionList = this.results.slice(number, this.results.length)
      } else {
        let number = parseFloat(value)
        rejectionList = this.results.filter(el => el.score < number)
      }

      console.log('Not going to next step:', rejectionList)
      this.$emit('reject-candidates', rejectionList)
    },
  },
}
</script>
