<template>
  <fw-panel v-if="stepData" :title="stepData.text" featured>
    <template #after-heading>
      <fw-tag v-if="isMeetingRunning" type="light-primary" size="sm" class="relative">
        <span class="mr-1">Reunião aberta</span> <fw-dot :ping="true" />
      </fw-tag>
      <fw-tag v-if="isMeetingEnded" type="light" size="sm">
        Reunião fechada
      </fw-tag>
      <fw-tag v-if="isMeetingReadyToBeStarted" type="border-light">
        Reunião por realizar
      </fw-tag>
    </template>

    <div v-if="!isMeetingEnded" class="flex gap-2 items-center">
      <div v-if="isMobile" class="font-semibold flex-1 items-center">
        <div class="font-semibold -mt-1 text-sm text-gray-500">
          {{ meetingTitle }}
        </div>
        {{ stepData.text }}
      </div>
      <div v-else class="font-medium flex-1 flex gap-5 items-center text-gray-500 text-sm">
        <div class="flex gap-2 items-center">
          <div v-if="meeting.date"><fw-icon-calendar class="w-5 h-5" /></div>
          <div v-if="meeting.date">{{ meeting.date | formatDayWithTime }}</div>
          <div v-if="meeting && meeting.location">
            <a v-if="locationIsLink" :href="meeting.location" target="_blank" class="flex  items-centergap-2"
              ><fw-icon-external-link class="flex-shrink-0 h-5 w-5 text-gray-400"></fw-icon-external-link
              >{{ meeting.location }}</a
            >
            <div v-else class="flex items-center gap-2">
              <fw-icon-map-pin2 class="flex-shrink-0 h-5 w-5 text-gray-400" /> {{ meeting.location }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <template #toolbar>
      <div v-if="!currentViewChecks.meeting_closed && canManage" class="flex gap-2">
        <fw-button
          v-if="currentViewChecks.meeting_ended"
          :type="'link'"
          :disabled="savingData"
          :loading="savingData"
          @click.native="reopenMeeting"
        >
          {{ $t('reopenMeeting') }}
        </fw-button>
        <fw-button
          v-else-if="
            currentViewChecks.can_start_meeting &&
              (stepData.value != 'seriation-interview' || interviewNotificationSent)
          "
          :type="'primary'"
          :disabled="savingData"
          :loading="savingData"
          @click.native="openMeeting"
        >
          {{ $t('openMeeting') }}
        </fw-button>
        <fw-button
          v-else-if="currentViewChecks.can_end_meeting"
          :type="'primary'"
          :disabled="savingData"
          :loading="savingData"
          @click.native="endMeeting"
        >
          {{ $t('endMeeting') }}
        </fw-button>
        <fw-button
          v-if="currentViewChecks.can_close_seriation_step"
          :type="'primary'"
          :disabled="savingData"
          :loading="savingData"
          @click.native="closeMeeting"
        >
          {{ $t('closeMeeting') }}
        </fw-button>
      </div>
      <div v-if="showDownloadMeetingDocument">
        <b-dropdown aria-role="list" position="is-bottom-left">
          <fw-button-dropdown slot="trigger" aria-role="listitem" type="xlight" :label="$t('download')" />
          <b-dropdown-item ref="actionitem" custom class="paddingless w-42" aria-role="menuitem">
            <fw-button label="Descarregar ata da reunião" @click.native="downloadMeetingTranscript('pt')">
              <template #icon>
                <fw-icon-download class="text-primary h-5 w-5"></fw-icon-download>
              </template>
              <template #default> {{ $t('meetingMinute') }} <span class="text-xs text-gray-500">(PT)</span> </template>
            </fw-button>
          </b-dropdown-item>
          <b-dropdown-item ref="actionitem" custom class="paddingless w-42" aria-role="menuitem">
            <fw-button label="Descarregar ata da reunião" @click.native="downloadMeetingTranscript('en')">
              <template #icon>
                <fw-icon-download class="text-primary h-5 w-5"></fw-icon-download>
              </template>
              <template #default> {{ $t('meetingMinute') }} <span class="text-xs text-gray-500">(EN)</span> </template>
            </fw-button>
          </b-dropdown-item>
        </b-dropdown>
      </div>
      <MeetingButton
        v-if="$store.state.showVirtualMeetings"
        :short="!showDownloadMeetingDocument"
        :meeting="meeting"
        :label="'Entrar na sala virtual do procedimento'"
      />
    </template>

    <template #footer>
      <fw-panel-info v-if="managerInfoText && canManage" type="orange" boxed clean class="mt-3">
        {{ managerInfoText }}
      </fw-panel-info>
      <fw-panel-info v-else-if="juryInfoText && !canManage" type="orange" clean class="mt-3">
        {{ juryInfoText }}
      </fw-panel-info>
    </template>
  </fw-panel>
</template>

<script>
import MeetingButton from '@/fw-modules/fw-core-vue/ui/components/buttons/MeetingButton'

export default {
  name: 'MeetingHeader',
  components: {
    MeetingButton,
  },
  props: {
    procedure: {
      type: Object,
      default: () => {
        return {}
      },
    },

    interviewApplications: {
      type: Array,
      default: () => {
        return []
      },
    },

    meeting: {
      type: Object,
      default: () => {
        return {
          date: null,
          location: null,
        }
      },
    },

    stepData: {
      type: Object,
      default: () => {
        return {}
      },
    },

    currentViewChecks: {
      type: Object,
      default: () => {
        return {}
      },
    },

    interviewNotificationSent: {
      type: Boolean,
      default: false,
    },

    allCandidatesRejected: {
      type: Boolean,
      default: false,
    },

    allCandidatesHavePreReview: {
      type: Boolean,
      default: false,
    },

    canManage: {
      type: Boolean,
      default: false,
    },

    isCurrentProcManager: {
      type: Boolean,
      default: false,
    },

    canSendInterviewNotification: {
      type: Boolean,
      default: false,
    },

    allApplicationsHaveInterviews: {
      type: Boolean,
      default: false,
    },

    savingData: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      minuteLanguage: 'pt',
    }
  },

  computed: {
    interviewDefined() {
      return Boolean(this.procedure.meetings.find(el => el.goal == 'seriation-interview'))
    },

    locationIsLink() {
      try {
        return Boolean(new URL(this.meeting.location))
      } catch (e) {
        return false
      }
    },

    showDownloadMeetingDocument() {
      return (
        this.currentViewChecks.is_seriation_ended &&
        this.currentViewChecks.meeting_closed &&
        !this.currentViewChecks.can_close_seriation_step
      )
    },

    isMobile() {
      return window.innerWidth <= 640
    },

    meetingTitle() {
      return this.meeting.date ? this.$options.filters.formatDayWithTime(this.meeting.date) : 'Reunião'
    },

    juryInfoText() {
      if (!this.meeting.date && !this.currentViewChecks.meeting_started) {
        return 'Aguarde que a reunião seja criada pelo secretariado ou presidente.'
      }

      if (!this.currentViewChecks.meeting_started) {
        return 'Aguarde pela reunião começar.'
      }

      if (this.currentViewChecks.meeting_closed) {
        return 'Reunião fechada.'
      }

      return ''
    },

    managerInfoText() {
      if (
        this.procedure.validations &&
        !this.procedure.validations.has_curriculum_update &&
        this.stepData.value == 'seriation-interview'
      ) {
        return this.isCurrentProcManager
          ? 'Falta publicar a ata Admissão/exclusão + AC para iniciar a reunião. Aceda ao separador "Publicações" para o fazer.'
          : 'Falta publicar a ata Admissão/exclusão + AC para iniciar a reunião, o que deverá ser feito pelo gestor do procedimento.'
      }

      if (this.allCandidatesRejected) {
        return 'Sem candidatos para avaliar.'
      }

      if (!this.allCandidatesHavePreReview && this.stepData.value == 'seriation-curriculum') {
        return 'Falta a pré-análise dos requisitos legais de admissão a todos os candidatos para iniciar a fase de avaliação.'
      }

      if (this.procedure.status == 'closed' || this.procedure.status == 'ended') {
        return ''
      }

      if (this.procedure.status != 'jury') {
        return 'Abra o procedimento ao júri para iniciar a fase de avaliação.'
      }

      const presidentPresent = this.currentViewChecks.jury
        ? this.procedure.presidents.filter(el => this.currentViewChecks.jury.includes(el.jury_key)).length
        : 0
      const minVogalsPresent = this.currentViewChecks.jury
        ? this.procedure.vogals.filter(el => this.currentViewChecks.jury.includes(el.jury_key)).length
        : 0

      const totalMembers = presidentPresent + minVogalsPresent

      if (!this.allApplicationsHaveInterviews && this.stepData.value == 'seriation-interview') {
        return 'Falta calendarizar as entrevista dos candidatos. Aceda ao separador "Calendarização" para definir a data, hora e local de cada entrevista.'
      }

      if (
        this.allApplicationsHaveInterviews &&
        this.stepData.value == 'seriation-interview' &&
        !this.interviewNotificationSent
      ) {
        return 'Falta enviar as convocatórias para entrevista aos candidatos.'
      }

      if (!this.currentViewChecks.meeting_started && !this.currentViewChecks.can_start_meeting && totalMembers < 3) {
        return 'Para iniciar a reunião é necessário a presença de 3 elementos do júri.'
      }

      if (this.currentViewChecks.meeting_started && !this.currentViewChecks.can_end_meeting && totalMembers < 3) {
        return 'Para terminar a reunião é necessário a presença de 3 elementos do júri.'
      }

      return ''
    },

    isMeetingReadyToBeStarted() {
      return !this.currentViewChecks.meeting_started && !this.currentViewChecks.meeting_closed
    },

    isMeetingRunning() {
      return this.currentViewChecks.meeting_started && !this.currentViewChecks.meeting_closed
    },

    isMeetingEnded() {
      return this.currentViewChecks.meeting_started && this.currentViewChecks.meeting_closed
    },
  },

  methods: {
    sendInterviewNotification() {
      this.$emit('send-interview-notification')
    },

    openMeeting() {
      this.$emit('start-meeting')
    },

    createMeeting(when = null) {
      this.$emit('create-meeting', when)
    },

    reopenMeeting() {
      this.$emit('reopen-meeting')
    },

    endMeeting() {
      this.$emit('end-meeting')
    },

    createInterviewMeeting() {
      this.$emit('create-interview-meeting')
    },

    closeMeeting() {
      this.$emit('close-meeting')
    },

    downloadMeetingTranscript(minuteLanguage) {
      this.$emit('download-minute', minuteLanguage)
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "download": "Descarregar",
    "meeting": "Reunião",
    "openMeeting": "Abrir reunião",
    "reopenMeeting": "Re-abrir seriação",
    "endMeeting": "Fechar seriação",
    "closeMeeting": "Terminar reunião",
    "sendInterviewNotification": "Enviar convocatórias para entrevista aos candidatos",
    "meetingMinute": "Ata editável da reunião"
  },
  "en": {
    "download": "Download",
    "meeting": "Meeting",
    "openMeeting": "Open meeting",
    "reopenMeeting": "Re-open seriation",
    "endMeeting": "Close seriation",
    "closeMeeting": "Close meeting",
    "sendInterviewNotification": "Send invitations for interviews to candidates",
    "meetingMinute": "Meeting minute"
  }
}
</i18n>
