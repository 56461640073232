<template>
  <sidebar-base>
    <button-sidebar
      label="Dashboard"
      icon="dashboard"
      :active="view == 'dashboard'"
      @click.native="goToView('dashboard')"
    />
    <button-sidebar :label="$t('procedure')" :active="procedureView" @click.native="goToView('metadata')">
      <template #svg>
        <fw-icon-article class="w-6 h-6" />
      </template>
      <template #suffix>
        <fw-icon-chevron-up v-if="procedureView" class="w-4 h-4" />
        <fw-icon-chevron-down v-else class="w-4 h-4" />
      </template>
    </button-sidebar>
    <VerticalSteps
      v-if="procedureView"
      :steps="procedureSteps"
      :current-step="view"
      class="mb-2"
      @clicked="goToView($event)"
    />

    <button-sidebar icon="people" :active="peopleView" :label="$t('people')" @click.native="goToView('jury')">
      <template #suffix>
        <fw-icon-chevron-up v-if="peopleView" class="w-4 h-4" />
        <fw-icon-chevron-down v-else class="w-4 h-4" />
      </template>
    </button-sidebar>

    <VerticalSteps
      v-if="peopleView"
      :steps="peopleSteps"
      :current-step="view"
      class="mb-2"
      @clicked="goToView($event)"
    />

    <button-sidebar
      icon="calendar-event"
      :active="view == 'calendar'"
      :label="$t('calendar')"
      @click.native="goToView('calendar')"
    />
    <button-sidebar
      :active="isEvaluationView"
      :disabled="!isEvaluationViewAllowed"
      :label="$t('evaluation')"
      @click.native="goToView('seriation-curriculum')"
    >
      <template #svg>
        <fw-icon-exam class="w-6 h-6" />
      </template>
      <template v-if="isEvaluationViewAllowed" #suffix>
        <fw-icon-chevron-up v-if="isEvaluationView" class="w-4 h-4" />
        <fw-icon-chevron-down v-else class="w-4 h-4" />
      </template>
    </button-sidebar>
    <VerticalSteps
      v-if="isEvaluationView && isEvaluationViewAllowed"
      class="mb-3"
      :steps="steps"
      :current-step="view"
      @clicked="goToView($event)"
    />
    <button-sidebar
      :label="$t('updates')"
      icon="clock-line"
      :active="view == 'updates'"
      @click.native="goToView('updates')"
    />
    <button-sidebar
      v-if="isCurrentProcManager || isAdminOrManager"
      icon="activity"
      :label="$t('activity')"
      :active="view == 'activity'"
      @click.native="goToView('activity')"
    />
    <button-sidebar
      v-if="isCurrentProcManager || isAdminOrManager"
      icon="message-sent"
      :label="$t('notifications')"
      :active="view == 'notifications'"
      @click.native="goToView('notifications')"
    />
  </sidebar-base>
</template>

<script>
import VerticalSteps from '@/fw-modules/fw-core-vue/ui/components/buttons/VerticalSteps'
import SidebarBase from '@/fw-modules/fw-core-vue/ui/components/sidebars/SidebarBase'
import ButtonSidebar from '@/fw-modules/fw-core-vue/ui/components/buttons/ButtonSidebar'

export default {
  name: 'SidebarProcedure',
  components: {
    ButtonSidebar,
    SidebarBase,
    VerticalSteps,
  },
  props: {
    procedure: {
      type: Object,
      default: function() {
        return {}
      },
    },
    view: {
      type: String,
      default: 'dashboard',
    },
    steps: {
      type: Array,
      default: function() {
        return []
      },
    },
    checks: {
      type: Object,
      default: function() {
        return {}
      },
    },
    procedureSteps: {
      type: Array,
      default: function() {
        return []
      },
    },
    peopleSteps: {
      type: Array,
      default: function() {
        return []
      },
    },
    isCurrentProcManager: {
      type: Boolean,
      default: false,
    },
    isAdminOrManager: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isEvaluationView() {
      return Boolean(
        ['seriation-curriculum', 'seriation-interview', 'final', 'complaints'].includes(this.view) &&
          this.steps &&
          this.steps.length
      )
    },

    isEvaluationViewAllowed() {
      return this.procedure.status != 'draft' && this.procedure.status != 'published' && !this.checks.canOpen
    },

    procedureView() {
      return this.procedureSteps.length > 0 && this.procedureSteps.findIndex(el => el.value === this.view) >= 0
    },

    peopleView() {
      return this.peopleSteps.length > 0 && this.peopleSteps.findIndex(el => el.value === this.view) >= 0
    },

    stepView() {
      return this.steps.length > 0 && this.steps.findIndex(el => el.value === this.view) >= 0
    },

    dirtyData() {
      return this.$store.state.dirtyData
    },

    user() {
      return this.$store.getters.getUser
    },
  },
  methods: {
    goToView(view) {
      this.$emit('go-to-view', view)
    },
  },
}
</script>

<style lang="postcss">
a span:last-child {
  @apply text-black;
}
.show-checked {
  background: #03a479
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1 1'%3E%3Cpath style='fill:%23fff' d='M 0.04038059,0.6267767 0.14644661,0.52071068 0.42928932,0.80355339 0.3232233,0.90961941 z M 0.21715729,0.80355339 0.85355339,0.16715729 0.95961941,0.2732233 0.3232233,0.90961941 z'%3E%3C/path%3E%3C/svg%3E")
    no-repeat center center;
  border-color: #03a479;
  background-size: 80%;
}

.flipanimation {
  animation: flipping 1.4s infinite;
}

@keyframes flipping {
  0% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
  50% {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }
  100% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
}
</style>

<i18n>
{
  "pt": {
    "procedure": "Procedimento",
    "people": "Pessoas",
    "calendar": "Calendarização",
    "evaluation": "Avaliação",
    "updates": "Publicações",
    "complaints": "Audiência(s) de interessados",
    "activity": "Atividade",
    "notifications": "Notificações",
    "conflitOfInterest": "Declaração conflitos de interesse"
  },
  "en": {
    "procedure": "Procedure",
    "people": "People",
    "calendar": "Calendar",
    "evaluation": "Evaluation",
    "updates": "Publications",
    "complaints": "Stakeholder Hearing",
    "activity": "Activity",
    "notifications": "Notifications",
    "conflitOfInterest": "Conflict of interest declaration"
  }
}
</i18n>
