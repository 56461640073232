<template>
  <div>
    <b-tooltip v-if="hasNoConflitOfInterest" :label="$t('noConflitOfInterests')" type="is-primary" position="is-left">
      <fw-tag type="light-primary" class="w-24 flex justify-center">
        <fw-icon-info class="w-4 h-4 mr-2" />
        <fw-icon-check class="w-4 h-4" />
      </fw-tag>
    </b-tooltip>
    <b-tooltip
      v-else-if="hasNoConflitOfInterest == false"
      :label="$t('hasConflitOfInterests')"
      type="is-dark"
      position="is-left"
    >
      <fw-tag type="light-danger" class="w-24 flex justify-center">
        <fw-icon-info class="w-4 h-4 mr-2" />
        <fw-icon-alert class="w-4 h-4" />
      </fw-tag>
    </b-tooltip>
    <b-tooltip v-else :label="$t('undefinedConflitOfInterests')" type="is-light" position="is-left">
      <fw-tag type="light-orange" class="w-24 flex justify-center"><fw-icon-info class="w-4 h-4 mr-2" /> ? </fw-tag>
    </b-tooltip>
  </div>
</template>

<script>
export default {
  name: 'TagConflitsOfInterest',
  props: {
    hasNoConflitOfInterest: {
      type: [Boolean, String],
      default: null,
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "hasConflitOfInterests": "Com conflitos de interesse",
    "noConflitOfInterests": "Sem conflitos de interesse",
    "undefinedConflitOfInterests": "Ainda não indicou se tem conflitos de interesse"
  },
  "en": {
    "hasConflitOfInterests": "Has conflicts of interest",
    "noConflitOfInterests": "No conflicts of interest",
    "undefinedConflitOfInterests": "No response yet"
  }
}
</i18n>
