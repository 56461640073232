<template>
  <div>
    <fw-heading size="h2" class="mb-5">{{ $t('result') }}</fw-heading>
    <SeriationResults
      view="final"
      :title="$t('finalPositions')"
      :can-manage="canManage"
      :results="results.final"
      :can-show-results="canShowFinalResults"
      @open-application="openApplication"
    />
    <SeriationResults
      v-if="results.interview"
      view="interview"
      :title="$t('interviewResults')"
      :can-manage="canManage"
      :results="results.interview"
      :can-show-results="canShowFinalResults"
      @open-application="openApplication"
    />
    <SeriationResults
      v-if="results.curriculum"
      view="curriculum"
      :title="$t('curriculumResults')"
      :can-manage="canManage"
      :results="results.curriculum"
      :can-show-results="canShowFinalResults"
      @open-application="openApplication"
    />
  </div>
</template>

<script>
import SeriationResults from '@/components/panels/SeriationResults'

export default {
  components: {
    SeriationResults,
  },

  props: {
    results: {
      type: Object,
      default() {
        return {}
      },
    },

    canManage: {
      type: Boolean,
      default: false,
    },

    canShowFinalResults: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    openApplication(candidateKey) {
      this.$emit('open-application', candidateKey)
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "result": "Resultado",
    "curriculumResults": "Resultado da avaliação curricular",
    "interviewResults": "Resultado das entrevistas",
    "finalPositions": "Lista de ordenação final"
  },
  "en": {
    "result": "Result",
    "curriculumResults": "Curriculum results",
    "interviewResults": "Interview results",
    "finalPositions": "Final sorting list"
  }
}
</i18n>
