<template>
  <section v-if="procedure" class="relative">
    <fw-panel :title="$t('criteria')" featured :loading="savingData" after-loading-checked>
      <template #toolbar>
        <div class="flex gap-1 items-center">
          <div v-if="editMode && $v.$error" class="p-1 mx-5 text-red-500 flex items-center gap-1 text-sm font-medium">
            <fw-icon-error-warning class="w-6 h-6"></fw-icon-error-warning>
            <span class="hidden lg:block">{{ $t('thereAreErrors') }}</span>
          </div>
          <div v-if="canEdit" class="flex gap-5">
            <fw-button
              :type="!editMode ? 'link' : 'link-light'"
              :disabled="savingData"
              :loading="savingData"
              @click.native="toggleEditMode()"
            >
              {{ editMode ? $t('close') : $t('edit') }}
            </fw-button>
            <fw-button
              v-if="editMode"
              type="primary"
              :disabled="savingData"
              :loading="savingData"
              @click.native="saveProc()"
              >{{ $t('save') }}</fw-button
            >
          </div>
        </div>
      </template>
    </fw-panel>

    <fw-panel
      v-if="isScholarship"
      :title="$t('selectionMethods')"
      :boxed="editMode ? null : 'sm'"
      custom-class="bg-white"
      class="my-5"
    >
      <div v-if="!editMode">
        <div class="flex gap-3">
          <fw-tag size="sm" type="primary"
            >{{ $t('curriculumEval') }}
            <span class="font-bold opacity-80 ml-2">{{ criteriaWeight.curriculum_weight }}%</span>
          </fw-tag>
          <fw-tag v-if="criteriaWeight.with_interview" size="sm" type="primary">
            {{ $t('interview') }} <span class="font-bold opacity-80 ml-2">{{ criteriaWeight.interview_weight }}%</span>
          </fw-tag>
        </div>
      </div>
      <OptionsCard
        v-else
        :options="[
          { text: $t('curriculumEval'), value: 'false', right_text: '100%' },
          {
            text: `${$t('curriculumEval')} + ${$t('interview')}`,
            value: 'true',
            right_text: interviewOptionsText,
          },
        ]"
        :disabled="!canEdit || !editMode"
        :selected="criteriaWeight.with_interview.toString()"
        @selected="selectMethod"
      ></OptionsCard>
    </fw-panel>

    <fw-panel
      v-if="isScholarship && criteriaWeight.with_interview && canEdit"
      :title="$t('weight')"
      boxed="sm"
      custom-class="bg-white"
    >
      <div>
        <div class="flex w-full flex-row gap-10">
          <div class="w-1-4">
            <fw-label marginless class="mb-2">{{ $t('curriculumEval') }}</fw-label>
            <div v-if="!editMode">{{ criteriaWeight.curriculum_weight }} %</div>
            <NumberInput
              v-else
              v-model="$v.criteriaWeight.curriculum_weight.$model"
              :max="100"
              :step="1"
              :min="0"
              :disabled="!canEdit || !editMode"
              @input="pedagogicalCapacityChanged"
            >
              <template #suffix>%</template>
            </NumberInput>
            <fw-tip v-if="$v.criteriaWeight.curriculum_weight.$error" error>
              {{ $t('enterValidValue') }}
            </fw-tip>
          </div>
          <div class="flex-1 flex items-end">
            <!-- "lazy" to update v-model only when dragging is finished. -->
            <b-slider
              v-model="$v.criteriaWeight.curriculum_weight.$model"
              class="hidden sm:block -mt-0.5"
              :min="0"
              :max="100"
              :custom-formatter="val => val + '/' + (100 - val) + '%'"
              :disabled="!canEdit || !editMode"
              lazy
              @change="sliderChanged"
            ></b-slider>
          </div>
          <div class="w-1-4">
            <fw-label marginless class="mb-2">{{ $t('interview') }}</fw-label>
            <div v-if="!editMode">{{ criteriaWeight.interview_weight }} %</div>
            <NumberInput
              v-else
              v-model="$v.criteriaWeight.interview_weight.$model"
              :step="1"
              :disabled="!canEdit || !editMode"
              :max="100"
              :min="0"
              @input="scientificPerformanceChanged"
            >
              <template #suffix>%</template>
            </NumberInput>
            <fw-tip v-if="$v.criteriaWeight.interview_weight.$error" error>
              {{ $t('enterValidValue') }}
            </fw-tip>
          </div>
        </div>
      </div>
    </fw-panel>

    <fw-panel :title="$t('selectionCriteria')" boxed="sm" custom-class="bg-white" class="my-5">
      <template #toolbar>
        <b-dropdown
          v-if="allowedCriteriumType.length > 0 && !isScholarship"
          scrollable
          max-height="500"
          aria-role="list"
          position="is-bottom-left"
        >
          <fw-button
            slot="trigger"
            :aria-label="$t('chooseCriteria')"
            type="dropdown"
            size="sm"
            class="flex gap-1 items-center"
          >
            {{ criteriumLabels[criteriumType] }}
            <fw-icon-chevron-down class="w-4 h-4" />
          </fw-button>
          <b-dropdown-item
            :key="'criterium_label'"
            aria-role="menu-item"
            :focusable="false"
            class="font-semibold"
            paddingless
            custom
          >
            <fw-label class="ml-4">{{ $t('defineCriteriaBy') }}:</fw-label>
          </b-dropdown-item>
          <b-dropdown-item
            v-for="(criterium, a) in allowedCriteriumType"
            :key="'criterium_selector_' + a"
            aria-role="menu-item"
            :focusable="true"
            class="px-4 py-2 font-semibold flex justify-start gap-3 items-center"
            @click.native="changeCriteriums(criterium)"
          >
            <div
              class="border-2 border-gray-200 h-4 w-4 rounded-full -mt-0.5"
              :class="{
                'bg-primary bg-opacity-90': criteriumType === criterium,
                'bg-gray-200': criteriumType !== criterium,
              }"
            ></div>
            <div>{{ criteriumLabels[criterium] }}</div>
          </b-dropdown-item>
        </b-dropdown>
        <fw-label v-else-if="!isScholarship">
          {{ criteriumLabels[criteriumType] }}
        </fw-label>
      </template>

      <fw-label class="-mt-2">{{ $t('curriculumEval') }}</fw-label>
      <fw-tip v-if="$v.curriculumCriteria.$error" error class="text-sm my-1.5" style="color: #f15f1fff">
        <span v-if="!$v.curriculumCriteria.mustBe100">{{ $t('sumMustBe') }}</span>
        <span v-else>{{ $t('addAtLeastOne') }}.</span>
      </fw-tip>
      <CriteriaList
        :criteria.sync="curriculumCriteria"
        :can-edit="canEdit && editMode"
        :disable-autowrite="disableAutowrite"
        :criteria-type="'curriculum'"
        @delete-criterium="deleteCriterium('curriculum', $event)"
        @save-criterium="saveCriterium('curriculum', $event)"
        @add-criterium="addCriterium('curriculum', $event)"
      ></CriteriaList>

      <div v-if="!canEdit && !curriculumCriteria?.length" class="text-sm text-gray-500">
        {{ $t('noCriteriumYet') }}
      </div>

      <div v-if="criteriaWeight.with_interview" class="pt-3">
        <fw-label>{{ $t('interview') }}</fw-label>

        <fw-tip v-if="$v.interviewCriteria.$error" error class="text-sm my-1.5" style="color: #f15f1fff">
          <span v-if="!$v.interviewCriteria.mustBe100">{{ $t('sumMustBe') }}</span>
          <span v-else>{{ $t('addAtLeastOne') }}.</span>
        </fw-tip>

        <CriteriaList
          :can-edit="canEdit && editMode"
          :criteria.sync="interviewCriteria"
          :disable-autowrite="disableAutowrite"
          :criteria-type="'interview'"
          @delete-criterium="deleteCriterium('interview', $event)"
          @save-criterium="saveCriterium('interview', $event)"
          @add-criterium="addCriterium('interview', $event)"
        ></CriteriaList>

        <div v-if="!canEdit && !interviewCriteria?.length" class="text-sm text-gray-500">
          {{ $t('noCriteriumYet') }}
        </div>
      </div>
    </fw-panel>
  </section>
</template>

<script>
import NumberInput from '@/fw-modules/fw-core-vue/ui/components/form/NumberInput'
import OptionsCard from '@/fw-modules/fw-core-vue/ui/components/form/OptionsCard'
import CriteriaList from '@/components/panels/CriteriaList'
import { required, maxLength, minLength, decimal, minValue, maxValue, helpers } from 'vuelidate/lib/validators'

const mustBe100 = helpers.withParams({ type: 'mustBe100' }, value => {
  if (Array.isArray(value)) {
    const sum = value.reduce((accumulator, item) => {
      return item._destroy ? accumulator : accumulator + Number(item.value) // dont count criteria do be deleted
    }, 0)

    return sum == 100 || !helpers.req(value)
  }

  return false
})

const equalTo100 = helpers.withParams({ type: 'mustBe100' }, value => {
  return value.curriculum_weight + value.interview_weight === 100
})

export default {
  components: {
    NumberInput,
    OptionsCard,
    CriteriaList,
  },

  props: {
    procedure: {
      type: Object,
      default: () => {
        return {}
      },
    },

    isValid: {
      type: Boolean,
    },

    canEdit: {
      type: Boolean,
      default: true,
    },

    isScholarship: {
      type: Boolean,
      default: true,
    },

    disableAutowrite: {
      type: Boolean,
      default: true,
    },

    view: {
      type: String,
      default: 'metadata',
    },

    savingData: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      editMode: this.canEdit && this.procedure.status === 'draft',
      interviewCriteria: {},
      curriculumCriteria: {},
      criteriaWeight: {
        with_interview: false,
        curriculum_weight: 70,
        interview_weight: 30,
      },
      formErrors: {
        interview_criteria: '',
        curriculum_criteria: '',
      },
      criteriumLabels: {
        percentage: 'Percentagens',
        points: 'Pontuação',
        points_options: 'Opções com pontos',
      },
      allowedCriteriumType: ['percentage', 'points', 'points_options'],
      criteriumType: 'percentage', //percentage | points | points_options || scholarship_one || scholarship_two.
      editingCriteria: null,
      sliderValue: 70,
    }
  },

  validations() {
    let validations = {
      criteriaWeight: {
        equalTo100,
        curriculum_weight: {
          required,
          decimal,
          min: minValue(0),
          max: maxValue(this.maxPedagogicalCapacity),
        },
        interview_weight: {
          required,
          decimal,
          min: minValue(0),
          max: maxValue(this.maxScientificPerformance),
        },
      },
      curriculumCriteria: {
        required,
        mustBe100,
        min: minLength(1),
        $each: {
          name: {
            pt: { required, min: minLength(1), max: maxLength(250) },
            en: { required, min: minLength(1), max: maxLength(250) },
          },
          value: { required, decimal, min: minValue(1), max: maxValue(100) },
          type: { required },
        },
      },
    }

    if (this.criteriaWeight.with_interview) {
      validations['interviewCriteria'] = {
        required,
        min: minLength(1),
        mustBe100,
        $each: {
          name: {
            pt: { required, min: minLength(1), max: maxLength(250) },
            en: { required, min: minLength(1), max: maxLength(250) },
          },
          value: { required, decimal, min: minValue(1), max: maxValue(100) },
          type: { required },
        },
      }
    }

    return validations
  },

  computed: {
    interviewOptionsText() {
      return this.criteriaWeight.with_interview
        ? this.criteriaWeight.curriculum_weight + '% + ' + this.criteriaWeight.interview_weight + '%'
        : '70% + 30%'
    },

    maxScientificPerformance() {
      return parseFloat(
        this.criteriaWeight.interview_weight < 101 ? (100 - this.criteriaWeight.curriculum_weight).toFixed(1) : 0.0
      )
    },

    maxPedagogicalCapacity() {
      return parseFloat(
        this.criteriaWeight.curriculum_weight < 101 ? (100 - this.criteriaWeight.interview_weight).toFixed(1) : 0.0
      )
    },
  },

  watch: {
    '$v.$invalid'(newInvalid) {
      this.$emit('update:isValid', !newInvalid)
    },
    'procedure.interview_criteria'(newValue) {
      this.interviewCriteria = newValue
    },
    'procedure.curriculum_criteria'(newValue) {
      this.curriculumCriteria = newValue
    },
    'procedure.curriculum_weight'(newValue) {
      this.criteriaWeight.curriculum_weight = newValue
    },
    'procedure.interview_weight'(newValue) {
      this.criteriaWeight.interview_weight = newValue
    },
  },

  created() {
    this.interviewCriteria = this.procedure.interview_criteria
    this.curriculumCriteria = this.procedure.curriculum_criteria
    this.criteriaWeight = {
      with_interview: this.procedure.with_interview,
      curriculum_weight: this.procedure.curriculum_weight,
      interview_weight: this.procedure.interview_weight,
    }
    this.sliderValue = this.procedure.curriculum_weight
    this.$emit('update:isValid', !this.$v.$invalid)
  },

  methods: {
    selectMethod(withInterview) {
      if (withInterview === 'false') {
        // Remove critérios
        for (let i = 0; i < this.interviewCriteria.length; i++) {
          this.interviewCriteria[i]['_destroy'] = true
        }
        // reset pedagogic capacity
        this.$set(this.criteriaWeight, 'curriculum_weight', 100)
        this.$set(this.criteriaWeight, 'interview_weight', 0)
        this.$set(this.criteriaWeight, 'with_interview', false)
      } else {
        this.$set(this.criteriaWeight, 'curriculum_weight', 70)
        this.$set(this.criteriaWeight, 'interview_weight', 30)
        this.$set(this.criteriaWeight, 'with_interview', true)
      }
      this.saveProc()
    },

    addCriterium(type, data) {
      if (type === 'interview') {
        this.interviewCriteria.push(data)
        this.$v.interviewCriteria.$touch()
      } else {
        this.curriculumCriteria.push(data)
        this.$v.curriculumCriteria.$touch()
      }
      this.saveProc()
    },
    deleteCriterium(type, pos) {
      if (type === 'interview') {
        if (this.interviewCriteria[pos]['key']) {
          this.interviewCriteria[pos]['_destroy'] = true
          this.$set(this.interviewCriteria, pos, this.interviewCriteria[pos])
        } else {
          this.interviewCriteria.splice(pos, 1)
        }
        this.$v.interviewCriteria.$touch()
      } else {
        if (this.curriculumCriteria[pos]['key']) {
          this.curriculumCriteria[pos]['_destroy'] = true
          this.$set(this.curriculumCriteria, pos, this.curriculumCriteria[pos])
        } else {
          this.curriculumCriteria.splice(pos, 1)
        }
        this.$v.curriculumCriteria.$touch()
      }
      this.saveProc()
    },

    saveCriterium(type, data) {
      if (type === 'interview') {
        this.$set(this.interviewCriteria, data.pos, data.data)
      } else {
        this.$set(this.curriculumCriteria, data.pos, data.data)
      }
      this.saveProc()
    },

    sliderChanged(value) {
      console.log('sliderChanged', value)
      this.$set(this.criteriaWeight, 'curriculum_weight', value)
      this.$set(this.criteriaWeight, 'interview_weight', 100 - value)

      this.$v.criteriaWeight.$touch()
    },

    pedagogicalCapacityChanged(value) {
      console.log('pedagogicalCapacityChanged :>> ', value)
      this.$set(this.criteriaWeight, 'curriculum_weight', parseInt(value))
      this.$set(this.criteriaWeight, 'interview_weight', parseInt((100 * 100 - value * 100) / 100))
      console.log('this.criteriaWeight :>> ', this.criteriaWeight)
      this.$v.criteriaWeight.$touch()
    },

    scientificPerformanceChanged(value) {
      console.log('scientificPerformanceChanged :>> ', value)
      this.$set(this.criteriaWeight, 'interview_weight', parseInt(value))
      this.$set(this.criteriaWeight, 'curriculum_weight', parseInt((100 * 100 - value * 100) / 100))
      console.log('this.criteriaWeight :>> ', this.criteriaWeight)
      this.$v.criteriaWeight.$touch()
    },

    saveProc(data) {
      if (!data) {
        data = {
          interview_criteria: this.interviewCriteria,
          curriculum_criteria: this.curriculumCriteria,
          with_interview: this.criteriaWeight.with_interview,
          curriculum_weight: this.criteriaWeight.curriculum_weight,
          interview_weight: this.criteriaWeight.interview_weight,
        }
      }

      this.$v.$touch()

      if (!this.$v.$invalid) {
        this.$emit('save-procedure', data)
      }
    },

    toggleEditMode() {
      this.editMode = !this.editMode
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "criteria": "Critérios",
    "thereAreErrors": "Existem erros no formulário",
    "save": "Guardar",
    "cancel": "Cancelar",
    "edit": "Editar",
    "delete": "Eliminar",
    "close": "Fechar",
    "curriculumEval": "Avaliação Curricular",
    "interview": "Entrevista",
    "enterValidValue":"Insira um valor válido",
    "selectionCriteria": "Critérios de avaliação",
    "chooseCriteria": "Escolher tipo de critérios",
    "defineCriteriaBy": "Definir critérios por",
    "sumMustBe": "A soma dos critérios de avaliação curricular deverá ser 100%.",
    "addAtLeastOne": "Insira pelo menos um critério",
    "selectionMethods": "Critérios de seleção",
    "weight": "Ponderação",
    "noCriteriumYet": "Ainda não foram definidos critérios de seleção."
  },
  "en": {
    "criteria": "Criteria",
    "thereAreErrors": "There are errors in the form",
    "save": "Save",
    "cancel": "Cancel",
    "edit": "Edit",
    "delete": "Delete",
    "close": "Close",
    "curriculumEval": "Curriculum seriation",
    "interview": "Interview",
    "enterValidValue":"Enter a valid value",
    "selectionCriteria": "Evaluation Criteria",
    "chooseCriteria": "Choose criteria type",
    "defineCriteriaBy": "Define criteria per",
    "sumMustBe": "The sum of the curricular evaluation criteria must be 100%.",
    "addAtLeastOne": "Enter at least one criterion",
    "selectionMethods": "Selection Criteria",
    "weight": "Weight",
    "noCriteriumYet": "No selection criteria has been added."
  }
}
</i18n>
