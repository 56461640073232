var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.currentViewChecks && _vm.procedure)?_c('fw-panel',{attrs:{"title":_vm.$t('juryPresences'),"boxed":"xs","custom-class":"bg-white"}},[(
      (!_vm.procedure.presidents || _vm.procedure.presidents.length < 1) && (!_vm.procedure.vogals || _vm.procedure.vogals.length < 1)
    )?_c('fw-panel-info',{staticClass:"p-3",attrs:{"clean":"","centered":""}},[_vm._v(" "+_vm._s(_vm.$t('undefinedJury'))+". ")]):_c('div',[_vm._l((_vm.procedure.presidents),function(president){return [_c('Person',{key:president.key,staticClass:"border-b border-gray-100",attrs:{"person":president,"open-details-on-click":_vm.canOpenUserDetails,"clickable":_vm.canOpenUserDetails},scopedSlots:_vm._u([{key:"secondline",fn:function(){return [_c('div',{staticClass:"flex items-center text-sm text-gray-500 gap-3"},[_c('div',[_vm._v(_vm._s(_vm.$t('president')))]),(president.as_president_substitute === true)?_c('div',{staticClass:"text-xs font-semibold"},[_vm._v(" "+_vm._s(_vm.$t('substitute'))+" ")]):_vm._e()])]},proxy:true},{key:"options",fn:function(){return [_c('div',{on:{"click":function($event){$event.stopPropagation();}}},[(!president.no_conflict_of_interest)?_c('TagConflitsOfInterest',{attrs:{"has-no-conflit-of-interest":president.no_conflict_of_interest}}):(_vm.canEdit && president.no_conflict_of_interest === true)?_c('OnoffOption',{attrs:{"disabled":_vm.currentViewChecks.meeting_ended || !_vm.canEdit,"options":[_vm.$t('present'), _vm.$t('absent')],"selected-option":_vm.currentViewChecks && _vm.currentViewChecks.jury
                  ? _vm.currentViewChecks.jury.includes(president.jury_key)
                    ? _vm.$t('present')
                    : _vm.$t('absent')
                  : null},on:{"input":function($event){return _vm.userPresence(president.jury_key, $event)}}}):(_vm.currentViewChecks && _vm.currentViewChecks.jury)?_c('div',[(_vm.currentViewChecks.jury.includes(president.jury_key))?_c('fw-tag',{staticClass:"w-24 flex items-center justify-center",attrs:{"type":"light-primary"}},[_vm._v(" "+_vm._s(_vm.$t('present'))+" ")]):_c('fw-tag',{staticClass:"w-24 flex items-center justify-center",attrs:{"type":"light"}},[_vm._v(" "+_vm._s(_vm.$t('absent'))+" ")])],1):_vm._e()],1)]},proxy:true}],null,true)})]}),_vm._l((_vm.procedure.vogals),function(vogal){return [_c('Person',{key:vogal.key,staticClass:"border-b border-gray-100",attrs:{"person":vogal,"open-details-on-click":_vm.canOpenUserDetails,"clickable":_vm.canOpenUserDetails},scopedSlots:_vm._u([{key:"secondline",fn:function(){return [_c('div',{staticClass:"flex items-center text-sm text-gray-500 gap-3"},[_c('div',[_vm._v(_vm._s(_vm.$t('vogal')))]),(vogal.as_president_substitute === true)?_c('div',{staticClass:"text-xs font-semibold"},[_vm._v(" "+_vm._s(_vm.$t('substitute'))+" ")]):_vm._e()])]},proxy:true},{key:"options",fn:function(){return [_c('div',{on:{"click":function($event){$event.stopPropagation();}}},[(!vogal.no_conflict_of_interest)?_c('TagConflitsOfInterest',{attrs:{"has-no-conflit-of-interest":vogal.no_conflict_of_interest}}):(_vm.canEdit && vogal.no_conflict_of_interest === true)?_c('OnoffOption',{attrs:{"options":[_vm.$t('present'), _vm.$t('absent')],"disabled":_vm.currentViewChecks.meeting_ended || !_vm.canEdit,"selected-option":_vm.currentViewChecks && _vm.currentViewChecks.jury && _vm.currentViewChecks.jury.includes(vogal.jury_key)
                  ? _vm.$t('present')
                  : _vm.$t('absent')},on:{"input":function($event){return _vm.userPresence(vogal.jury_key, $event)}}}):(_vm.currentViewChecks && _vm.currentViewChecks.jury)?_c('div',[(_vm.currentViewChecks.jury.includes(vogal.jury_key))?_c('fw-tag',{staticClass:"w-24 flex items-center justify-center",attrs:{"type":"light-primary"}},[_vm._v(" "+_vm._s(_vm.$t('present'))+" ")]):_c('fw-tag',{staticClass:"w-24 flex items-center justify-center",attrs:{"type":"light"}},[_vm._v(" "+_vm._s(_vm.$t('absent'))+" ")])],1):_vm._e()],1)]},proxy:true}],null,true)})]})],2)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }