<template>
  <b-dropdown aria-role="list" position="is-bottom-left">
    <fw-button-dropdown slot="trigger" aria-role="listitem" type="xlight" :label="$t('actions')">
      <fw-icon-actions class="w-5 h-5" :class="{ 'text-primary animate-pulse': activeActionsCounter }" />
      <span :class="{ 'text-primary': activeActionsCounter }">{{ $t('actions') }}</span>
      <fw-badge v-if="activeActionsCounter">{{ activeActionsCounter }}</fw-badge>
    </fw-button-dropdown>
    <b-dropdown-item
      v-if="!activeActionsCounter && !activeActionsHiddenFromCounter"
      custom
      class="paddingless w-52 text-gray-500"
    >
      {{ $t('noActions') }}
    </b-dropdown-item>
    <b-dropdown-item v-if="showActionDeleteProcedure" ref="actionitem" custom class="paddingless" aria-role="menuitem">
      <fw-button type="basic-action" expanded custom-class="text-red-400" @click.native="$emit('delete-procedure')">{{
        $t('deleteProcedure')
      }}</fw-button>
    </b-dropdown-item>
    <b-dropdown-item v-if="checks.canPublish" ref="actionitem" custom class="paddingless" aria-role="menuitem">
      <fw-button
        ref="publishButton"
        :type="checks.canPublish ? 'basic-action' : 'disabled'"
        :disabled="!checks.canPublish"
        class="w-full my-0.5 text-left"
        @click.native="$emit('publish')"
      >
        {{ $t('publish') }}
      </fw-button>
    </b-dropdown-item>
    <b-dropdown-item v-else-if="checks.canOpen" ref="actionitem" custom class="paddingless" aria-role="menuitem">
      <fw-button
        :type="checks.canOpen ? 'basic-action' : 'disabled'"
        :disabled="!checks.canOpen"
        class="w-full my-0.5 text-left"
        @click.native="$emit('open')"
      >
        {{ $t('open') }}
      </fw-button>
    </b-dropdown-item>
    <b-dropdown-item
      v-else-if="checks.canNotifyInterview"
      ref="actionitem"
      custom
      class="paddingless"
      aria-role="menuitem"
    >
      <fw-button
        :type="checks.canNotifyInterview ? 'basic-action' : 'disabled'"
        :disabled="!checks.canNotifyInterview"
        class="w-full my-0.5 text-left"
        @click.native="$emit('notify-interview')"
      >
        <div class="w-44">
          {{ $t('notifyInterview') }}
          <p
            v-if="checks.notificationInterview"
            class="text-yellow-700 text-xs mt-2 flex items-start justify-center gap-2"
          >
            <fw-icon-info class="w-4 h-4" style="min-width: 1rem;" />
            <span>Enviadas em {{ checks.notificationInterview | formatDateTime }}</span>
          </p>
        </div>
      </fw-button>
    </b-dropdown-item>
    <b-dropdown-item
      v-else-if="procedure && checks.canClose"
      ref="actionitem"
      custom
      class="paddingless"
      aria-role="menuitem"
    >
      <fw-button
        :type="checks.canClose ? 'basic-action' : 'disabled'"
        :disabled="!checks.canClose"
        class="w-full my-0.5 text-left"
        @click.native="$emit('end')"
      >
        {{ $t('end') }}
      </fw-button>
    </b-dropdown-item>
    <b-dropdown-item
      v-else-if="checks.canLock && checks.isResultsNotified"
      ref="actionitem"
      custom
      class="paddingless"
      aria-role="menuitem"
    >
      <fw-button
        :type="checks.canLock ? 'basic-action' : 'disabled'"
        class="w-full my-0.5 text-left"
        :disabled="!checks.canLock"
        @click.native="$emit('close')"
      >
        {{ $t('close') }}
      </fw-button>
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
export default {
  props: {
    checks: {
      type: Object,
      default: () => {
        return {}
      },
    },
    procedure: {
      type: Object,
      default: () => {
        return {}
      },
    },
    currentViewChecks: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      availableActions: [
        'showActionPublish',
        'showActionOpen',
        'showActionNotifyInterview',
        'showActionEnd',
        'showActionClose',
      ], // Confirm every computed value to update counter
      availableActionsSkipCounter: ['showActionDeleteProcedure'],
    }
  },

  computed: {
    showActionDeleteProcedure() {
      return this.checks.isCurrentProcManager && this.canDeleteProcedure
    },
    showActionPublish() {
      if (this.checks.canPublish) {
        return true
      }
      return false
    },
    showActionOpen() {
      if (this.checks.canOpen && !this.checks.canClose) {
        return true
      }
      return false
    },
    showActionNotifyInterview() {
      if (this.checks.canNotifyInterview) {
        return true
      }
      return false
    },
    showActionEnd() {
      if (this.checks.canClose) {
        return true
      }
      return false
    },
    showActionClose() {
      if (this.checks.canLock && this.checks.isResultsNotified) {
        return true
      }
      return false
    },
    activeActionsCounter() {
      var counter = 0
      var self = this
      for (const action of self.availableActions) {
        if (self[action]) counter += 1
      }
      return counter
    },
    activeActionsHiddenFromCounter() {
      var counter = 0
      var self = this
      for (const action of self.availableActionsSkipCounter) {
        if (self[action]) counter += 1
      }
      return counter
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "actions": "Ações",
    "noActions": "Não existem ações disponíveis neste momento.",
    "publish": "Publicar procedimento",
    "notifyPublished": "Notificar abertura das candidaturas ao Júri",
    "open": "Abrir procedimento ao Júri",
    "notifyInterview": "Enviar convocatórias para entrevista aos candidatos",
    "end": "Terminar fase de avaliação",
    "notifyResults": "Enviar notificações de resultados",
    "notifyRejected": "Enviar notificações a candidatos não admitidos",
    "close": "Encerrar procedimento",
    "meeting": "Reunião",
    "sendInterviewNotification": "Enviar convocatórias para entrevista aos candidatos",
    "meetingMinute": "Ata editável da reunião",
    "deleteProcedure": "Eliminar procedimento"
  },
  "en": {
    "actions": "Actions",
    "noActions": "No actions available at this time.",
    "publish": "Publish procedure",
    "notifyPublished": "Notify opening of applications to Jury",
    "open": "Open proceedings to the Jury",
    "notifyInterview": "Send invitations for interviews to candidates",
    "end": "End evaluation phase",
    "notifyResults": "Send result notifications",
    "notifyRejected": "Send notifications to rejected candidates",
    "close": "Close procedure",
    "meeting": "Reunião",
    "sendInterviewNotification": "Enviar convocatórias para entrevista aos candidatos",
    "meetingMinute": "Ata editável da reunião",
    "deleteProcedure": "Delete procedure"
  }
}
</i18n>
