<template>
  <section v-if="!loading && procedure">
    <MeetingHeader
      :procedure="procedure"
      :step-data="currentStepData"
      :is-current-proc-manager="isCurrentProcManager"
      :current-view-checks="currentViewChecks"
      :meeting="meeting"
      :with-interview="procedure.with_interview"
      :all-candidates-rejected="!candidates.length"
      :can-manage="canManage"
      :can-send-interview-notification="canSendInterviewNotification"
      :all-applications-have-interviews="allApplicationsHaveInterviews"
      :saving-data="loading"
      :interview-notification-sent="interviewNotificationSent"
      @create-interview-meeting="createInterviewMeeting"
      @send-interview-notification="sendInterviewNotification"
      @start-meeting="startMeeting"
      @end-meeting="endMeeting"
      @close-meeting="closeMeeting"
      @reopen-meeting="reopenMeeting"
      @download-minute="downloadMinute"
    />

    <Presences
      :procedure="procedure"
      :current-view-checks="currentViewChecks"
      :can-edit="canEditJuryPresences"
      class="my-5"
      @set-user-presence="setUserPresence"
    />

    <PresencesCandidates
      :people="candidates"
      :people-present="interviewUsersPresent"
      :downloading="downloadingFiles"
      :allow-download-candidates-zip="allowDownloadCandidatesZip"
      title="Presenças dos candidatos"
      no-content-message="Sem candidatos"
      :can-edit="canEditCandidatesPresences"
      :can-download-presence-file="canDownloadPresenceFile"
      class="my-5"
      @set-user-presence="setCandidatesPresence"
      @open-application="openApplication"
      @download-candidates="downloadCandidates"
      @download-presence-file="downloadPresenceFile"
    />

    <fw-panel-info
      v-if="
        !canEditCandidatesPresences &&
          currentViewChecks &&
          currentViewChecks.meeting_started &&
          !currentViewChecks.meeting_ended
      "
      class="mb-5"
      >Para editar as presenças dos candidatos não podem existir mapas de avaliação fechados.</fw-panel-info
    >

    <fw-panel
      v-if="meetingHasBeenOpened"
      title="Seriação Individual"
      subtitle="Por membro do júri"
      boxed="xs"
      custom-class="bg-white"
    >
      <div
        v-if="procedure.presidents.length === 0 && procedure.vogals.length === 0"
        class="hero hero-for-empty shadow-md rounded-xl bg-gray-50 text-lg text-gray-500"
        :class="{ '-mt-4': !isJury }"
      >
        <div class="hero-content">Sem júri definido</div>
      </div>
      <div v-else>
        <RecordEvaluationUser
          v-for="president in procedure.presidents"
          :key="president.jury_key"
          :is-president="true"
          :jury="president"
          :current-view-checks="currentViewChecks"
          :has-candidates="candidates.length > 0"
          :can-see-all-maps="canManage"
          :loading="loading"
          :map="evaluationMaps[president.jury_key]"
          view="interview"
          @open-map="openEvaluationMap"
        />

        <RecordEvaluationUser
          v-for="vogal in procedure.vogals"
          :key="vogal.jury_key"
          :jury="vogal"
          :is-president="false"
          :current-view-checks="currentViewChecks"
          :has-candidates="candidates.length > 0"
          :map="evaluationMaps[vogal.jury_key]"
          :can-see-all-maps="canManage"
          :loading="loading"
          view="interview"
          @open-map="openEvaluationMap"
        />
      </div>
    </fw-panel>

    <fw-panel
      v-if="!isScholarship"
      title="Decisão de mérito absoluto"
      subtitle="Por membro do júri"
      boxed="xs"
      custom-class="bg-white"
    >
      <div v-if="procedure.presidents.length === 0 && procedure.vogals.length === 0" class="text-gray-500">
        Sem júri definido
      </div>
      <div v-else>
        <Person v-for="president in procedure.presidents" :key="president.key" :person="president">
          <template #secondline>
            <div class="flex text-sm text-gray-400">
              <div>Presidente</div>
              <div
                v-if="president.as_president_substitute === true"
                class="text-xs bg-green-500 px-1.5 py-0.5 text-black text-opacity-60"
              >
                Suplente
              </div>
            </div>
          </template>
          <template #options>
            <div class="flex justify-end">
              <fw-button type="light">Bloquear Mapa</fw-button>
            </div>
          </template>
        </Person>
        <Person v-for="vogal in procedure.vogals" :key="vogal.key" :person="vogal">
          <template #secondline>
            <div class="flex text-sm text-gray-400">
              <div>Vogal</div>
              <div
                v-if="vogal.as_president_substitute === true"
                class="text-xs bg-green-500 px-1.5 py-0.5 text-black text-opacity-60"
              >
                Suplente
              </div>
            </div>
          </template>
          <template #options>
            <div class="flex justify-end">
              <fw-button type="light">Bloquear Mapa</fw-button>
            </div>
          </template>
        </Person>
      </div>
    </fw-panel>

    <fw-panel
      v-if="!isScholarship"
      title="Mérito absoluto"
      subtitle="Resultado final"
      boxed="xs"
      custom-class="bg-white"
    >
      <PeopleList v-if="absoluteMerithApplications.length > 0" :list="absoluteMerithApplications"></PeopleList>
      <div v-else class="text-gray-500">Sem resultados</div>
    </fw-panel>
  </section>
</template>

<script>
import Presences from '@/components/panels/Presences'
import Person from '@/fw-modules/fw-core-vue/ui/components/cards/Person'
import MeetingHeader from '@/components/panels/MeetingHeader'
import RecordEvaluationUser from '@/components/records/RecordEvaluationUser'
import PresencesCandidates from '@/components/panels/PresencesCandidates'

import Dates from '@/fw-modules/fw-core-vue/utilities/dates'

export default {
  components: {
    Presences,
    Person,
    MeetingHeader,
    RecordEvaluationUser,
    PresencesCandidates,
  },
  props: {
    procedure: {
      type: Object,
      default: () => {
        return {}
      },
    },
    currentViewChecks: {
      type: Object,
      required: true,
    },
    evaluationMaps: {
      type: Object,
      required: true,
    },
    isCurrentProcManager: {
      type: Boolean,
      default: true,
    },
    interviewNotificationSent: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: true,
    },
    downloadingFiles: {
      type: Boolean,
      default: false,
    },
    canManage: {
      type: Boolean,
      default: true,
    },
    isJury: {
      type: Boolean,
      default: true,
    },
    isScholarship: {
      type: Boolean,
      default: true,
    },
    canEditJuryPresences: {
      type: Boolean,
      default: true,
    },
    canEditCandidatesPresences: {
      type: Boolean,
      default: false,
    },
    canDownloadPresenceFile: {
      type: Boolean,
      default: false,
    },
    allowDownloadCandidatesZip: {
      type: Boolean,
      default: false,
    },
    canSendInterviewNotification: {
      type: Boolean,
      default: false,
    },
    canDownloadInterviewInfo: {
      type: Boolean,
      default: true,
    },
    candidates: {
      type: Array,
      default: () => {
        return []
      },
    },
    myJuryInfo: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },

  data() {
    return {
      activeModal: null,
      mapSearchQuery: '',
      currentStepData: {
        text: 'Entrevista',
        value: 'seriation-interview',
      },
      selectedJury: null,
    }
  },

  computed: {
    interviewDefined() {
      return Boolean(this.procedure.meetings.find(el => el.goal == 'seriation-interview'))
    },

    interviewUsersPresent() {
      if (this.procedure && this.procedure.applications) {
        return this.procedure.applications
          .filter(el => el.attendance_status === 'attending' && el.application_status !== 'cancelled')
          .map(el => el.key)
      } else {
        return []
      }
    },

    allApplicationsHaveInterviews() {
      if (this.procedure && this.procedure.applications) {
        return !this.procedure.applications.find(
          el =>
            (!el.interview_datetime || !el.interview_location) &&
            !el.rejected_step &&
            el.application_status !== 'cancelled'
        )
      } else {
        return false
      }
    },

    meeting() {
      return this.procedure.meetings.find(el => el.goal == 'seriation-interview')
    },

    meetingHasBeenOpened() {
      return this.currentViewChecks.meeting_started
    },
  },

  methods: {
    async downloadCandidates(option = 'files') {
      if (this.allowDownloadCandidatesZip) {
        this.$emit('download-candidates', option)
      }
    },

    admitUser(candidateKey, rawValue) {
      const value = rawValue === 0
      const candidate = this.procedure.applications.find(el => el.candidate_key === candidateKey)
      if (!value) {
        candidate.rejected_reason = null
      }
      this.$emit('admit-user', candidateKey, value)
    },

    openApplication(candidateKey) {
      this.$emit('open-application', candidateKey)
    },

    setUserPresence(personKey, option) {
      this.$emit('set-user-presence', personKey, option)
    },

    startMeeting() {
      if (this.meeting) {
        if (Dates.now().isSame(Dates.build(this.meeting.date), 'day')) {
          this.$emit('start-meeting')
        } else {
          this.$emit('save-meeting', 'interview', this.meeting)
        }
      } else {
        this.$emit('save-meeting', 'interview')
      }

      this.$emit('get-current-view-checks')
    },

    closeModal() {
      this.activeModal = null
    },

    createInterviewMeeting() {
      this.$emit('create-interview-meeting')
    },

    sendInterviewNotification() {
      this.$emit('send-interview-notification')
    },

    endMeeting() {
      this.$emit('end-meeting')
    },

    closeMeeting() {
      this.$emit('close-meeting')
    },

    reopenMeeting() {
      this.$emit('reopen-meeting')
    },

    downloadMinute(locale) {
      this.$emit('download-minute', locale)
    },

    openEvaluationMap(user) {
      this.$emit('open-evaluation-map', user)
    },

    openRejectedReason(application) {
      this.$emit('open-rejected-reason', application)
    },

    setCandidatesPresence(candidateKey, rawValue) {
      this.$emit('set-candidates-presence', candidateKey, rawValue)
    },

    downloadPresenceFile(candidate) {
      this.$emit('download-presence-file', candidate)
    },
  },
}
</script>
