<template>
  <section v-if="people" class="mt-5">
    <fw-heading size="h4" muted>{{ $t('juryMembers') }}</fw-heading>
    <fw-panel
      :title="$t('president')"
      boxed="xs"
      custom-class="bg-white"
      class="mb-5 mt-2"
      :counter="people.presidents.length"
    >
      <fw-panel-info v-if="!people.presidents || people.presidents.length === 0" centered clean class="my-3">{{
        $t('presidentRequired')
      }}</fw-panel-info>

      <ExpandList
        :add-title="$t('addPresident')"
        :empty="!people.presidents || people.presidents.length === 0"
        :show-add-items="canEdit && (!people.presidents || people.presidents.length === 0)"
        @add-element="choosePeople('presidents')"
      >
        <template #list>
          <Person
            v-for="(person, p) in people.presidents"
            :key="person.key"
            :class="{
              'border-b border-gray-100': p !== people.presidents.length - 1,
            }"
            :open-details-on-click="canOpenUserDetails"
            :person="person"
            :selectable="false"
            :clickable="canOpenUserDetails"
          >
            <template #options>
              <TagConflitsOfInterest :has-no-conflit-of-interest="person.no_conflict_of_interest" />
              <b-dropdown v-if="canEdit" aria-role="list" position="is-bottom-left">
                <fw-button-dropdown slot="trigger" :chevron="false" type="simple" class="flex flex-col" label="Ações">
                  <fw-icon-more class="w-5 h-5" />
                </fw-button-dropdown>
                <b-dropdown-item custom aria-role="menuitem" class="paddingless">
                  <fw-button type="basic-action" custom-class="w-full" @click.native="deletePresident(person.key)">{{
                    $t('delete')
                  }}</fw-button>
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </Person>
        </template>
      </ExpandList>
    </fw-panel>
    <fw-panel
      :title="$t('effectiveVogals')"
      boxed="xs"
      custom-class="bg-white"
      class="my-5"
      :counter="people.vogals.effective.length"
    >
      <fw-panel-info v-if="!people.vogals.effective.length" centered clean class="my-3">{{
        $t('effectiveVogalsRequired')
      }}</fw-panel-info>
      <ExpandList
        :add-title="$t('addEffectiveVogals')"
        :empty="!people.vogals.effective || people.vogals.effective.length < 1"
        :show-add-items="canEdit && totalVogalsMembers < maxVogals"
        @add-element="choosePeople('effective-vogals')"
      >
        <template #list>
          <Person
            v-for="(person, p) in people.vogals.effective"
            :key="person.key"
            :class="{
              'border-b border-gray-100': canEdit || p < people.vogals.effective.length - 1,
            }"
            :open-details-on-click="canOpenUserDetails"
            :person="person"
            :selectable="false"
            :clickable="canOpenUserDetails"
          >
            <template #options>
              <TagConflitsOfInterest :has-no-conflit-of-interest="person.no_conflict_of_interest" />
              <b-dropdown v-if="canEdit" aria-role="list" position="is-bottom-left">
                <fw-button-dropdown slot="trigger" :chevron="false" type="simple" class="flex flex-col" label="Ações">
                  <fw-icon-more class="w-5 h-5" />
                </fw-button-dropdown>
                <b-dropdown-item custom aria-role="menuitem" class="paddingless">
                  <fw-button type="basic-action" custom-class="w-full" @click.native="deleteVogal(person.key)">{{
                    $t('delete')
                  }}</fw-button>
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </Person>
        </template>
      </ExpandList>
    </fw-panel>
    <fw-panel
      :title="$t('substituteVogals')"
      boxed="xs"
      custom-class="bg-white"
      class="my-5"
      :counter="people.vogals.substitute.length"
    >
      <fw-panel-info v-if="!people.vogals.substitute.length" centered clean class="my-3">
        <div>{{ $t('noSubstituteVogals') }}</div>
        <div v-if="canEdit && totalVogalsMembers === maxVogals" class="text-xs mx-auto max-w-sm my-1">
          {{ $t('maxVogals') }}
        </div>
      </fw-panel-info>
      <ExpandList
        :add-title="$t('addSubstituteVogals')"
        :empty="!people.vogals.substitute || people.vogals.substitute.length < 1"
        :show-add-items="canEdit && totalVogalsMembers < maxVogals"
        @add-element="choosePeople('substitute-vogals')"
      >
        <template #list>
          <Person
            v-for="(person, p) in people.vogals.substitute"
            :key="person.key"
            :class="{
              'border-b border-gray-100': canEdit || p < people.vogals.substitute.length - 1,
            }"
            :open-details-on-click="canOpenUserDetails"
            :person="person"
            :selectable="false"
            :clickable="canOpenUserDetails"
          >
            <template #options>
              <TagConflitsOfInterest :has-no-conflit-of-interest="person.no_conflict_of_interest" />
              <b-dropdown v-if="canEdit" aria-role="list" position="is-bottom-left">
                <fw-button-dropdown slot="trigger" :chevron="false" type="simple" class="flex flex-col" label="Ações">
                  <fw-icon-more class="w-5 h-5" />
                </fw-button-dropdown>
                <b-dropdown-item custom aria-role="menuitem" class="paddingless">
                  <fw-button type="basic-action" custom-class="w-full" @click.native="deleteVogal(person.key)">{{
                    $t('delete')
                  }}</fw-button>
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </Person>
        </template>
      </ExpandList>
    </fw-panel>
  </section>
</template>

<script>
import ExpandList from '@/fw-modules/fw-core-vue/ui/components/lists/ExpandList'
import Person from '@/fw-modules/fw-core-vue/ui/components/cards/Person'
import TagConflitsOfInterest from '@/components/users/TagConflitsOfInterest'

export default {
  components: {
    ExpandList,
    Person,
    TagConflitsOfInterest,
  },

  props: {
    people: {
      type: Object,
      default: () => {
        return {
          presidents: [],
          vogals: {
            effective: [],
            substitute: [],
          },
          manager: {},
        }
      },
    },

    canEdit: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      maxVogals: 8, // this will be different for each procedure type
      choosingPeopleType: null,
      currentSelectedPeople: [],
      usersList: [],
    }
  },

  computed: {
    api() {
      return this.$store.state.api.base
    },

    totalVogalsMembers() {
      return this.people.vogals.effective.length + this.people.vogals.substitute.length
    },

    // Roles
    userRoles() {
      return this.$store.getters.userPermissions
    },

    canOpenUserDetails() {
      return this.userRoles.isProcedureManager || this.userRoles.isAdmin || this.userRoles.isManager
    },
  },

  methods: {
    choosePeople(type) {
      this.$emit('choose-people', type)
    },

    invitePerson(user, roles, type) {
      this.$emit('invite-person', user, roles, type)
    },

    updateVogals(users, substitute = false) {
      this.$emit('update-vogals', users, substitute)
    },

    deleteVogal(key) {
      this.$emit('delete-vogal', key)
    },

    deletePresident(key) {
      this.$emit('delete-president', key)
    },

    updatePresidents(selection) {
      this.$emit('update-presidents', selection)
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "candidates": "Candidatos",
    "application": "Candidatura",
    "people": "Pessoas",
    "edit": "Alterar",
    "juryMembers": "Elementos do júri",
    "procedureManager": "Gestor/a do procedimento",
    "president": "Presidente",
    "addPresident": "Adicionar Presidente",
    "presidentRequired": "Ainda não foi definido um presidente. Para avançar, precisa associar uma pessoa neste cargo.",
    "effectiveVogals": "Vogais efetivos",
    "addEffectiveVogals": "Adicionar Vogais efetivos",
    "effectiveVogalsRequired": "Ainda não foram associados vogais efectivos. Para avançar, precisa associar pelo menos duas pessoas pessoa neste cargo.",
    "substituteVogals": "Vogais suplentes",
    "addSubstituteVogals": "Adicionar Vogais suplentes",
    "noSubstituteVogals": "Ainda não foram associados foram associados vogais suplentes. Para avançar, precisa associar pelo menos uma pessoa pessoa neste cargo.",
    "maxVogals": "Caso pretenda associar membros, como o número máximo de Vogais foi atingido, por favor, remova um membro da lista de vogais efetivos.",
    "delete": "Remover",
    "notYet": "Ainda não",
    "no": "Não",
    "noVogals": "existem candidatos associados ao procedimento.",
    "applicationsSubmitted": "Submetida",
    "applicationsCancelled": "Cancelada",
    "chooseManager": "Escolha o secretariado",
    "choosePresidente": "Escolha o presidente",
    "chooseVogals": "Escolha o(s) vogal/vogais",
    "chooseEffectiveVogals": "Escolha o(s) vogal/vogais efectivo(s)",
    "chooseSubstituteVogals": "Escolha o(s) vogal/vogais suplente(s)",
    "choosePerson": "Escolher pessoa",
    "selectPresidente": "Selecione o utilizador que quer como presidente:",
    "selectVogals": "Selecione o(s) utilizador(es) que quer como vogal/vogais:",
    "selectEffectiveVogals": "Selecione o(s) utilizador(es) que quer como vogal/vogais efectivo(s):",
    "selectSubstituteVogals": "Selecione o(s) utilizador(es) que quer como vogal/vogais suplente(s):",
    "selectUser": "Seleccione um utilizador",
    "hasConflitOfInterests": "Com conflitos de interesse",
    "noConflitOfInterests": "Sem conflitos de interesse",
    "undefinedConflitOfInterests": "Sem resposta",
    "maxVogalsNum": {
      "title": "Número de vogais acima do permitido",
      "message": "A cada procedimento deve apenas associar <strong>{max} vogais</strong> (efetivos + suplentes). Para atualizar a lista de membros, por favor, remova pelo menos uma pessoa da lista de vogais."
    }
  },
  "en": {
    "candidates": "Candidates",
    "application": "Application",
    "people": "People",
    "edit": "Edit",
    "juryMembers": "Jury members",
    "procedureManager": "Procedure manager",
    "president": "President",
    "addPresident": "Add President",
    "presidentRequired": "A president has not yet been defined. To move forward, it needs to associate a person in this position.",
    "effectiveVogals": "Effective vogals",
    "addEffectiveVogals": "Adicionar Effective vogals",
    "effectiveVogalsRequired": "No effective vogals have been associated yet. To move forward, you need to associate at least two people in this position.",
    "substituteVogals": "Substitute vogals",
    "addSubstituteVogals": "Add Vogal Substitute",
    "noSubstituteVogals": "No substitute vogals have been associated yet. To move forward, you need to associate at least one person in this position.",
    "maxVogals": "If you want to associate members, as the maximum number of Vogals has been reached, please remove a member from the list of Effective vogals.",
    "delete": "Remove",
    "notYet": "No",
    "no": "No",
    "noVogals": "candidates associated with the procedure.",
    "applicationsSubmitted": "Submitted",
    "applicationsCancelled": "Cancelled",
    "chooseManager": "Choose the secretariat",
    "choosePresidente": "Choose the president",
    "chooseVogals": "Choose the vogal(s)",
    "chooseEffectiveVogals": "Choose the effective vogal(s)",
    "chooseSubstituteVogals": "Choose the substitute vogal(s)",
    "choosePerson": "Choose person",
    "selectPresidente": "Select the user you want as president:",
    "selectVogals": "Select the user(s) you want as vogals:",
    "selectEffectiveVogals": "Select the user(s) you want as the effective vogals:",
    "selectSubstituteVogals": "Select the user(s) you want as substitute vogals:",
    "selectUser": "Select a user",
    "hasConflitOfInterests": "Has conflicts of interest",
    "noConflitOfInterests": "No conflicts of interest",
    "undefinedConflitOfInterests": "No response",
    "maxVogalsNum": {
      "title": "Number of vogals above allowed",
      "message": "To each procedure you should only associate <strong>{max} vogals</strong> (effective vogals + substitute). To update the list of vogals, please remove at least one person from the list of vogals."
    }
  }
}
</i18n>
