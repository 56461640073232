<template>
  <ExpandList
    :class="{ error: errorName.length }"
    :add-title="$t('addCriteria')"
    :empty="criteria.length === 0"
    :show-add-items="canEdit && editingCriteria !== -2 && criteriaSum < 100"
    @add-element="addCriterium"
  >
    <template #list>
      <template v-for="(criterium, m) in criteria">
        <div v-if="!criterium._destroy" :key="'criterium_' + m" class="flex">
          <div v-if="editingCriteria === m" class="w-full">
            <fw-label>{{ $t('criterion') }}</fw-label>
            <fw-tip v-if="errorName.length > 0" error>
              {{ errorName }}
            </fw-tip>
            <TranslatedInput
              v-model="criteriumTmpData.name"
              :disable-autowrite="disableAutowrite"
              :multiline="true"
              :placeholder="{ pt: $t('criteriaPlaceholder.pt'), en: $t('criteriaPlaceholder.en') }"
              class="mb-3"
            />
            <fw-label>{{ $t('percentage') }} (%)</fw-label>
            <fw-tip v-if="errorValue.length > 0" error>
              {{ errorValue }}
            </fw-tip>
            <NumberInput v-model="criteriumTmpData.value" :placeholder="`${$t('percentage')} (%)`" />
            <div class="flex-1 flex justify-end items-center mt-3">
              <fw-button type="primary" class="mr-4" @click.native="saveCriterium()">{{ $t('save') }}</fw-button>
              <fw-button type="light" @click.native="closeCriterium()">{{ $t('cancel') }}</fw-button>
            </div>
          </div>
          <div v-else class="w-full flex gap-4 p-3 rounded-xl" :class="{ 'hover:bg-gray-100': canEdit }">
            <div class="flex-1">
              <div v-if="criterium.name.pt.length > 0" class="flex gap-2">
                <div>
                  <div class="font-bold mx-2 flex-shrink-0 w-10 text-gray-500">{{ criterium.value }}%</div>
                </div>
                <div class="flex-1">
                  <fw-heading size="h4">{{ criterium.name.pt }}</fw-heading>
                  <div
                    v-if="criterium.name.en.length > 0 && criterium.name.pt !== criterium.name.en"
                    class="text-gray-500 text-xs font-medium mt-1 flex gap-0.5"
                  >
                    {{ criterium.name.en }}
                  </div>
                </div>
              </div>
              <span v-else class="text-gray-400 mr-3">{{ $t('noCriteria') }}</span>
            </div>
            <div v-if="canEdit">
              <b-dropdown aria-role="list" position="is-bottom-left">
                <fw-button-dropdown slot="trigger" :chevron="false" type="simple" class="flex flex-col" label="Ações">
                  <fw-icon-more class="w-5 h-5" />
                </fw-button-dropdown>
                <b-dropdown-item custom aria-role="menuitem" class="paddingless">
                  <fw-button
                    type="basic-action"
                    size="sm"
                    label="Editar critério"
                    custom-class="w-full"
                    @click.native="editCriterium(m)"
                  >
                    {{ $t('edit') }}
                  </fw-button>
                </b-dropdown-item>
                <b-dropdown-item custom aria-role="menuitem" class="paddingless">
                  <fw-button
                    type="basic-action"
                    size="sm"
                    custom-class="w-full"
                    label="Eliminar critério"
                    @click.native="deleteCriterium(m)"
                  >
                    {{ $t('delete') }}
                  </fw-button>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </div>
        </div>
      </template>
      <div v-if="editingCriteria === -2" class="w-full px-5 pb-5 pt-3">
        <fw-label>{{ $t('criterion') }}</fw-label>
        <div v-if="errorName.length > 0" class="text-sm my-1.5" style="color: #F15F1FFF">
          {{ errorName }}
        </div>
        <TranslatedInput
          v-model="criteriumTmpData.name"
          :disable-autowrite="disableAutowrite"
          :multiline="true"
          :placeholder="{ pt: $t('criteriaPlaceholder.pt'), en: $t('criteriaPlaceholder.en') }"
          class="mb-3"
        />
        <fw-label>{{ $t('percentage') }} (%)</fw-label>
        <div v-if="errorValue.length > 0" class="text-sm my-1.5" style="color: #F15F1FFF">
          {{ errorValue }}
        </div>
        <NumberInput
          v-model="criteriumTmpData.value"
          :placeholder="`${$t('percentage')} (%)`"
          :min="0.0"
          :max="100.0"
          :step="0.01"
        />
        <div class="flex-1 flex justify-end items-center mt-3">
          <fw-button type="primary" class="mr-4" @click.native="saveCriterium(true)">{{ $t('save') }}</fw-button>
          <fw-button type="light" @click.native="closeCriterium()">{{ $t('cancel') }}</fw-button>
        </div>
      </div>
    </template>
  </ExpandList>
</template>

<script>
import ExpandList from '@/fw-modules/fw-core-vue/ui/components/lists/ExpandList'
import TranslatedInput from '@/fw-modules/fw-core-vue/ui/components/form/TranslatedInput'
import NumberInput from '@/fw-modules/fw-core-vue/ui/components/form/NumberInput'

export default {
  name: 'CriteriaList',
  components: {
    ExpandList,
    TranslatedInput,
    NumberInput,
  },
  props: {
    disableAutowrite: {
      type: Boolean,
      default: false,
    },
    canEdit: {
      type: Boolean,
      default: true,
    },
    criteriaType: {
      type: String,
      default: 'curriculum',
    },
    criteria: {
      type: Array,
      default: function() {
        return []
      },
    },
  },
  data() {
    return {
      editingCriteria: -1,
      criteriumTmpData: {},
      errorName: '',
      errorValue: '',
    }
  },
  computed: {
    criteriaSum() {
      return this.criteria.reduce((accumulator, item) => {
        return item._destroy ? accumulator : accumulator + Number(item.value) // dont count criteria do be deleted
      }, 0)
    },
  },
  methods: {
    addCriterium() {
      this.errorName = ''
      this.errorValue = ''
      this.criteriumTmpData = {
        name: {
          pt: '',
          en: '',
        },
        type: this.criteriaType,
        value: 0,
      }
      this.editingCriteria = -2
    },
    editCriterium(pos) {
      this.errorName = ''
      this.errorValue = ''
      this.criteriumTmpData = JSON.parse(JSON.stringify(this.criteria[pos]))
      this.editingCriteria = pos
    },
    saveCriterium(isNew = false) {
      if (this.criteriumTmpData && this.criteriumTmpData.name) {
        if (this.criteriumTmpData.name.pt === '') {
          this.errorName = this.$t('addName.pt')
        } else if (this.criteriumTmpData.name.en === '') {
          this.errorName = this.$t('addName.en')
        } else if (this.criteriumTmpData.name.en.length >= 250) {
          this.errorName = this.$t('maxChars.en')
        } else if (this.criteriumTmpData.name.pt.length >= 250) {
          this.errorName = this.$t('maxChars.pt')
        } else {
          this.errorName = ''
        }
      }

      if (this.criteriumTmpData && (this.criteriumTmpData.value || this.criteriumTmpData.value === 0)) {
        let number = parseInt(this.criteriumTmpData.value)
        if (number === 0) {
          this.errorValue = this.$t('cantBeZero')
        } else if (number > 100) {
          this.errorValue = this.$t('cantBeMoreThan', { value: '100%' })
        } else {
          this.errorValue = ''
        }
      }

      let sum = this.criteria.reduce((accumulator, item) => {
        return item._destroy ? accumulator : accumulator + Number(item.value) // dont count criteria do be deleted
      }, 0)

      if (this.editingCriteria > -1) {
        sum = sum - Number(this.criteria[this.editingCriteria].value)
      }

      if (sum + Number(this.criteriumTmpData.value) > 100) {
        //(100 * 100 - sum * 100) / 100 this fixes the floating number problem with javascript
        this.errorValue = this.$t('cantBeMoreThan', { value: `${(100 * 100 - sum * 100) / 100}%` })
      }

      if (this.errorValue === '' && this.errorName === '') {
        if (isNew) {
          this.$emit('add-criterium', this.criteriumTmpData)
        } else {
          this.$emit('save-criterium', {
            pos: this.editingCriteria,
            data: this.criteriumTmpData,
          })
        }

        this.closeCriterium()
      }
    },
    deleteCriterium(pos) {
      this.$emit('delete-criterium', pos)
    },
    closeCriterium() {
      this.editingCriteria = -1
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "save": "Guardar",
    "cancel": "Cancelar",
    "edit": "Editar",
    "delete": "Eliminar",
    "noCriteria": "Sem critério",
    "percentage": "Percentagem",
    "addCriteria": "Adicionar Critério",
    "criterion": "Critério",
    "criteriaPlaceholder": {
      "pt": "Nome do critério em português",
      "en": "Nome do critério em inglês"
    },
    "cantBeZero": "O valor do critério não pode ser 0%",
    "cantBeMoreThan": "O valor do critério não pode ser superior a {value}",
    "addName" : {
      "pt": "Insira o nome em português",
      "en": "Insira o nome em inglês"
    },
    "maxChars": {
      "pt": "O nome em português não pode ter mais do que 250 caracteres.",
      "en": "O nome em inglês não pode ter mais do que 250 caracteres."
    }
  },
  "en": {
    "save": "Save",
    "cancel": "Cancel",
    "edit": "Edit",
    "delete": "Delete",
    "noCriteria": "No Criteria",
    "percentage": "Percentage",
    "addCriteria": "Add Criteria",
    "criterion": "Criterion",
    "criteriaPlaceholder": {
      "pt": "Criterion name in Portuguese",
      "en": "Criterion name in English"
    },
    "cantBeZero": "The criterion value cannot be 0%.",
    "cantBeMoreThan": "The value of the criterion cannot be greater than {value}",
    "addName" : {
      "pt": "Enter the name in Portuguese",
      "en": "Enter the name in English"
    },
    "maxChars": {
      "pt": "The name in Portuguese can be no longer than 250 characters.",
      "en": "The name in English can be no longer than 250 characters."
    }
  }
}
</i18n>
