<template>
  <div v-if="(canEdit && editing) || isNewUpdate || openAsEditing" class="flex flex-col p-5 gap-5">
    <fw-panel
      :title="isNewUpdate ? $t('editUpdate') : $t('addUpdate')"
      :loading="loading"
      :loading-error="loadingError"
      :after-loading-checked="afterLoadingChecked"
      custom-class="flex-1 flex gap-3 flex-col"
    >
      <div>
        <fw-label>{{ $t('title.label') }}</fw-label>
        <TranslatedInput
          v-model="newUpdateData.title"
          :placeholder="{ pt: $t('title.pt'), en: $t('title.en') }"
          :disable-autowrite="true"
          :multiline="false"
          :class="{
            error: $v.newUpdateData.title.$error,
          }"
        />
        <fw-tip v-if="$v.newUpdateData.title.$error" error>
          {{ $t('title.required') }}
        </fw-tip>
      </div>

      <div class="mb-1.5">
        <fw-label>{{ $t('updateType') }}</fw-label>

        <div v-if="!isNewUpdate">
          <fw-tag>
            {{
              newUpdateData.type == 'curriculum' && !procedure.with_interview
                ? $t(`updateTypes.curriculumLUOF`)
                : $t(`updateTypes.${newUpdateData.type}`)
            }}
          </fw-tag>
        </div>
        <b-field v-else size="is-medium">
          <b-select v-model="newUpdateData.type" placeholder="Escolha um tipo" expanded>
            <option value="notice">
              {{ $t('updateTypes.notice') }}
            </option>
            <template v-if="procedure.with_interview">
              <option value="curriculum">
                {{ $t('updateTypes.curriculum') }}
              </option>
              <option value="interview">
                {{ $t('updateTypes.interview') }}
              </option>
            </template>

            <option v-else value="curriculum">
              {{ $t('updateTypes.curriculumLUOF') }}
            </option>

            <option value="other">
              {{ $t('updateTypes.other') }}
            </option>
          </b-select>
        </b-field>

        <fw-tip v-if="$v.newUpdateData.type.$error && procedureTypeErrorMsg" error>
          {{ procedureTypeErrorMsg }}
        </fw-tip>

        <fw-panel-info v-if="procedureTypeInfoMsg" type="orange" boxed clean class="mt-3" icon>
          <p>{{ procedureTypeInfoMsg }}</p>
        </fw-panel-info>
      </div>

      <div>
        <fw-label>{{ $t('description.label') }}</fw-label>
        <TranslatedInput
          v-model="newUpdateData.description"
          :placeholder="{ pt: $t('description.pt'), en: $t('description.en') }"
          :disable-autowrite="true"
          :multiline="true"
        />
        <fw-tip v-if="$v.newUpdateData.description.$error" error>
          {{ $t('description.required') }}
        </fw-tip>
      </div>

      <div>
        <fw-label>{{ $t('files.pt.label') }}</fw-label>
        <div class="mb-2">
          <div v-if="newUpdateData.files.pt.length > 0" class="files mb-1.5">
            <RecordFileEntry
              v-for="(file, f) in newUpdateData.files.pt"
              :key="file.key"
              :can-edit="false"
              :can-remove="canEdit && (editing || isNewUpdate)"
              :allow-classified="false"
              :file="file"
              @remove="removeFile(f, 'pt')"
              @download="downloadFile(file)"
            />
          </div>
          <Uploader
            :label="$t('files.pt.upload')"
            :is-docked="true"
            layout="minimal"
            reference-id="uploader_update"
            allowed="all"
            :clear-after="true"
            input-id="upload_input"
            :files.sync="updateFilesToUpload"
            :size="0"
            :new-file-context="{}"
            file-type="file"
            file-code=""
            class="cursor-pointer update-modal-uploader"
            uploader-class="w-full rounded"
            @upload="uploaded('pt', $event)"
          />
        </div>
      </div>

      <div>
        <fw-label>{{ $t('files.en.label') }}</fw-label>
        <div>
          <div v-if="newUpdateData.files.en.length > 0" class="files mb-1.5">
            <RecordFileEntry
              v-for="(file, f) in newUpdateData.files.en"
              :key="file.key"
              :can-edit="false"
              :can-remove="canEdit && (editing || isNewUpdate)"
              :allow-classified="false"
              :file="file"
              @remove="removeFile(f, 'en')"
              @download="downloadFile(file)"
            />
          </div>

          <Uploader
            :label="$t('files.en.upload')"
            :is-docked="true"
            layout="minimal"
            reference-id="uploader_update_en"
            allowed="all"
            :clear-after="true"
            input-id="upload_input_en"
            :files.sync="updateFilesToUploadEN"
            :size="0"
            :new-file-context="{}"
            file-type="file"
            file-code=""
            class="cursor-pointer update-modal-uploader"
            uploader-class="w-full rounded"
            @upload="uploaded('en', $event)"
          />
        </div>
      </div>

      <fw-tip v-if="$v.newUpdateData.files.$error" error>
        {{ $t('files.required') }}
      </fw-tip>

      <div class="mt-2">
        <!-- b-switch checked has value of false (:true-value="false") and value of true unchecked (:false-value="true") -->
        <b-switch
          v-model="newUpdateData.is_private"
          :true-value="false"
          :false-value="true"
          :disabled="!isNewUpdate && newUpdateData.type != 'other'"
        >
          {{ $t('publicUpdate') }}<br />
          <div class="text-xs text-gray-500" v-html="$t('selectThisOption')"></div>
        </b-switch>

        <fw-tip v-if="$v.newUpdateData.is_private.$error" error>
          {{ $t('isPrivate.mustBeChecked') }}
        </fw-tip>
      </div>
    </fw-panel>

    <div class="flex items-center justify-end gap-5">
      <fw-button type="link-muted" @click.native="$emit('close')">{{ $t('close') }}</fw-button>
      <fw-button type="primary" :disabled="loading" @click.native="saveUpdate">{{ $t('save') }}</fw-button>
    </div>
  </div>
  <div v-else class="flex flex-col p-5 gap-5">
    <fw-panel :title="$t('update')">
      <template v-if="canEdit" #toolbar>
        <fw-button type="link" @click.native="editing = !editing">{{ $t('edit') }}</fw-button>
      </template>

      <div class="flex flex-col gap-2">
        <div class="flex flex-col">
          <div class="flex gap-2">
            <div class="flex-shrink-0">
              <fw-tag class="uppercase">{{ language }}</fw-tag>
            </div>
            <fw-heading size="h2" marginless>
              {{ newUpdateData.title[language] }}
            </fw-heading>
          </div>
          <div class="flex gap-2">
            <div class="flex-shrink-0">
              <fw-tag class="uppercase">{{ secondaryLanguage }}</fw-tag>
            </div>
            <fw-heading size="sm" marginless class="mt-1">
              {{ newUpdateData.title[secondaryLanguage] }}
            </fw-heading>
          </div>
        </div>

        <fw-panel-info class="flex flex-col gap-2 mt-5" :label="$t('description.label')">
          <div class="flex gap-2">
            <div class="flex-shrink-0">
              <fw-tag class="uppercase">{{ language }}</fw-tag>
            </div>
            <div size="h2" marginless>
              {{ newUpdateData.description[language] }}
            </div>
          </div>
          <div class="flex gap-2 my-2">
            <div class="flex-shrink-0">
              <fw-tag class="uppercase">{{ secondaryLanguage }}</fw-tag>
            </div>
            <div class="mt-1">
              {{ newUpdateData.description[secondaryLanguage] }}
            </div>
          </div>
        </fw-panel-info>

        <fw-panel-info class="flex flex-col gap-1" :label="$t('files.label')">
          <div v-for="lang in langs" :key="lang" :class="{ 'mt-1': newUpdateData.files[lang].length }">
            <div class="flex gap-2 my-2">
              <div class="flex-shrink-0">
                <fw-tag class="uppercase">{{ lang }}</fw-tag>
              </div>
              <fw-panel-info v-if="!newUpdateData.files[lang].length" clean centered class="p-0.5">{{
                $t('noFiles')
              }}</fw-panel-info>
              <fw-panel-info v-else clean centered class="p-0.5"
                >{{ newUpdateData.files[lang].length }} {{ $t('file') }}
                {{ newUpdateData.files[lang].length > 1 ? 's' : '' }}</fw-panel-info
              >
            </div>
            <div v-if="newUpdateData.files[lang].length">
              <RecordFileEntry
                v-for="(file, f) in newUpdateData.files[lang]"
                :key="file.key"
                :can-edit="false"
                :can-remove="canEdit && (editing || isNewUpdate)"
                :allow-classified="false"
                :file="file"
                @remove="removeFile(f, lang)"
                @download="downloadFile(file)"
              />
            </div>
          </div>
        </fw-panel-info>

        <fw-panel-info class="flex flex-col gap-1" :label="$t('updateType')">
          <fw-tag>
            {{
              newUpdateData.type == 'curriculum' && !procedure.with_interview
                ? $t(`updateTypes.curriculumLUOF`)
                : $t(`updateTypes.${newUpdateData.type}`)
            }}
          </fw-tag>
        </fw-panel-info>
      </div>
    </fw-panel>
    <div class="flex items-center justify-end gap-5">
      <fw-button type="link-muted" @click.native="$emit('close')">{{ $t('close') }}</fw-button>
    </div>
  </div>
</template>

<script>
import Uploader from '@/fw-modules/fw-core-vue/storage/components/PathUploader.vue'
import RecordFileEntry from '@/fw-modules/fw-core-vue/ui/components/form/RecordFileEntry'
import TranslatedInput from '@/fw-modules/fw-core-vue/ui/components/form/TranslatedInput'
import { required, minLength, helpers } from 'vuelidate/lib/validators'

import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import ServiceStorage from '@/fw-modules/fw-core-vue/storage/services/ServiceStorage'
import Dates from '@/fw-modules/fw-core-vue/utilities/dates'

const updateType = prop =>
  helpers.withParams({ type: 'updateType', prop }, value => {
    if (!value) return false
    if (value == 'interview' && !prop.validations.interview) return false
    if (value == 'curriculum' && !prop.validations.curriculum) return false

    return true
  })

export default {
  name: 'ModalProcedureUpdateEntry',
  components: {
    Uploader,
    RecordFileEntry,
    TranslatedInput,
  },
  props: {
    procedureKey: {
      type: String,
      default: '',
    },
    procedure: {
      type: Object,
      default: () => {
        return {}
      },
    },
    data: {
      type: Object,
      default() {
        return {
          title: {
            pt: '',
            en: '',
          },
          description: {
            pt: '',
            en: '',
          },
          files: [],
          is_private: false, // true => Atualização privada; false => Atualização pública
          publish_date: Dates.formatToAPI(new Date()),
          is_empty: true,
          type: 'other',
        }
      },
    },
    canEdit: {
      type: Boolean,
      default: false,
    },
    openAsEditing: {
      type: Boolean,
      default: false,
    },
  },

  validations() {
    return {
      newUpdateData: {
        title: {
          pt: { required, min: minLength(2) },
          en: { required, min: minLength(2) },
        },
        description: {
          pt: { required, min: minLength(2) },
          en: { required, min: minLength(2) },
        },
        type: {
          required,
          invalidType: updateType(this.procedure),
        },
        is_private: { checked: (value, vm) => vm.type == 'other' || value === false },
        files: {
          required: (value, vm) =>
            vm.type == 'other' || (vm.files.pt && vm.files.pt.length) || (vm.files.en && vm.files.en.length),
        },
      },
    }
  },

  data() {
    return {
      loading: false,
      editing: this.openAsEditing,
      loadingError: false,
      afterLoadingChecked: true,
      updateFilesToUpload: [],
      updateFilesToUploadEN: [],
      newUpdateData: {
        title: {
          pt: '',
          en: '',
        },
        description: {
          pt: '',
          en: '',
        },
        files: {
          pt: [],
          en: [],
        },
        is_private: false,
        publish_date: Dates.formatToAPI(new Date()),
        type: 'other',
      },
      errors: {
        title: '',
        description: '',
        files: '',
      },
    }
  },

  computed: {
    api() {
      return this.$store.state.api.base
    },

    isNewUpdate() {
      return this.newUpdateData && !this.newUpdateData.key
    },

    language() {
      return this.$store.state.language
    },

    secondaryLanguage() {
      return this.language === 'pt' ? 'en' : 'pt'
    },

    langs() {
      return [this.language, this.secondaryLanguage]
    },

    procedureTypeErrorMsg() {
      if (this.procedure.with_interview) {
        if (this.newUpdateData.type == 'interview' && !this.procedure.validations.interview) {
          return 'Termine a reunião da entrevista para poder criar publicações deste tipo.'
        }

        if (this.newUpdateData.type == 'curriculum' && !this.procedure.validations.curriculum) {
          return 'Termine a reunião da avaliação curricular para poder criar publicações deste tipo.'
        }
      } else if (this.newUpdateData.type == 'curriculum' && !this.procedure.validations.curriculum) {
        return 'Termine a reunião da avaliação curricular para poder criar publicações deste tipo.'
      }

      return null
    },

    procedureTypeInfoMsg() {
      if (this.newUpdateData.type == 'notice') {
        return 'Após anexação do edital, poderá publicar o procedimento o que irá gerar as notificações de prazo de candidaturas e pré-agendamento ao júri.'
      }

      if (this.procedure.with_interview) {
        if (this.newUpdateData.type == 'curriculum') {
          return 'Após anexação da ata, que se tornará pública, existindo candidatos excluídos os mesmos serão notificados desta publicação.'
        } else if (this.newUpdateData.type == 'interview') {
          return 'Após anexação da ata, poderá terminar a fase avaliativa o que irá gerar as notificações a todos os candidatos admitidos e abrir o período de audiência de interessados.'
        }
      } else if (this.newUpdateData.type == 'curriculum') {
        return 'Após anexação da ata, poderá terminar a fase avaliativa o que irá gerar as notificações a todos os candidatos e abrir o período de audiência de interessados.'
      }

      return null
    },
  },

  created() {
    if (this.data != null && !this.data.is_empty) {
      console.log('Procedure update data ========> ', this.data)
      const dataCopy = JSON.parse(JSON.stringify(this.data))
      dataCopy.files = {
        pt: [],
        en: [],
      }
      this.data.files.forEach(file => {
        if (file.locale == 'en') {
          dataCopy.files.en.push(file)
        } else {
          dataCopy.files.pt.push(file)
        }
      })
      this.newUpdateData = dataCopy
      this.$v.$touch()
    }
  },

  methods: {
    async downloadFile(file) {
      const url = ServiceStorage.getFileUrl(file, this.$store.state.session.user.token)
      utils.downloadFile(url, file.filename)
    },

    removeFile(f, locale) {
      console.log('remove file', f, locale)
      this.$buefy.dialog.confirm({
        message: this.$t('deleteFileConfirm'),
        onConfirm: async () => {
          let file = this.newUpdateData.files[locale][f]
          try {
            if (!this.isNewUpdate) {
              const result = await this.api.deleteFileUpdateProcedure(this.procedureKey, this.newUpdateData.key, file)
              console.log(result)
            }

            this.newUpdateData.files[locale].splice(f, 1)
          } catch (error) {
            console.error('Ocorreu um erro ao eliminar o ficheiro')
          }
        },
      })
    },

    async uploaded(locale, files) {
      let newfiles = []
      for (const file of files) {
        if (file.response.data) {
          if (file.response.status === 'success') {
            let fileData = file.response.data.file
            fileData['locale'] = locale
            newfiles.push(fileData)
          }
        }
      }
      this.newUpdateData.files[locale] = this.newUpdateData.files[locale].concat(newfiles)

      // if (!this.isNewUpdate) {
      //   console.log('Relate files')
      //   for (let file of newfiles) {
      //     await this.api.relateUpdateFile(this.procedureKey, this.newUpdateData.key, file.key, locale)
      //   }
      // }
    },

    async saveUpdate() {
      this.$v.$touch()
      if (this.$v.$invalid) return

      if (this.newUpdateData.type == 'curriculum' && this.procedure.validations.has_rejected_candidates) {
        this.$buefy.dialog.confirm({
          cancelText: this.$t('cancel'),
          confirmText: this.$t('confirm'),
          message: `Ao guardar a publicação, vão ser enviadas notificações para os candidatos
            não admitidos na fase de admissão. Tem a certeza que quer continuar?`,
          onConfirm: async () => {
            this.doSaveUpdate()
          },
        })
      } else {
        this.doSaveUpdate()
      }
    },

    doSaveUpdate() {
      if (!this.isNewUpdate) {
        this.doEditUpdate()
      } else {
        this.doCreateUpdate()
      }
    },

    async doEditUpdate() {
      if (this.$v.$invalid) return
      this.loading = true

      const updateData = JSON.parse(JSON.stringify(this.newUpdateData))
      try {
        const result = await this.api.editProcedureUpdate(this.procedureKey, this.newUpdateData.key, updateData)
        this.loading = false
        this.$emit('edited-update', result)
        this.$emit('close')
      } catch (error) {
        console.error(error)
        this.loading = false
        this.loadingError = true
        this.$buefy.snackbar.open({
          message: this.$t('errorOccurred'),
          type: 'is-danger',
        })
      }
    },

    async doCreateUpdate() {
      if (this.$v.$invalid) return
      this.loading = true
      try {
        const updateData = JSON.parse(JSON.stringify(this.newUpdateData))
        const result = await this.api.createProcedureUpdate(this.procedureKey, updateData)
        updateData['key'] = result.key

        // if (updateData.files.pt.length > 0) {
        //   console.log('Relate files PT')
        //   for (let file of updateData.files.pt) {
        //     await this.api.relateUpdateFile(this.procedureKey, result.key, file.key, file.locale)
        //   }
        // }
        // if (updateData.files.en.length > 0) {
        //   console.log('Relate files EN')
        //   for (let file of updateData.files.en) {
        //     await this.api.relateUpdateFile(this.procedureKey, result.key, file.key, file.locale)
        //   }
        // }

        this.$buefy.snackbar.open({
          message: this.$t('updateSaved'),
          type: 'is-success',
        })
        this.loading = false
        this.$emit('new-update', result)
        this.$emit('close')
      } catch (error) {
        console.error(error)

        this.loading = false
        this.loadingError = true

        this.$buefy.snackbar.open({
          message: this.$t('errorOccurred'),
          type: 'is-danger',
        })
      }
    },
  },
}
</script>

<style lang="postcss">
.update-modal-uploader .file-uploads {
  @apply w-full;
}
</style>

<i18n>
{
  "pt": {
    "update": "Publicação",
    "editUpdate": "Editar publicação",
    "addUpdate": "Adicionar publicação",
    "publicUpdate": "Publicação pública",
    "selectThisOption": "Selecione esta opção para a publicação aparecer publicamente na página do procedimento.<br /> Caso contrário, a atualização ficará disponível apenas ao júri.",
    "title": {
      "label": "Título",
      "pt": "Título em português",
      "en": "Título em inglês",
      "required": "Insira um título em português e inglês."
    },
    "description": {
      "label": "Descrição",
      "pt": "Descrição em português",
      "en": "Descrição em inglês",
      "required": "Insira uma descriçao em português e inglês."
    },
    "files": {
      "label": "Ficheiros",
      "required": "Insira ficheiros em português ou inglês.",
      "pt": {
        "upload":"Carregar ficheiros em português",
        "label":"Ficheiros em português"
      },
      "en": {
        "upload":"Carregar ficheiros em inglês",
        "label":"Ficheiros em inglês"
      }
    },
    "isPrivate": {
      "mustBeChecked": "Para o tipo selecionado a atualização tem que ser pública"
    },
    "updateType": "Tipo",
    "updateTypes": {
      "notice": "Edital",
      "curriculum": "Ata Admissão/exclusão + AC",
      "curriculumLUOF": "Ata Admissão/exclusão + AC + LUOF",
      "interview": "Ata Entrevista + LUOF",
      "other": "Outro"
    },
    "cancel": "Cancelar",
    "confirm": "Continuar",
    "close": "Fechar",
    "save": "Guardar",
    "edit": "Editar",
    "noFiles": "Sem ficheiros neste idioma.",
    "file": "ficheiro",
    "deleteFileConfirm": "Tem a certeza que deseja eliminar o ficheiro?",
    "errorOccurred": "Ocorreu um erro ao guardar as alterações.",
    "updateSaved": "Atualização guardada"
  },
  "en": {
    "update": "Publication",
    "editUpdate": "Edit publication",
    "addUpdate": "Add publication",
    "publicUpdate": "Public publication",
    "selectThisOption": "Select this option for the publication to appear publicly on the procedure page.<br /> Otherwise, the update will only be available to the jury.",
    "title": {
      "label": "Title",
      "pt": "Title in Portuguese",
      "en": "Title in English",
      "required": "Enter a title in Portuguese and English."
    },
    "description": {
      "label": "Description",
      "pt": "Description in Portuguese",
      "en": "Description in English",
      "required": "Insira uma descriçao in Portuguese and English."
    },
    "files": {
      "label": "Files",
      "required": "Upload files in Portuguese or English.",
      "pt": {
        "upload":"Upload files in Portuguese",
        "label":"Files in Portuguese"
      },
      "en": {
        "upload":"Upload files in English",
        "label":"Files in English"
      }
    },
    "isPrivate": {
      "mustBeChecked": "Para o tipo selecionado a atualização tem que ser pública"
    },
    "cancel": "Cancel",
    "confirm": "Confirm",
    "close": "Close",
    "save": "Save",
    "edit": "Edit",
    "noFiles": "No files in this language.",
    "file": "files",
    "updateType": "Type",
    "updateTypes": {
      "notice": "Edital",
      "curriculum": "Minutes of Admission/Exclusion + AC",
      "curriculumLUOF": "Minutes of Admission/Exclusion + AC + LUOF",
      "interview": "Minutes of Interview + LUOF",
      "other": "Other"
    },
    "deleteFileConfirm": "Are you sure you want to delete the file?",
    "errorOccurred": "An error occurred while saving the changes.",
    "updateSaved": "Update saved"
  }
}
</i18n>
