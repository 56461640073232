<template>
  <div v-if="!loading && procedure">
    <MeetingHeader
      :procedure="procedure"
      :is-current-proc-manager="isCurrentProcManager"
      :interview-applications="interviewApplications"
      :meeting="meeting"
      :step-data="currentStepData"
      :current-view-checks="currentViewChecks"
      :all-candidates-rejected="allCandidatesRejected"
      :all-candidates-have-pre-review="allCandidatesHavePreReview"
      :can-manage="canManage"
      :can-send-interview-notification="canSendInterviewNotification"
      :saving-data="loading"
      :interview-notification-sent="interviewNotificationSent"
      @create-interview-meeting="createInterviewMeeting"
      @send-interview-notification="sendInterviewNotification"
      @start-meeting="startMeeting"
      @end-meeting="endMeeting"
      @close-meeting="closeMeeting"
      @reopen-meeting="reopenMeeting"
      @download-minute="downloadMinute"
    />

    <Presences
      :procedure="procedure"
      :current-view-checks="currentViewChecks"
      :can-edit="canEditJuryPresences"
      class="my-5"
      @set-user-presence="setUserPresence"
    />

    <fw-panel-info
      v-if="!canEdit && currentViewChecks && !currentViewChecks.meeting_started && !currentViewChecks.meeting_ended"
      clean
      class="mb-5"
      >Após abrir a reunião de avaliação, poderá avançar com a admissão de candidatos.</fw-panel-info
    >

    <fw-panel title="Lista de candidatos" boxed="xs" custom-class="bg-white">
      <template #toolbar>
        <CandidatesInfoDownload
          v-if="submittedApplications.length && allowDownloadCandidatesZip"
          :downloading="downloadingFiles"
          @download="downloadCandidates"
        />

        <b-dropdown
          v-if="submittedApplications.length > 0 && canEdit"
          scrollable
          max-height="500"
          aria-role="list"
          position="is-bottom-left"
        >
          <fw-button-dropdown slot="trigger" type="xlight" class="flex flex-col" label="Editar em bloco" />
          <b-dropdown-item aria-role="menu-item" :focusable="true" @click.native="toggleCandidatesAdmission(true)">
            <div class="font-medium">Admitir todos os candidatos</div>
          </b-dropdown-item>
          <b-dropdown-item aria-role="menu-item" :focusable="true" @click.native="toggleCandidatesAdmission(false)">
            <div class="font-medium">Excluir todos os candidatos</div>
          </b-dropdown-item>
        </b-dropdown>
      </template>
      <div v-if="submittedApplications.length > 0">
        <Person
          v-for="application in submittedApplications"
          :key="application.key"
          :person="application"
          class="border-b border-gray-100"
          :selectable="false"
          :clickable="true"
          @click.native="openApplication(application.candidate_key)"
        >
          <template #options>
            <div class="flex gap-10 items-center" @click.stop>
              <b-tooltip
                v-if="application.manager_admission_review"
                label="Pré-análise dos requisitos legais de admissão disponível"
                position="is-bottom"
                type="is-light"
              >
                <fw-icon-message class="w-5 h-5 opacity-50" />
              </b-tooltip>
              <OnoffOption
                v-if="canEdit"
                :options="['Admitido', 'Excluído']"
                :selected-option="
                  application.admission_status === 'rejected'
                    ? 'Excluído'
                    : application.admission_status === 'selected'
                    ? 'Admitido'
                    : null
                "
                :disabled="!canEdit"
                @input="admitUser(application.candidate_key, $event)"
              />
              <fw-tag
                v-else-if="application.admission_status === 'selected'"
                type="primary"
                class="w-24 flex items-center justify-center"
              >
                {{ $t('admitted') }}
              </fw-tag>
              <fw-tag
                v-else-if="application.admission_status === 'rejected'"
                type="medium"
                class="w-24 flex items-center justify-center"
              >
                {{ $t('rejected') }}
              </fw-tag>
            </div>
          </template>
        </Person>
      </div>
      <fw-panel-info v-else centered empty>
        Não existem candidaturas para apresentar.
      </fw-panel-info>
    </fw-panel>

    <fw-panel
      v-if="rejectedApplications.length"
      title="Fundamentação das exclusões"
      boxed="xs"
      class="my-5"
      custom-class="bg-white"
    >
      <Person
        v-for="application in rejectedApplications"
        :key="application.key"
        :person="application"
        :clickable="canEdit"
        class="border-b border-gray-100"
        @click.native="canEdit ? openRejectedReason(application) : false"
      >
        <template #options>
          <fw-button v-if="canEdit" type="link" @click.native="openRejectedReason(application)">
            Editar
          </fw-button>
        </template>
        <template #secondline>
          <div v-if="application.rejected_reason" class="flex gap-2 pl-2 pt-2">
            <div class="flex-shrink-0"><fw-icon-exam class="w-5 h-5 opacity-50" /></div>
            <v-clamp autoresize :max-lines="5">
              {{ application.rejected_reason }}
            </v-clamp>
          </div>
          <div v-else class="text-sm text-gray-500">Sem fundamentação definida.</div>
        </template>
      </Person>
    </fw-panel>

    <fw-panel-info
      v-if="!canEdit && currentViewChecks && currentViewChecks.meeting_started && !currentViewChecks.meeting_ended"
    >
      <p v-if="!allRejectedCandidatesWithReason" class="mb-2">
        Existem candidatos excluídos sem fundamentação de exclusão definidas.
      </p>
      <p>Para editar a admissão dos candidatos não podem existir mapas de avaliação fechados.</p>
    </fw-panel-info>

    <fw-panel title="Seriação Individual" subtitle="Por membro do júri" boxed="xs" class="my-5" custom-class="bg-white">
      <fw-panel-info v-if="procedure.presidents.length === 0 && procedure.vogals.length === 0" centered empty>
        Os elementos do júri ainda não foram definidos.
      </fw-panel-info>
      <div v-else>
        <RecordEvaluationUser
          v-for="president in procedure.presidents"
          :key="president.jury_key"
          is-president
          :jury="president"
          :current-view-checks="currentViewChecks"
          :has-candidates="submittedApplications.length > 0"
          :can-see-all-maps="canManage"
          :loading="loading"
          :map="evaluationMaps[president.jury_key]"
          view="curriculum"
          @open-map="openEvaluationMap"
        />
        <RecordEvaluationUser
          v-for="vogal in procedure.vogals"
          :key="vogal.jury_key"
          :jury="vogal"
          :current-view-checks="currentViewChecks"
          :has-candidates="submittedApplications.length > 0"
          :map="evaluationMaps[vogal.jury_key]"
          :can-see-all-maps="canManage"
          :loading="loading"
          view="curriculum"
          @open-map="openEvaluationMap"
        />
      </div>
    </fw-panel>

    <fw-panel
      v-if="!isScholarship"
      title="Decisão de mérito absoluto"
      subtitle="Por membro do júri"
      boxed
      custom-class="bg-white mb-7 shadow-md relative rounded-md"
    >
      <div v-if="procedure.presidents.length === 0 && procedure.vogals.length === 0" class="text-gray-500">
        Sem júri definido
      </div>
      <div v-else>
        <Person v-for="president in procedure.presidents" :key="president.key" :person="president">
          <template #secondline>
            <div class="flex text-sm text-gray-400">
              <div>Presidente</div>
              <div
                v-if="president.as_president_substitute === true"
                class="text-xs bg-green-500 px-1.5 py-0.5 text-black text-opacity-60"
              >
                Suplente
              </div>
            </div>
          </template>
          <template #options>
            <div class="flex justify-end">
              <fw-button type="light">Bloquear Mapa</fw-button>
            </div>
          </template>
        </Person>
        <Person v-for="vogal in procedure.vogals" :key="vogal.key" :person="vogal">
          <template #secondline>
            <div class="flex text-sm text-gray-400">
              <div>Vogal</div>
              <div
                v-if="vogal.as_president_substitute === true"
                class="text-xs bg-green-500 px-1.5 py-0.5 text-black text-opacity-60"
              >
                Suplente
              </div>
            </div>
          </template>
          <template #options>
            <div class="flex justify-end">
              <fw-button type="light">Bloquear Mapa</fw-button>
            </div>
          </template>
        </Person>
      </div>
    </fw-panel>

    <fw-panel
      v-if="!isScholarship"
      title="Mérito absoluto"
      subtitle="Resultado final"
      boxed
      custom-class="bg-white mb-7 shadow-md relative rounded-md"
    >
      <PeopleList v-if="absoluteMerithApplications.length > 0" :list="absoluteMerithApplications"></PeopleList>
      <div v-else class="text-gray-500">Sem resultados</div>
    </fw-panel>
  </div>
</template>

<script>
import Presences from '@/components/panels/Presences'
import OnoffOption from '@/fw-modules/fw-core-vue/ui/components/form/onoffOption'
import Person from '@/fw-modules/fw-core-vue/ui/components/cards/Person'
import MeetingHeader from '@/components/panels/MeetingHeader'
import CandidatesInfoDownload from '@/components/buttons/CandidatesInfoDownload'
import RecordEvaluationUser from '@/components/records/RecordEvaluationUser'

import Dates from '@/fw-modules/fw-core-vue/utilities/dates'

export default {
  components: {
    Presences,
    OnoffOption,
    Person,
    MeetingHeader,
    CandidatesInfoDownload,
    RecordEvaluationUser,
  },
  props: {
    procedure: {
      type: Object,
      default: () => {
        return {}
      },
    },
    interviewNotificationSent: {
      type: Boolean,
      default: false,
    },
    currentViewChecks: {
      type: Object,
      required: true,
    },
    evaluationMaps: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: true,
    },
    canManage: {
      type: Boolean,
      default: true,
    },
    isCurrentProcManager: {
      type: Boolean,
      default: true,
    },
    isScholarship: {
      type: Boolean,
      default: true,
    },
    canEdit: {
      type: Boolean,
      default: true,
    },
    canEditJuryPresences: {
      type: Boolean,
      default: true,
    },
    allowDownloadCandidatesZip: {
      type: Boolean,
      default: false,
    },
    downloadingFiles: {
      type: Boolean,
      default: false,
    },
    canSendInterviewNotification: {
      type: Boolean,
      default: false,
    },
    myJuryInfo: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },

  data() {
    return {
      mapSearchQuery: '',
      currentStepData: {
        text: 'Avaliação curricular',
        value: 'seriation-curriculum',
      },
      selectedJury: null,
    }
  },

  computed: {
    interviewDefined() {
      return Boolean(this.procedure.meetings.find(el => el.goal == 'seriation-interview'))
    },

    submittedApplications() {
      return this.procedure.applications.filter(el => el.application_status === 'submitted')
    },

    rejectedApplications() {
      return this.procedure.applications.filter(el => el.admission_status === 'rejected')
    },

    allRejectedCandidatesWithReason() {
      console.log('this.rejectedApplications :>> ', this.rejectedApplications)
      return !this.rejectedApplications.some(el => !el.rejected_reason)
    },

    interviewApplications() {
      return this.procedure.applications?.filter(el => !el.rejected_step)
    },

    allCandidatesRejected() {
      if (this.procedure.applications?.length == 0) {
        return false
      }

      let list = this.procedure.applications.filter(
        el => el.application_status === 'submitted' && el.rejected_step !== 'admission'
      )
      return !list.length > 0
    },

    allCandidatesHavePreReview() {
      if (this.procedure.applications.length == 0) {
        return true
      }

      let list = this.procedure.applications.filter(el => el.application_status === 'submitted')
      return list.every(el => el.manager_id && el.manager_notes && el.manager_admission_review)
    },

    meeting() {
      return this.procedure.meetings.find(el => el.goal == 'seriation-curriculum')
    },
  },

  methods: {
    downloadCandidates(option = 'files') {
      if (this.allowDownloadCandidatesZip) {
        this.$emit('download-candidates', option)
      }
    },

    admitUser(candidateKey, rawValue) {
      const value = rawValue === 0
      const candidate = this.procedure.applications.find(el => el.candidate_key === candidateKey)
      if (!value) {
        candidate.rejected_reason = null
      }
      this.$emit('admit-user', candidateKey, value)
    },

    toggleCandidatesAdmission(selected) {
      this.$emit(
        'admit-bulk-users',
        this.procedure.applications.map(el => el.candidate_key),
        selected
      )
    },

    openApplication(candidateKey) {
      this.$emit('open-application', candidateKey)
    },

    setUserPresence(personKey, option) {
      console.log('set-user-presence', personKey, option)
      this.$emit('set-user-presence', personKey, option)
    },

    startMeeting() {
      if (this.meeting) {
        if (Dates.now().isSame(Dates.build(this.meeting.date), 'day')) {
          this.$emit('start-meeting')
        } else {
          this.$emit('save-meeting', 'interview', this.meeting)
        }
      } else {
        this.$emit('save-meeting', 'curriculum')
      }
    },

    sendInterviewNotification() {
      this.$emit('send-interview-notification')
    },

    endMeeting() {
      this.$emit('end-meeting')
    },

    closeMeeting() {
      this.$emit('close-meeting')
    },

    reopenMeeting() {
      this.$emit('reopen-meeting')
    },

    downloadMinute(locale) {
      this.$emit('download-minute', locale)
    },

    openEvaluationMap(user) {
      this.$emit('open-evaluation-map', user)
    },

    openRejectedReason(application) {
      this.$emit('open-rejected-reason', application)
    },

    createInterviewMeeting() {
      this.$emit('create-interview-meeting')
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "admitted": "Admitido",
    "rejected": "Excluído"
  },
  "en": {
    "admitted": "Admitted",
    "rejected": "Excluded"
  }
}
</i18n>
