<template>
  <b-dropdown aria-role="list" position="is-bottom-left">
    <fw-button-dropdown slot="trigger" aria-role="listitem" type="xlight" :label="$t('download')" />
    <b-dropdown-item ref="actionitem" custom class="paddingless marginless w-56" aria-role="menuitem">
      <fw-button
        type="basic-action"
        :disabled="downloading && fileType == 'files'"
        class="w-full text-left"
        @click.native="downloadFile('files')"
      >
        <template #icon>
          <div class="w-5 h-5">
            <fw-icon-loading v-if="downloading && fileType == 'files'" class="flex-shrink-0 w-4 h-4" />
            <fw-icon-download v-else class="flex-shrink-0 w-5 h-5" />
          </div>
        </template>
        {{ $t('files') }}
      </fw-button>
    </b-dropdown-item>
    <b-dropdown-item ref="actionitem" custom class="paddingless" aria-role="menuitem">
      <fw-button
        type="basic-action"
        :disabled="downloading && fileType == 'summary'"
        class="w-full text-left"
        @click.native="downloadFile('summary')"
      >
        <template #icon>
          <div class="w-5 h-5">
            <fw-icon-loading v-if="downloading && fileType == 'summary'" class="flex-shrink-0 w-4 h-4" />
            <fw-icon-download v-else class="flex-shrink-0 w-5 h-5" />
          </div>
        </template>
        {{ $t('summary') }}
      </fw-button>
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },

    downloading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fileType: null,
    }
  },
  methods: {
    downloadFile(type) {
      this.fileType = type
      this.$emit('download', type)
    },
  },
}
</script>

<i18n>
{
  "en": {
    "files": "ZIP of the Candidate Files",
    "summary": "Summary of the candidates",
    "download": "Download"
  },
  "pt": {
    "files": "Ficheiros de todas as candidaturas (.zip)",
    "summary": "Sumário de candidaturas (.csv)",
    "download": "Descarregar"
  }
}
</i18n>
