var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.procedure)?_c('section',[_c('fw-panel',{attrs:{"title":_vm.$t('calendar'),"featured":"","loading":_vm.savingData,"after-loading-checked":""},scopedSlots:_vm._u([{key:"toolbar",fn:function(){return [_c('div',{staticClass:"flex gap-1 items-center"},[(_vm.editMode && _vm.$v.$error)?_c('div',{staticClass:"p-1 mx-5 text-red-500 flex items-center gap-1 text-sm font-medium"},[_c('fw-icon-error-warning',{staticClass:"w-6 h-6"}),_c('span',{staticClass:"hidden lg:block"},[_vm._v(_vm._s(_vm.$t('thereAreErrors')))])],1):_vm._e(),(_vm.canEdit)?_c('div',{staticClass:"flex gap-5"},[_c('fw-button',{attrs:{"type":!_vm.editMode ? 'link' : 'link-light',"disabled":_vm.savingData,"loading":_vm.savingData},nativeOn:{"click":function($event){return _vm.toggleEditMode()}}},[_vm._v(" "+_vm._s(_vm.editMode ? _vm.$t('close') : _vm.$t('edit'))+" ")]),(_vm.editMode)?_c('fw-button',{attrs:{"type":"primary","disabled":_vm.savingData,"loading":_vm.savingData},nativeOn:{"click":function($event){return _vm.saveProc()}}},[_vm._v(_vm._s(_vm.$t('save')))]):_vm._e()],1):_vm._e()])]},proxy:true}],null,false,1106975668)}),_c('fw-panel',{staticClass:"my-4",attrs:{"title":_vm.$t('publicationDates'),"boxed":"sm","custom-class":"bg-white flex flex-col gap-3"}},[_c('div',{staticClass:"flex flex-col sm:flex-row gap-5"},[_c('div',{staticClass:"sm:w-1/3"},[_c('span',{staticClass:"font-semibold text-gray-500 mb-1 text-xs"},[_vm._v(_vm._s(_vm.isScholarship ? 'Publicação Euraxess' : 'Publicação no DR'))]),(!_vm.editMode || !_vm.canEdit)?_c('div',{staticClass:"font-medium"},[_vm._v(" "+_vm._s(_vm._f("formatDate")(_vm.publishedDrDate))+" ")]):_c('b-datepicker',{class:{
            error: _vm.$v.publishedDrDate.$error,
          },attrs:{"placeholder":_vm.$t('chooseDate'),"icon":"calendar-alt","locale":"pt-PT","trap-focus":"","disabled":!_vm.canEdit && _vm.editMode},on:{"icon-right-click":function($event){return _vm.clearDate('published_dr_date')}},model:{value:(_vm.$v.publishedDrDate.$model),callback:function ($$v) {_vm.$set(_vm.$v.publishedDrDate, "$model", $$v)},expression:"$v.publishedDrDate.$model"}}),(_vm.$v.publishedDrDate.$error)?_c('fw-tip',{attrs:{"error":""}},[_vm._v(" "+_vm._s(_vm.$t('enterDate'))+" ")]):_vm._e()],1),_c('div',{staticClass:"sm:w-1/3"},[_c('span',{staticClass:"font-semibold text-gray-500 mb-1 text-xs"},[_vm._v(_vm._s(_vm.$t('applicationsOpening')))]),(!_vm.editMode || !_vm.canEdit)?_c('div',{staticClass:"font-medium"},[_vm._v(_vm._s(_vm._f("formatDate")(_vm.publishStart)))]):_c('b-datepicker',{class:{
            error: _vm.$v.publishStart.$error,
          },attrs:{"placeholder":_vm.$t('chooseDate'),"icon":"calendar-alt","locale":"pt-PT","trap-focus":"","disabled":!_vm.canEdit && _vm.editMode},on:{"input":function($event){return _vm.publishStartChanged()},"icon-right-click":function($event){_vm.publishStart = null}},model:{value:(_vm.$v.publishStart.$model),callback:function ($$v) {_vm.$set(_vm.$v.publishStart, "$model", $$v)},expression:"$v.publishStart.$model"}}),(_vm.$v.publishStart.$error)?_c('fw-tip',{attrs:{"error":""}},[(!_vm.$v.publishStart.min)?_c('span',[_vm._v(_vm._s(_vm.$t('enterLaterOpeningDateDate')))]):_c('span',[_vm._v(_vm._s(_vm.$t('enterOpeningDate')))])]):_vm._e()],1),_c('div',{staticClass:"sm:w-1/3"},[_c('span',{staticClass:"font-semibold text-gray-500 mb-1 text-xs"},[_vm._v(_vm._s(_vm.$t('closingApplications')))]),(!_vm.editMode || !_vm.canEdit)?_c('div',{staticClass:"font-medium"},[_vm._v(_vm._s(_vm._f("formatDate")(_vm.publishEnd)))]):_c('b-datepicker',{class:{
            error: _vm.$v.publishEnd.$error,
          },attrs:{"placeholder":_vm.$t('chooseDate'),"locale":"pt-PT","icon":"calendar-alt","trap-focus":"","position":"is-bottom-left","disabled":!_vm.canEdit && _vm.editMode},on:{"icon-right-click":function($event){_vm.publishEnd = null}},model:{value:(_vm.$v.publishEnd.$model),callback:function ($$v) {_vm.$set(_vm.$v.publishEnd, "$model", $$v)},expression:"$v.publishEnd.$model"}}),(_vm.$v.publishEnd.$error)?_c('fw-tip',{attrs:{"error":""}},[(!_vm.$v.publishEnd.min)?_c('span',[_vm._v(_vm._s(_vm.$t('enterLaterClosingDateDate')))]):_c('span',[_vm._v(_vm._s(_vm.$t('enterClosingDate')))])]):_vm._e()],1)])]),_c('fw-panel',{staticClass:"my-5",attrs:{"title":_vm.$t('meetingsDates'),"custom-class":"bg-white"}},[_c('ExpandList',{attrs:{"add-title":_vm.$t('addMeeting'),"empty":!_vm.meetings || _vm.meetings.length < 1,"show-add-items":_vm.canAddNewMeeting},on:{"add-element":_vm.newMeeting},scopedSlots:_vm._u([{key:"list",fn:function(){return [((!_vm.meetings || _vm.meetings.length < 1) && !_vm.canAddNewMeeting)?_c('fw-panel-info',{attrs:{"type":"basic","centered":""}},[_vm._v(_vm._s(_vm.$t('noMeetings'))+".")]):_vm._e(),_vm._l((_vm.meetings),function(meeting,m){return [(!meeting._destroy)?_c('div',{key:'meeting_' + m,staticClass:"p-4 border-b border-gray-100 flex justify-end items-center",class:{ 'hover:bg-gray-100': _vm.canEdit }},[_c('div',{staticClass:"flex-1 flex gap-2 items-center"},[_c('div',[_c('fw-icon-calendar',{staticClass:"w-5 h-5"})],1),_c('div',[_c('fw-heading',{attrs:{"size":"h4"}},[_vm._v(_vm._s(_vm._f("formatDayWithTime")(meeting.date))+" "),_c('div',{staticClass:"w-2 h-2 bg-gray-200 mx-1.5 rounded-full inline-block mb-0.5"}),_vm._v(" "+_vm._s(_vm.$t(`meetingsGoals.${meeting.goal}`)))])],1)]),(
                _vm.canEditMeetings &&
                  !(meeting.goal == 'seriation-interview'
                    ? _vm.procedure.interview_started
                    : meeting.goal == 'seriation-curriculum'
                    ? _vm.procedure.admission_started
                    : _vm.procedure.status == 'closed')
              )?_c('div',[_c('b-dropdown',{attrs:{"aria-role":"list","position":"is-bottom-left"}},[_c('fw-button-dropdown',{staticClass:"flex flex-col",attrs:{"slot":"trigger","chevron":false,"type":"xlight","label":"Ações"},slot:"trigger"},[_c('fw-icon-more',{staticClass:"w-5 h-5"})],1),_c('b-dropdown-item',{staticClass:"paddingless",attrs:{"custom":"","aria-role":"menuitem"}},[_c('fw-button',{attrs:{"type":"basic-action","label":"Editar reunião","size":"sm","custom-class":"w-full"},nativeOn:{"click":function($event){return _vm.editMeeting(m)}}},[_vm._v(" "+_vm._s(_vm.$t('edit'))+" ")])],1),_c('b-dropdown-item',{staticClass:"paddingless",attrs:{"custom":"","aria-role":"menuitem"}},[_c('fw-button',{attrs:{"type":"basic-action","size":"sm","custom-class":"w-full","label":"Eliminar reunião"},nativeOn:{"click":function($event){return _vm.deleteMeeting(m)}}},[_vm._v(" "+_vm._s(_vm.$t('delete'))+" ")])],1)],1)],1):_vm._e()]):_vm._e()]})]},proxy:true}],null,false,1646318776)})],1),(_vm.procedure.with_interview && _vm.procedure.curriculum_closed)?_c('CandidatesInterviewInfo',{attrs:{"procedure":_vm.procedure,"people":_vm.candidates,"loading":_vm.loadingData.setCandidatesInterview,"can-edit":_vm.canEditInterviewInfo},on:{"set-candidates-interview":_vm.setCandidatesInterview}}):_vm._e(),_c('fw-panel-info',{staticClass:"my-5",attrs:{"debug":"","label":"Meetings data (raw)"}},[_c('json-viewer',{attrs:{"value":_vm.meetings}})],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }