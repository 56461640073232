<template>
  <b-dropdown
    ref="genericSelector"
    :disabled="disabled"
    scrollable
    max-height="500"
    aria-role="list"
    position="is-bottom-left"
  >
    <fw-button slot="trigger" aria-label="Escolha uma opção" type="dropdown" size="sm" class="flex gap-1 items-center">
      {{ selectedText }}
      <fw-icon-chevron-down class="w-4 h-4" />
    </fw-button>

    <b-dropdown-item
      v-for="(option, o) in options"
      :key="'selector_' + o"
      aria-role="menu-item"
      :focusable="true"
      class="px-4 py-2 font-semibold flex justify-start gap-3 items-center"
      @click.native="clickSelect(o)"
    >
      <div
        class="border-2 border-gray-200 h-4 w-4 rounded-full -mt-0.5"
        :class="{
          'bg-primary bg-opacity-90': option.value === selected,
          'bg-gray-200': option.value !== selected,
        }"
      ></div>
      <div>{{ option.text[language] }}</div>
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
export default {
  name: 'GenericSelector',
  props: {
    options: {
      type: Array,
      required: true,
      default() {
        return []
      },
    },
    selected: {
      type: [String, Number],
    },
    language: {
      type: String,
      default: 'pt',
    },
    disabled: Boolean,
  },
  data() {
    return {
      selectedText: '',
    }
  },
  computed: {
    /*academicYearsSorted() {
      let academicYear = this.academicYears
      return academicYear.sort().reverse()
    },*/
  },
  created() {
    for (let i = 0; i < this.options.length; i++) {
      if (this.options[i].value == this.selected) {
        this.selectedText = this.options[i].text[this.language]
        break
      }
    }
  },
  methods: {
    clickSelect(index) {
      this.selectedText = this.options[index].text[this.language]
      this.$emit('selected', this.options[index].value)
    },
  },
}
</script>

<style scoped>
.fw-option.selected .check {
  background: #30a898
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1 1'%3E%3Cpath style='fill:%23fff' d='M 0.04038059,0.6267767 0.14644661,0.52071068 0.42928932,0.80355339 0.3232233,0.90961941 z M 0.21715729,0.80355339 0.85355339,0.16715729 0.95961941,0.2732233 0.3232233,0.90961941 z'%3E%3C/path%3E%3C/svg%3E")
    no-repeat center center;
  border-color: #30a898;
  background-size: 80%;
}

.fw-option .check {
  border-color: #30a898;
  height: 16px;
  width: 16px;
}
</style>
