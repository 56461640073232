<template>
  <fw-panel v-if="currentViewChecks && procedure" :title="$t('juryPresences')" boxed="xs" custom-class="bg-white">
    <fw-panel-info
      v-if="
        (!procedure.presidents || procedure.presidents.length < 1) && (!procedure.vogals || procedure.vogals.length < 1)
      "
      clean
      centered
      class="p-3"
    >
      {{ $t('undefinedJury') }}.
    </fw-panel-info>
    <div v-else>
      <template v-for="president in procedure.presidents">
        <Person
          :key="president.key"
          :person="president"
          class="border-b border-gray-100"
          :open-details-on-click="canOpenUserDetails"
          :clickable="canOpenUserDetails"
        >
          <template #secondline>
            <div class="flex items-center text-sm text-gray-500 gap-3">
              <div>{{ $t('president') }}</div>
              <div v-if="president.as_president_substitute === true" class="text-xs font-semibold">
                {{ $t('substitute') }}
              </div>
            </div>
          </template>
          <template #options>
            <div @click.stop>
              <TagConflitsOfInterest
                v-if="!president.no_conflict_of_interest"
                :has-no-conflit-of-interest="president.no_conflict_of_interest"
              />
              <OnoffOption
                v-else-if="canEdit && president.no_conflict_of_interest === true"
                :disabled="currentViewChecks.meeting_ended || !canEdit"
                :options="[$t('present'), $t('absent')]"
                :selected-option="
                  currentViewChecks && currentViewChecks.jury
                    ? currentViewChecks.jury.includes(president.jury_key)
                      ? $t('present')
                      : $t('absent')
                    : null
                "
                @input="userPresence(president.jury_key, $event)"
              ></OnoffOption>
              <div v-else-if="currentViewChecks && currentViewChecks.jury">
                <fw-tag
                  v-if="currentViewChecks.jury.includes(president.jury_key)"
                  type="light-primary"
                  class="w-24 flex items-center justify-center"
                >
                  {{ $t('present') }}
                </fw-tag>
                <fw-tag v-else type="light" class="w-24 flex items-center justify-center">
                  {{ $t('absent') }}
                </fw-tag>
              </div>
            </div>
          </template>
        </Person>
      </template>
      <template v-for="vogal in procedure.vogals">
        <Person
          :key="vogal.key"
          :person="vogal"
          class="border-b border-gray-100"
          :open-details-on-click="canOpenUserDetails"
          :clickable="canOpenUserDetails"
        >
          <template #secondline>
            <div class="flex items-center text-sm text-gray-500 gap-3">
              <div>{{ $t('vogal') }}</div>
              <div v-if="vogal.as_president_substitute === true" class="text-xs font-semibold">
                {{ $t('substitute') }}
              </div>
            </div>
          </template>
          <template #options>
            <div @click.stop>
              <TagConflitsOfInterest
                v-if="!vogal.no_conflict_of_interest"
                :has-no-conflit-of-interest="vogal.no_conflict_of_interest"
              />
              <OnoffOption
                v-else-if="canEdit && vogal.no_conflict_of_interest === true"
                :options="[$t('present'), $t('absent')]"
                :disabled="currentViewChecks.meeting_ended || !canEdit"
                :selected-option="
                  currentViewChecks && currentViewChecks.jury && currentViewChecks.jury.includes(vogal.jury_key)
                    ? $t('present')
                    : $t('absent')
                "
                @input="userPresence(vogal.jury_key, $event)"
              ></OnoffOption>
              <div v-else-if="currentViewChecks && currentViewChecks.jury">
                <fw-tag
                  v-if="currentViewChecks.jury.includes(vogal.jury_key)"
                  type="light-primary"
                  class="w-24 flex items-center justify-center"
                >
                  {{ $t('present') }}
                </fw-tag>
                <fw-tag v-else type="light" class="w-24 flex items-center justify-center">
                  {{ $t('absent') }}
                </fw-tag>
              </div>
            </div>
          </template>
        </Person>
      </template>
    </div>
  </fw-panel>
</template>

<script>
import Person from '@/fw-modules/fw-core-vue/ui/components/cards/Person'
import OnoffOption from '@/fw-modules/fw-core-vue/ui/components/form/onoffOption'
import TagConflitsOfInterest from '@/components/users/TagConflitsOfInterest'

export default {
  components: {
    Person,
    OnoffOption,
    TagConflitsOfInterest,
  },
  props: {
    procedure: {
      type: Object,
    },
    currentViewChecks: {
      type: Object,
    },
    canEdit: {
      type: Boolean,
      default: false,
    },
    canOpenMeeting: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    // Roles
    userRoles() {
      return this.$store.getters.userPermissions
    },
    canOpenUserDetails() {
      return this.userRoles.isProcedureManager || this.userRoles.isAdmin || this.userRoles.isManager
    },
  },

  methods: {
    userPresence(key, ev) {
      this.$emit('set-user-presence', key, ev)
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "president": "Presidente",
    "juryPresences": "Presenças do júri",
    "undefinedJury": "Não existem elementos do júri autorizados para participar nesta reunião.",
    "substitute": "Suplente",
    "vogal": "Vogal",
    "present": "Presente",
    "absent": "Ausente",
    "notAcceptedConflitOfInterests": "Existem conflitos de interesse"
  },
  "en": {
    "president": "President",
    "juryPresences": "Jury Presences",
    "undefinedJury": "No jury defined",
    "substitute": "Substitute",
    "vogal": "Vogal",
    "present": "Present",
    "absent": "Absent",
    "notAcceptedConflitOfInterests": "Has conflicts of interest"
  }
}
</i18n>
