var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg-white option-cards",class:{
    'rounded-xl': _vm.size === 'big',
    'rounded-md': _vm.size !== 'big',
    disabled: _vm.disabled,
  }},_vm._l((_vm.options),function(option,o){return _c('button',{key:'option_' + o,staticClass:"fw-option w-full text-left flex group transition duration-150 items-center",class:{
      'rounded-t-xl': o === 0 && _vm.size === 'big',
      'rounded-b-xl': o === _vm.options.length - 1 && _vm.size === 'big',
      'rounded-t-md': o === 0 && _vm.size !== 'big',
      'rounded-b-md': o === _vm.options.length - 1 && _vm.size !== 'big',
      'border-b border-gray-100': o !== _vm.options.length - 1,
      selected: option.value === _vm.selected,
      'px-3 py-2 lg:text-md font-semibold': _vm.size === 'big',
      'px-3 py-2 text-md font-semibold': _vm.size === 'small',
      'opacity-70 hover:opacity-90 hover:text-primary cursor-pointer ': !_vm.disabled,
      'opacity-50 cursor-not-allowed': _vm.disabled,
    },attrs:{"disabled":_vm.disabled},on:{"click":function($event){return _vm.clickSelect(option.value)}}},[_c('div',{staticClass:"check border-2 rounded-2xl mr-3 flex-shrink-0"}),_c('div',{staticClass:"flex-1"},[_vm._v(" "+_vm._s(typeof option['text'] === 'string' ? option['text'] : option['text'][_vm.language])+" ")]),(option['right_text'])?_c('div',{staticClass:"text-gray-500 font-medium"},[_vm._v(_vm._s(option['right_text']))]):_vm._e()])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }