<template>
  <fw-panel v-if="tmpPeople" :title="$t('interviewDates')" boxed="xs" custom-class="bg-white">
    <template v-if="canEdit && editMode && tmpPeople.length > 0" #after-heading>
      <fw-button type="link" class="mr-2" @click.native="activeModal = true">Definir em bloco</fw-button>
    </template>
    <template v-if="canEdit && tmpPeople.length > 0" #toolbar>
      <div v-if="!editMode">
        <fw-button type="link" @click.native="editMode = !editMode">Editar</fw-button>
      </div>
      <div v-else class="flex gap-2 items-center">
        <fw-button type="link-muted" @click.native="editMode = !editMode">Fechar</fw-button>
        <fw-button type="light-primary" @click.native="setCandidatesInterviewAndCloseEdit">Guardar e fechar</fw-button>
        <fw-button type="primary" :loading="loading" @click.native="setCandidatesInterview">Guardar</fw-button>
      </div>
    </template>
    <template v-if="procedure.notifications" #footer>
      <fw-panel-info
        v-if="procedure.notifications['notification-interview']"
        class="text-yellow-700 text-sm"
        clean
        icon
      >
        As últimas notificações de datas para as entrevistas foram enviadas aos candidatos em
        <b>{{ procedure.notifications['notification-interview'] | formatDateTime }}</b
        >.
      </fw-panel-info>
      <fw-panel-info v-else clean icon>
        Ainda não foram enviadas convocatórias das entrevistas.
      </fw-panel-info>
    </template>

    <form v-if="tmpPeople.length > 0" @submit.prevent="setCandidatesInterview" @keyup.enter="setCandidatesInterview">
      <Person
        v-for="(person, index) in tmpPeople"
        :key="person.key"
        :person="person"
        class="border-b border-gray-100"
        :selectable="false"
        :clickable="false"
      >
        <template #options>
          <div class="p-3" @click.stop>
            <div v-if="canEdit && editMode" class="flex gap-3 w-96">
              <div>
                <b-datetimepicker
                  v-model="person.interview_datetime"
                  placeholder="Select a date and time"
                  locale="pt-PT"
                  :min-datetime="minInterviewDate"
                  position="is-top-left"
                  append-to-body
                  :disabled="!canEdit"
                ></b-datetimepicker>
                <fw-tip v-if="$v.tmpPeople.$each[index].interview_datetime.$error" error>
                  <span v-if="!$v.tmpPeople.$each[index].interview_datetime.required">Insira uma data</span>
                  <span v-else>Data inválida</span>
                </fw-tip>
              </div>
              <div>
                <TextInput v-model="person.interview_location" :minlength="3" :maxlength="255" placeholder="Local" />
                <fw-tip v-if="$v.tmpPeople.$each[index].interview_location.$error" error>Insira uma localização</fw-tip>
              </div>
            </div>
            <div v-else class="w-96">
              <div class="flex gap-3 items-center">
                <div
                  class="flex-shrink-0"
                  :class="{ 'text-primary': person.interview_datetime, 'text-gray-400': !person.interview_datetime }"
                >
                  <fw-icon-calendar class="w-5 h-5 flex-shrink-0" />
                </div>
                <div v-if="person.interview_datetime" :class="{ 'font-medium': person.interview_datetime }">
                  {{ person.interview_datetime | formatDateTime }}
                </div>
                <div v-else class="text-gray-500">Data e hora não definida.</div>
              </div>
              <div class="flex gap-3 text-sm">
                <div
                  class="flex-shrink-0"
                  :class="{ 'text-primary': person.interview_location, 'text-gray-400': !person.interview_location }"
                >
                  <fw-icon-home class="w-5 h-5" />
                </div>
                <div v-if="person.interview_location">
                  <v-clamp autoresize :max-lines="2">
                    {{ person.interview_location }}
                  </v-clamp>
                </div>
                <div v-else class="text-gray-400">Local não definido.</div>
              </div>
            </div>
          </div>
        </template>
      </Person>
    </form>

    <fw-panel-info v-else>{{ noContentMessage }}</fw-panel-info>
    <b-modal
      v-if="activeModal"
      :active="activeModal"
      scroll="keep"
      :can-cancel="true"
      trap-focus
      :destroy-on-hide="true"
      aria-role="dialog"
      aria-modal
      :width="700"
      :on-cancel="closeModal"
      :custom-class="'rounded-buefy-modal overflow-visible'"
    >
      <div class="h-full p-5">
        <fw-heading>Definir entrevistas em bloco</fw-heading>
        <form @submit.prevent="setBulkInterviewCalendar(true)" @keyup.enter="setBulkInterviewCalendar(true)">
          <div class="my-5">
            <fw-label>Data da entrevista</fw-label>
            <div>
              <b-datetimepicker
                v-model="bulk.interview_datetime"
                placeholder="Indique dia e hora"
                locale="pt-PT"
                :min-datetime="minInterviewDate"
                position="is-top-left"
              ></b-datetimepicker>
            </div>
          </div>
          <div class="my-3">
            <fw-label>Local para a entrevista</fw-label>
            <div>
              <TextInput
                v-model="bulk.interview_location"
                :minlength="2"
                :maxlength="255"
                placeholder="Indique um local ou URL (máximo 255 caracteres)"
              />
            </div>
          </div>
          <div class="my-3">
            <fw-label>Intervalo entre entrevistas</fw-label>
            <div>
              <NumberInput v-model="bulk.interview_interval" :min="5" :max="60" placeholder="Intervalo em minutos" />
            </div>
          </div>
        </form>
        <fw-panel-info class="my-3" clean>
          Ao submeter este formulário, os campos data/hora e local de todos os candidatos serão substítuidos mas não
          guardados. Para persistir de forma definitiva a calendarização, por favor, carregue em <b>Guardar</b> (após
          sair desta janela).
        </fw-panel-info>
        <div class="flex gap-3 justify-end">
          <fw-button type="link-muted" @click.native="activeModal = false">Cancelar</fw-button>
          <fw-button type="light-primary" :disabled="!canRunBulkHelper" @click.native="setBulkInterviewCalendar(false)"
            >Definir sem guardar</fw-button
          >
          <fw-button type="primary" :disabled="!canRunBulkHelper" @click.native="setBulkInterviewCalendar(true)"
            >Definir e guardar</fw-button
          >
        </div>
      </div>
    </b-modal>
  </fw-panel>
</template>

<script>
import Person from '@/fw-modules/fw-core-vue/ui/components/cards/Person'
import TextInput from '@/fw-modules/fw-core-vue/ui/components/form/TextInput'
import NumberInput from '@/fw-modules/fw-core-vue/ui/components/form/NumberInput'

import { required, maxLength, minLength, minValue } from 'vuelidate/lib/validators'
import Dates from '@/fw-modules/fw-core-vue/utilities/dates'

export default {
  name: 'CandidatesInterviewInfo',
  components: {
    Person,
    TextInput,
    NumberInput,
  },
  props: {
    people: {
      type: Array,
      required: true,
    },

    procedure: {
      type: Object,
    },

    noContentMessage: {
      type: String,
      default: 'Sem conteúdo',
    },

    canEdit: {
      type: Boolean,
      default: false,
    },

    downloading: {
      type: Boolean,
      default: false,
    },

    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      minInterviewDate: this.setMinDatetime(),
      activeModal: false,
      bulk: {
        interview_datetime: null,
        interview_location: null,
        interview_interval: 20,
      },
      editMode: false,
      tmpPeople: [],
      selectMode: 'position',
      selectModeIndex: 0,
      selectionTypes: [
        {
          text: 'Hora',
          value: 'hour',
          input: 'datetime-local',
          message: 'Marcar data de entrevista para:',
        },
        {
          text: 'Local',
          value: 'place',
          input: 'text',
          message: 'Marcar local de entrevista para:',
        },
        {
          text: 'Intervalo de minutos',
          value: 'interval',
          input: 'number',
          message: 'Marcar data de entrevista com intervalo de minutos:',
        },
      ],
    }
  },

  computed: {
    canRunBulkHelper() {
      return Boolean(this.bulk.interview_datetime && this.bulk.interview_location && this.bulk.interview_interval)
    },
  },

  validations() {
    return {
      tmpPeople: {
        $each: {
          interview_location: { required, min: minLength(2), max: maxLength(250) },
          interview_datetime: { required, min: minValue(this.minInterviewDate) },
        },
      },
    }
  },

  watch: {
    people(newVal) {
      this.tmpPeople = newVal.map(p => ({
        ...p,
        interview_datetime: p.interview_datetime ? new Date(p.interview_datetime) : null,
      }))
    },
  },

  created() {
    this.tmpPeople = this.people.map(p => ({
      ...p,
      interview_datetime: p.interview_datetime ? new Date(p.interview_datetime) : null,
    }))
  },

  methods: {
    closeModal() {
      this.activeModal = false
    },

    setBulkInterviewCalendar(saveAfter = false) {
      let datetime = Dates.buildCore(this.bulk.interview_datetime)
      let location = this.bulk.interview_location
      let interval = this.bulk.interview_interval

      for (let index = 0; index < this.tmpPeople.length; index++) {
        this.tmpPeople[index].interview_datetime = datetime.toDate()
        this.tmpPeople[index].interview_location = location
        datetime = datetime.add(interval, 'minutes')
      }

      if (saveAfter) {
        this.setCandidatesInterview()
      }

      this.activeModal = false
    },

    setMinDatetime() {
      const date = new Date()
      date.setHours(new Date().getHours() + 1)
      return date
    },

    userPresence(candidateKey, rawValue) {
      this.$emit('set-user-presence', candidateKey, rawValue)
    },

    setCandidatesInterview() {
      if (!this.canEdit) return
      this.$v.$touch()
      console.log('set candidate :>> ', this.tmpPeople)
      if (!this.$v.$invalid) {
        this.$emit('set-candidates-interview', this.tmpPeople)
      }
    },

    setCandidatesInterviewAndCloseEdit() {
      this.setCandidatesInterview()
      if (!this.$v.$invalid) {
        this.editMode = false
      }
    },

    openApplication(candidateKey) {
      this.$emit('open-application', candidateKey)
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "interviewDates": "Datas de entrevistas"
  },
  "en": {
    "interviewDates": "Interview dates"
  }
}
</i18n>
