<template>
  <div v-if="metadata" class="relative">
    <fw-panel :title="$t('metadata')" featured :loading="savingData" after-loading-checked>
      <template #toolbar>
        <div class="flex gap-1 items-center">
          <div v-if="editMode && $v.$error" class="p-1 mx-5 text-red-500 flex items-center gap-1 text-sm font-medium">
            <fw-icon-error-warning class="w-6 h-6"></fw-icon-error-warning>
            <span class="hidden lg:block">{{ $t('thereAreErrors') }}</span>
          </div>
          <div v-if="canEdit" class="flex gap-5">
            <fw-button
              :type="!editMode ? 'link' : 'link-light'"
              :disabled="savingData"
              :loading="savingData"
              @click.native="toggleEditMode()"
            >
              {{ editMode ? $t('close') : $t('edit') }}
            </fw-button>
            <fw-button
              v-if="editMode"
              type="primary"
              :disabled="savingData"
              :loading="savingData"
              @click.native="saveProc()"
              >{{ $t('save') }}</fw-button
            >
          </div>
        </div>
      </template>
    </fw-panel>

    <div>
      <fw-panel :title="$t('generalDetails')" boxed="lg" class="my-5" custom-class="bg-white flex flex-col gap-3">
        <div>
          <fw-label>{{ $t('procedureCode') }}</fw-label>
          <fw-heading size="h2">{{ metadata.prefix + metadata.code }}</fw-heading>
          <div v-if="!isProcedureTypeScholarship" class="w-1/2">
            <fw-label>{{ $t('date.label') }}</fw-label>
            <b-datepicker
              v-if="editMode"
              v-model="$v.metadata.date.$model"
              :placeholder="$t('date.placeholder')"
              :class="{
                error: $v.date.$error,
              }"
              locale="pt-PT"
              :disabled="!canEdit || !editMode"
              icon="calendar-alt"
              trap-focus
            >
            </b-datepicker>
            <div v-else>
              <div v-if="metadata.date">
                {{ metadata.date }}
              </div>
              <fw-panel-info v-else clean>{{ metadata.date || $t('notDefined') }}</fw-panel-info>
            </div>
          </div>
        </div>

        <div
          :class="{
            'grid grid-cols-2 md:grid-cols-3 gap-3': !editMode,
            'flex flex-col gap-3 my-3': editMode,
          }"
        >
          <div v-if="isProcedureTypeScholarship">
            <fw-label>{{ $t('pepCode') }}</fw-label>
            <TextInput
              v-if="editMode"
              v-model.trim="$v.metadata.pep_code.$model"
              :disabled="!canEdit || !editMode"
              :minlength="2"
              :max="250"
              :help="$t('charsLimit', { limit: '250' })"
              :class="{ error: $v.metadata.pep_code.$error }"
            >
            </TextInput>
            <div v-else>
              <div v-if="metadata.pep_code">
                {{ metadata.pep_code }}
              </div>
              <fw-panel-info v-else clean>{{ metadata.pep_code || $t('notDefined') }}</fw-panel-info>
            </div>

            <fw-tip v-if="$v.metadata.pep_code.$error" error>
              <span v-if="!$v.metadata.pep_code.required">{{
                $tc('errors.required', 1, { field: $t('pepCode') })
              }}</span>
              <span v-else>{{ $t('errors.invalid', { field: $t('pepCode') }) }}</span>
            </fw-tip>
          </div>

          <div v-if="isProcedureTypeScholarship">
            <fw-label>LUGUS</fw-label>
            <TextInput
              v-if="editMode"
              v-model="$v.metadata.lugus.$model"
              :disabled="!canEdit || !editMode"
              :minlength="2"
              :maxlength="250"
              :help="$t('charsLimit', { limit: '250' })"
              :class="{
                error: $v.metadata.lugus.$error,
              }"
            >
            </TextInput>
            <div v-else>
              <div v-if="metadata.lugus">
                {{ metadata.lugus }}
              </div>
              <fw-panel-info v-else clean>{{ metadata.lugus || $t('notDefined') }}</fw-panel-info>
            </div>

            <fw-tip v-if="$v.metadata.lugus.$error" error>
              <span v-if="!$v.metadata.lugus.required">{{ $tc('errors.required', 1, { field: 'LUGUS' }) }}</span>
              <span v-else>{{ $t('errors.invalid', { field: 'LUGUS' }) }}</span>
            </fw-tip>
          </div>

          <div>
            <fw-label>{{ $t('seatsNumber') }}</fw-label>
            <NumberInput
              v-if="editMode"
              v-model="$v.metadata.number_of_seats.$model"
              :disabled="!canEdit || !editMode"
              :class="{
                error: $v.metadata.number_of_seats.$error,
              }"
            >
              <template #suffix>{{ isProcedureTypeScholarship ? $t('scholarships') : $t('seats') }}</template>
            </NumberInput>
            <div v-else>
              <div v-if="metadata.number_of_seats">
                {{ metadata.number_of_seats }}
              </div>
              <fw-panel-info v-else clean>{{ metadata.number_of_seats || $t('notDefined') }}</fw-panel-info>
            </div>

            <fw-tip v-if="$v.metadata.number_of_seats.$error" error>
              <span v-if="!$v.metadata.number_of_seats.required">
                {{
                  $tc('errors.required', 1, {
                    field: isProcedureTypeScholarship
                      ? $t('scholarshipsNumber').toLowerCase()
                      : $t('seatsNumber').toLowerCase(),
                  })
                }}</span
              >
              <span v-else>{{ $t('errors.invalid', { field: $t('number') }) }}</span>
            </fw-tip>
          </div>

          <div v-if="isProcedureTypeScholarship">
            <fw-label>{{ $t('monthlyValue.label') }}</fw-label>
            <TextInput
              v-if="editMode"
              :disabled="!canEdit || !editMode"
              :class="{
                error: $v.metadata.monthly_schoolarship_value.$error,
              }"
            >
              <template #main>
                <b-autocomplete
                  v-model="$v.metadata.monthly_schoolarship_value.$model"
                  class="input-r rounded-none rounded-l-md w-full"
                  :data="filteredScholarshipAmounts"
                  :disabled="!canEdit || !editMode"
                  :open-on-focus="true"
                  :class="{
                    error: $v.metadata.monthly_schoolarship_value.$error,
                  }"
                  :placeholder="$t('monthlyValue.placeholder')"
                >
                  <template #empty>{{ $t('noSugestions') }}</template>
                </b-autocomplete>
              </template>
              <template #suffix>€/{{ $tc('month') }}</template>
            </TextInput>
            <div v-else>
              <div v-if="metadata.monthly_schoolarship_value">
                {{ metadata.monthly_schoolarship_value }} €/{{ $tc('month') }}
              </div>
              <fw-panel-info v-else clean>{{ metadata.monthly_schoolarship_value || $t('notDefined') }}</fw-panel-info>
            </div>

            <fw-tip v-if="$v.metadata.monthly_schoolarship_value.$error" error>
              <span v-if="!$v.metadata.monthly_schoolarship_value.required">{{
                $tc('errors.required', 1, { field: $t('monthlyValue.label').toLowerCase() })
              }}</span>
              <span v-else>{{ $t('errors.invalid', { field: $t('number').toLowerCase() }) }}</span>
            </fw-tip>
          </div>

          <div v-if="isProcedureTypeScholarship">
            <fw-label>{{ $t('duration') }}</fw-label>
            <div v-if="!editMode">
              <div v-if="metadata.duration">{{ metadata.duration }} {{ $tc('month', metadata.duration) }}</div>
              <fw-panel-info v-else clean>{{ $t('notDefined') }}</fw-panel-info>
            </div>
            <NumberInput
              v-else
              v-model="$v.metadata.duration.$model"
              :disabled="!canEdit || !editMode"
              :class="{
                error: $v.metadata.duration.$error,
              }"
            >
              <template #suffix>{{ $tc('month', 2) }}</template>
            </NumberInput>

            <fw-tip v-if="$v.metadata.duration.$error" error>
              <span v-if="!$v.metadata.duration.required">
                {{ $tc('errors.requiredF', 1, { field: $t('duration').toLowerCase() }) }}
              </span>
              <span v-else>{{ $t('errors.invalidF', { field: $t('duration').toLowerCase() }) }}</span>
            </fw-tip>
          </div>

          <div v-if="isProcedureTypeScholarship" class="mt-2">
            <div v-if="!editMode">
              <fw-label>{{ $t('possiblyRenewable') }}</fw-label>
              <div>{{ !!metadata.is_renewable ? $t('yes') : $t('no') }}</div>
            </div>

            <b-switch
              v-else
              v-model="metadata.is_renewable"
              :disabled="!canEdit || !editMode"
              class="text-sm text-gray-500"
              >{{ $t('possiblyRenewable') }}</b-switch
            >
          </div>
        </div>

        <div>
          <div v-if="!editMode">
            <fw-label>{{ $t('withTuitionPayment') }}</fw-label>
            <div>{{ !!metadata.tuition_payment ? $t('yes') : $t('no') }}</div>
          </div>

          <b-switch
            v-else
            v-model="metadata.tuition_payment"
            :disabled="!canEdit || !editMode"
            class="text-sm text-gray-500"
            @input="$v.metadata.tuition_conditions.$touch()"
            >{{ $t('withTuitionPayment') }}</b-switch
          >
        </div>

        <div v-if="isProcedureTypeScholarship && metadata.tuition_payment">
          <fw-label>{{ $t('valueAndConditions.label') }}</fw-label>
          <div v-if="!editMode" class="flex flex-col gap-3">
            <div v-for="lang in langs" :key="lang" class="flex gap-3">
              <div>
                <fw-tag class="uppercase">{{ lang }}</fw-tag>
              </div>
              <span v-if="metadata.tuition_conditions && metadata.tuition_conditions[lang]">{{
                metadata.tuition_conditions[lang]
              }}</span>
              <fw-panel-info v-else clean>{{ $t('notDefined') }}</fw-panel-info>
            </div>
          </div>

          <TranslatedInput
            v-else
            v-model="$v.metadata.tuition_conditions.$model"
            :disable-autowrite="disableAutowrite"
            :disabled="!canEdit || !editMode"
            :multiline="true"
            :rows="3"
            :class="{
              error: $v.metadata.tuition_conditions.$error,
            }"
            :placeholder="{
              pt: $t('valueAndConditions.placeholder.pt'),
              en: $t('valueAndConditions.placeholder.en'),
            }"
          />

          <fw-tip v-if="$v.metadata.tuition_conditions.$error" error>
            {{ $tc('errors.requiredF', 2, { field: $t('valueAndConditions.placeholder.ptAndEn') }) }}
          </fw-tip>
        </div>

        <div v-if="isProcedureTypeScholarship">
          <fw-label>{{ $t('candidateProfile.label') }}</fw-label>
          <div v-if="!editMode" class="flex flex-col gap-3">
            <div v-for="lang in langs" :key="lang" class="flex gap-3">
              <div>
                <fw-tag class="uppercase">{{ lang }}</fw-tag>
              </div>
              <span v-if="metadata.candidate_profile && metadata.candidate_profile[lang]">{{
                metadata.candidate_profile[lang]
              }}</span>
              <fw-panel-info v-else clean>{{ $t('notDefined') }}</fw-panel-info>
            </div>
          </div>

          <TranslatedInput
            v-else
            v-model="$v.metadata.candidate_profile.$model"
            :disable-autowrite="disableAutowrite"
            :disabled="!canEdit || !editMode"
            :multiline="true"
            :rows="3"
            :class="{
              error: $v.metadata.candidate_profile.$error,
            }"
            :placeholder="{
              pt: $t('candidateProfile.placeholder.pt'),
              en: $t('candidateProfile.placeholder.en'),
            }"
          />

          <fw-tip v-if="$v.metadata.candidate_profile.$error" error>
            {{ $tc('errors.required', 1, { field: $t('candidateProfile.placeholder.ptAndEn') }) }}
          </fw-tip>
        </div>

        <div v-if="isProcedureTypeScholarship">
          <fw-label>{{ $t('docsToSubmit.label') }}</fw-label>
          <div v-if="!editMode" class="flex flex-col gap-3">
            <div v-for="lang in langs" :key="lang" class="flex gap-3">
              <div>
                <fw-tag class="uppercase">{{ lang }}</fw-tag>
              </div>
              <span v-if="metadata.docs_to_submit && metadata.docs_to_submit[lang]">{{
                metadata.docs_to_submit[lang]
              }}</span>
              <fw-panel-info v-else clean>{{ $t('notDefined') }}</fw-panel-info>
            </div>
          </div>

          <TranslatedInput
            v-else
            v-model="$v.metadata.docs_to_submit.$model"
            :disable-autowrite="disableAutowrite"
            :disabled="!canEdit || !editMode"
            :class="{
              error: $v.metadata.docs_to_submit.$error,
            }"
            :rows="3"
            :multiline="true"
            :placeholder="{
              pt: $t('docsToSubmit.placeholder.pt'),
              en: $t('docsToSubmit.placeholder.en'),
            }"
          />

          <fw-tip v-if="$v.metadata.docs_to_submit.$error" error>
            {{ $tc('errors.required', 2, { field: $t('docsToSubmit.placeholder.ptAndEn') }) }}
          </fw-tip>
        </div>

        <div v-if="!isProcedureTypeScholarship">
          <fw-label>Identificação do edital</fw-label>
          <div v-if="!editMode">{{ metadata.public_notice_id || $t('notDefined') }}</div>
          <b-input
            v-else
            v-model="$v.metadata.public_notice_id.$model"
            maxlength="200"
            type="textarea"
            :class="{
              error: $v.metadata.public_notice_id.$error,
            }"
            :disabled="!canEdit || !editMode"
          ></b-input>

          <fw-tip v-if="$v.metadata.public_notice_id.$error" error>
            Insira a identificação do edital.
          </fw-tip>
        </div>

        <div v-if="!isProcedureTypeScholarship" class="flex gap-5">
          <div class="w-1/2">
            <fw-label>Categoria</fw-label>
            <div v-if="!editMode">{{ metadata.category_type || $t('notDefined') }}</div>
            <b-field v-else size="is-medium">
              <b-select
                v-model="metadata.category_type"
                :disabled="!canEdit || !editMode"
                placeholder="Escolha uma categoria"
                expanded
              >
                <option v-for="(name, value, index) in categories" :key="index" :value="value">
                  {{ name }}
                </option>
              </b-select>
            </b-field>
          </div>
          <div class="w-1/2">
            <fw-label>Categoria Profissional</fw-label>
            <div v-if="!editMode">{{ metadata.professional_category || $t('notDefined') }}</div>
            <b-field v-else size="is-medium">
              <b-select
                v-model="metadata.professional_category"
                :disabled="!canEdit || !editMode"
                placeholder="Escolha uma categoria profissional"
                expanded
              >
                <option v-for="(name, value, index) in professionalCategories" :key="index" :value="value">
                  {{ name }}
                </option>
              </b-select>
            </b-field>
          </div>
        </div>

        <div v-if="metadata.location || isProcedureTypeScholarship">
          <fw-label>{{ isProcedureTypeScholarship ? $t('scholarshipLocation') : $t('location') }}</fw-label>
          <fw-panel-info v-if="!editMode" clean>{{ metadata.location || $t('notDefined') }}</fw-panel-info>
          <b-autocomplete
            v-else
            v-model="$v.metadata.location.$model"
            :disabled="!canEdit || !editMode"
            :data="locations"
            :open-on-focus="true"
            :placeholder="$t('facultyDepartment')"
            :class="{
              error: $v.metadata.location.$error,
            }"
          >
            <template #empty>{{ $t('noResults') }}</template>
          </b-autocomplete>

          <fw-tip v-if="$v.metadata.location.$error" error>
            {{ $tc('errors.requiredF', 1, { field: $t('location').toLowerCase() }) }}
          </fw-tip>
        </div>

        <div v-if="isProcedureTypeScholarship">
          <fw-label>{{ $t('claimant') }}</fw-label>
          <TextInput
            v-if="editMode"
            v-model="$v.metadata.send_to_email.$model"
            :disabled="!canEdit || !editMode"
            :minlength="2"
            :maxlength="250"
            :help="$t('claimantInstructions')"
            :class="{
              error: $v.metadata.send_to_email.$error,
            }"
            type="email"
          >
          </TextInput>
          <div v-else>
            <div v-if="metadata.send_to_email">
              <div class="flex gap-2 items-center">
                <span>{{ metadata.send_to_email }}</span>
                <span type="primary" size="sm" class="flex items-center">
                  <fw-icon-message-sent class="w-5 h-5 text-primary" />
                  <fw-icon-check class="w-5 h-5 text-primary" />
                </span>
              </div>
              <div class="text-xs text-gray-500">{{ $t('claimantInstructions') }}</div>
            </div>
            <fw-panel-info v-else clean>{{ metadata.send_to_email || $t('notDefined') }}</fw-panel-info>
          </div>

          <fw-tip v-if="$v.metadata.send_to_email.$error" error>
            {{ $t('errors.invalid', { field: 'e-mail' }) }}
          </fw-tip>
        </div>
      </fw-panel>

      <fw-panel
        v-if="!isProcedureTypeScholarship"
        :title="'Unidade orgânica e enquadramento'"
        boxed
        custom-class="bg-white mb-7 shadow-md rounded-md"
      >
        <fw-label>Faculdade / Departamento</fw-label>
        <div v-if="!editMode">{{ metadata.department || $t('notDefined') }}</div>
        <b-autocomplete
          v-else
          v-model="$v.metadata.department.$model"
          :disabled="!canEdit || !editMode"
          :class="{
            error: $v.metadata.department.$error,
          }"
          class="mb-2"
          :data="filteredDepartments"
          :open-on-focus="true"
          placeholder="Faculdade / Departamento"
        >
          <template #empty>{{ $t('noResults') }}</template>
        </b-autocomplete>

        <div class="flex mb-5 gap-5">
          <div class="w-1/2">
            <fw-label>Área</fw-label>
            <div v-if="!editMode">{{ metadata.area || $t('notDefined') }}</div>
            <b-input
              v-else
              v-model="$v.metadata.area.$model"
              :disabled="!canEdit || !editMode"
              :class="{
                error: $v.metadata.area.$error,
              }"
              type="text"
              placeholder="Área"
            ></b-input>
          </div>
          <div class="w-1/2">
            <fw-label>Sub área</fw-label>
            <div v-if="!editMode">{{ metadata.sub_area }}</div>
            <b-input
              v-else
              v-model="$v.metadata.sub_area.$model"
              :disabled="!canEdit || !editMode"
              :class="{
                error: $v.metadata.sub_area.$error,
              }"
              type="text"
              placeholder="Sub área"
            ></b-input>
          </div>
        </div>
      </fw-panel>

      <fw-panel
        v-if="isProcedureTypeScholarship"
        :title="$t('projectDetails')"
        boxed="lg"
        custom-class="bg-white flex flex-col gap-3"
      >
        <div>
          <fw-label>{{ $t('projectReference') }}</fw-label>
          <TextInput
            v-if="editMode"
            v-model="$v.metadata.scholarship_project.reference.$model"
            :disabled="!canEdit || !editMode"
            :help="$t('charsLimit', { limit: '250' })"
            :minlength="3"
            :maxlength="250"
            :placeholder="$t('projectReference')"
            :class="{
              error: $v.metadata.scholarship_project.reference.$error,
            }"
          />
          <div v-else>
            <div v-if="metadata.scholarship_project.reference" class="text-lg font-semibold">
              {{ metadata.scholarship_project.reference }}
            </div>
            <fw-panel-info v-else clean>{{ metadata.scholarship_project.reference || $t('notDefined') }}</fw-panel-info>
          </div>
          <fw-tip v-if="$v.metadata.scholarship_project.reference.$error" error>
            {{ $tc('errors.requiredF', 1, { field: $t('projectReference').toLowerCase() }) }}
          </fw-tip>
        </div>

        <div>
          <fw-label>{{ $t('projectName.label') }}</fw-label>
          <div v-if="!editMode" class="flex flex-col gap-3">
            <div v-for="lang in langs" :key="lang" class="flex gap-3">
              <div>
                <fw-tag class="uppercase">{{ lang }}</fw-tag>
              </div>
              <span v-if="metadata.scholarship_project.name && metadata.scholarship_project.name[lang]">{{
                metadata.scholarship_project.name[lang]
              }}</span>
              <fw-panel-info v-else clean>{{ $t('notDefined') }}</fw-panel-info>
            </div>
          </div>

          <TranslatedInput
            v-else
            v-model="$v.metadata.scholarship_project.name.$model"
            :disable-autowrite="disableAutowrite"
            :disabled="!canEdit || !editMode"
            :maxlength="250"
            :rows="2"
            :help="$t('charsLimit', { limit: '250' })"
            :placeholder="{
              pt: $t('projectName.placeholder.pt'),
              en: $t('projectName.placeholder.en'),
            }"
            :class="{
              error: $v.metadata.scholarship_project.name.$error,
            }"
          />
          <fw-tip v-if="$v.metadata.scholarship_project.name.$error" error>
            {{ $tc('errors.required', 1, { field: $t('projectName.placeholder.ptAndEn') }) }}
          </fw-tip>
        </div>

        <div :key="'finance_source_' + metadata.finance_source.value" class="relative">
          <div class="flex items-end">
            <fw-label class="flex-1">{{ $t('finance') }}</fw-label>
            <GenericSelector
              v-if="editMode && metadata.finance_source.value && financeSources.length"
              :options.sync="financeSources"
              :selected="metadata.finance_source.value"
              :disabled="!canEdit || !editMode"
              @selected="selectFinanceSource"
            ></GenericSelector>
          </div>
          <div v-if="!editMode" class="flex flex-col gap-3">
            <div v-for="lang in langs" :key="lang" class="flex gap-3">
              <div>
                <fw-tag class="uppercase">{{ lang }}</fw-tag>
              </div>
              <span v-if="metadata.finance_source.text && metadata.finance_source.text[lang]">{{
                metadata.finance_source.text[lang]
              }}</span>
              <fw-panel-info v-else clean>{{ $t('notDefined') }}</fw-panel-info>
            </div>
          </div>

          <TranslatedInput
            v-else-if="metadata.finance_source.value"
            v-model="$v.metadata.finance_source.text.$model"
            :disable-autowrite="disableAutowrite"
            :class="{
              error: $v.metadata.finance_source.$error,
            }"
            :placeholder="{
              pt: $t('financeSource.placeholder.pt'),
              en: $t('financeSource.placeholder.en'),
            }"
            :disabled="!canEdit || !editMode"
            :multiline="true"
          />
          <OptionsCard
            v-else
            size="small"
            :class="{
              error: $v.metadata.finance_source.$error,
            }"
            :options="financeSources"
            :selected="metadata.finance_source.value"
            :disabled="!canEdit || !editMode"
            @selected="selectFinanceSource"
          ></OptionsCard>

          <fw-tip v-if="$v.metadata.finance_source.$error" error>
            {{ $tc('errors.required', 1, { field: $t('financeSource.placeholder.ptAndEn') }) }}
          </fw-tip>
        </div>

        <div>
          <fw-label>{{ $t('scientificArea.label') }}</fw-label>
          <div v-if="!editMode" class="flex flex-col gap-3">
            <div v-for="lang in langs" :key="lang" class="flex gap-3">
              <div>
                <fw-tag class="uppercase">{{ lang }}</fw-tag>
              </div>
              <span
                v-if="
                  metadata.scholarship_project.scientific_area && metadata.scholarship_project.scientific_area[lang]
                "
                >{{ metadata.scholarship_project.scientific_area[lang] }}</span
              >
              <fw-panel-info v-else clean>{{ $t('notDefined') }}</fw-panel-info>
            </div>
          </div>

          <TranslatedInput
            v-else
            v-model="$v.metadata.scholarship_project.scientific_area.$model"
            :disable-autowrite="disableAutowrite"
            :disabled="!canEdit || !editMode"
            :multiline="true"
            :rows="2"
            :class="{
              error: $v.metadata.scholarship_project.scientific_area.$error,
            }"
            :placeholder="{
              pt: $t('scientificArea.placeholder.pt'),
              en: $t('scientificArea.placeholder.en'),
            }"
          />
          <fw-tip v-if="$v.metadata.scholarship_project.scientific_area.$error" error>
            {{ $tc('errors.requiredF', 1, { field: $t('scientificArea.placeholder.ptAndEn') }) }}
          </fw-tip>
        </div>

        <div>
          <fw-label>{{ $t('advisors.label') }}</fw-label>
          <fw-panel-info v-if="!editMode && !metadata.scholarship_project.advisors.length" clean>
            {{ $t('advisors.notDefined') }}
          </fw-panel-info>
          <ExpandList
            :add-title="$t('advisors.add')"
            :class="{
              error: $v.metadata.scholarship_project.advisors.$error,
            }"
            :show-add-items="canEdit && editMode"
            :empty="!metadata.scholarship_project.advisors || metadata.scholarship_project.advisors.length < 1"
            @add-element="choosePeople('advisors')"
          >
            <template #list>
              <Person
                v-for="person in metadata.scholarship_project.advisors"
                :key="person.key"
                class="border-b border-gray-100"
                no-style
                :person="person"
                :selectable="false"
                :clickable="false"
              >
                <template v-if="canEdit && editMode" #options>
                  <fw-button type="border-danger" @click.native="deleteAdvisor(person.key)">
                    {{ $t('delete') }}
                  </fw-button>
                </template>
              </Person>
            </template>
          </ExpandList>

          <fw-tip v-if="$v.metadata.scholarship_project.advisors.$error" error>
            {{ $t('errors.requiredAtLeast', { number: $t('one'), field: $t('advisor').toLowerCase() }) }}
          </fw-tip>
        </div>

        <div>
          <fw-label>{{ $t('requirements.label') }}</fw-label>
          <div v-if="!editMode" class="flex flex-col gap-3">
            <div v-for="lang in langs" :key="lang" class="flex gap-3">
              <div>
                <fw-tag class="uppercase">{{ lang }}</fw-tag>
              </div>
              <span
                v-if="metadata.scholarship_project.requirements && metadata.scholarship_project.requirements[lang]"
                >{{ metadata.scholarship_project.requirements[lang] }}</span
              >
              <fw-panel-info v-else clean>{{ $t('notDefined') }}</fw-panel-info>
            </div>
          </div>

          <TranslatedInput
            v-else
            v-model="$v.metadata.scholarship_project.requirements.$model"
            :disable-autowrite="disableAutowrite"
            :disabled="!canEdit || !editMode"
            :class="{
              error: $v.metadata.scholarship_project.requirements.$error,
            }"
            :placeholder="{
              pt: $t('requirements.placeholder.pt'),
              en: $t('requirements.placeholder.en'),
            }"
            :multiline="true"
          />
          <fw-tip v-if="$v.metadata.scholarship_project.requirements.$error" error>
            {{ $tc('errors.required', 2, { field: $t('requirements.placeholder.ptAndEn') }) }}
          </fw-tip>
        </div>

        <div>
          <fw-label>{{ $t('goals.label') }}</fw-label>
          <div v-if="!editMode" class="flex flex-col gap-3">
            <div v-for="lang in langs" :key="lang" class="flex gap-3">
              <div>
                <fw-tag class="uppercase">{{ lang }}</fw-tag>
              </div>
              <span v-if="metadata.scholarship_project.goals && metadata.scholarship_project.goals[lang]">{{
                metadata.scholarship_project.goals[lang]
              }}</span>
              <fw-panel-info v-else clean>{{ $t('notDefined') }}</fw-panel-info>
            </div>
          </div>

          <TranslatedInput
            v-else
            v-model="$v.metadata.scholarship_project.goals.$model"
            :disable-autowrite="disableAutowrite"
            :disabled="!canEdit || !editMode"
            :class="{
              error: $v.metadata.scholarship_project.goals.$error,
            }"
            :multiline="true"
            :placeholder="{
              pt: $t('goals.placeholder.pt'),
              en: $t('goals.placeholder.en'),
            }"
          />

          <fw-tip v-if="$v.metadata.scholarship_project.goals.$error" error>
            {{ $tc('errors.required', 2, { field: $t('goals.placeholder.ptAndEn') }) }}
          </fw-tip>
        </div>
      </fw-panel>
    </div>

    <b-modal
      :active="!!choosingPeopleType"
      scroll="keep"
      :can-cancel="true"
      trap-focus
      :destroy-on-hide="true"
      aria-role="dialog"
      aria-modal
      :width="700"
      :on-cancel="closeModal"
      :custom-class="'rounded-buefy-modal'"
    >
      <ChoosePeopleModal
        v-if="choosingPeopleType"
        :title="$t('advisors.choose')"
        :instructions="$t('advisors.select')"
        :multiselect="true"
        :selected="currentSelectedPeople"
        :invite-people="canInvitePeople"
        :endpoint="endpointSearch"
        @save-invite="invitePerson"
        @selected="selectedPeople"
        @close="closeModal"
      ></ChoosePeopleModal>
    </b-modal>
  </div>
</template>

<script>
import ExpandList from '@/fw-modules/fw-core-vue/ui/components/lists/ExpandList'
import Person from '@/fw-modules/fw-core-vue/ui/components/cards/Person'
import TextInput from '@/fw-modules/fw-core-vue/ui/components/form/TextInput'
import NumberInput from '@/fw-modules/fw-core-vue/ui/components/form/NumberInput'
import TranslatedInput from '@/fw-modules/fw-core-vue/ui/components/form/TranslatedInput'
import OptionsCard from '@/fw-modules/fw-core-vue/ui/components/form/OptionsCard'
import GenericSelector from '@/fw-modules/fw-core-vue/ui/components/buttons/GenericSelector'
import ChoosePeopleModal from '@/fw-modules/fw-core-vue/ui/components/modals/ChoosePeopleModal'
import { required, maxLength, minLength, decimal, minValue, requiredIf, helpers, email } from 'vuelidate/lib/validators'

const financingTemplates = {
  oe: {
    pt: 'Financiado pela Fundação para a Ciência e a Tecnologia, I.P./MCTES através de fundos nacionais (PIDDAC)',
    en: 'Funded by the Foundation for Science and Technology, I.P./MCTES through national funds (PIDDAC)',
  },
  feder: {
    pt:
      'Financiado pelo Fundo Europeu de Desenvolvimento Regional (FEDER) da União Europeia, através (indicar qual o Programa Operacional que financia)',
    en:
      'Funded by the European Regional Development Fund (ERDF) of the European Union, through (indicate which Operational Program you finance)',
  },
  own_funds: {
    pt: 'Financiado com receita própria',
    en: 'Financed with own funds',
  },
  other: {
    pt: '',
    en: '',
  },
}

const minIf = helpers.withParams({ type: 'minIf' }, value => {
  return !helpers.req(value) || value.length > 2
})

export default {
  components: {
    ExpandList,
    Person,
    TextInput,
    NumberInput,
    TranslatedInput,
    OptionsCard,
    GenericSelector,
    ChoosePeopleModal,
  },

  props: {
    procedure: {
      type: Object,
      default: () => {
        return {}
      },
    },

    isValid: {
      type: Boolean,
    },

    canInvitePeople: {
      type: Boolean,
      default: false,
    },

    professionalCategories: {
      type: Array,
      default: () => {
        return []
      },
    },

    facultiesAndDepartments: {
      type: Array,
      default: () => {
        return []
      },
    },

    financeSources: {
      type: Array,
      default: () => {
        return []
      },
    },

    canEdit: {
      type: Boolean,
      default: true,
    },

    savingData: {
      type: Boolean,
      default: false,
    },

    disableAutowrite: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      editMode: this.canEdit && this.procedure.status === 'draft',

      // List of amounts as requested by the client (2023-06-14)
      amountOptions: ['2612.03', '1801.00', '2068.65', '1752.52', '1259.64', '990.98', '601.12'],
      metadata: null,
      choosingPeopleType: null,
      currentSelectedPeople: [],
    }
  },

  // TODO: Use dynamic-validation-schema (https://vuelidate.js.org/#sub-dynamic-validation-schema)
  // for validation of diferent types of procedures
  validations: {
    metadata: {
      pep_code: { required, min: minLength(2), max: maxLength(250) },
      lugus: { required, min: minLength(2), max: maxLength(250) },
      number_of_seats: { required, decimal, min: minValue(1) },
      monthly_schoolarship_value: { required, decimal, min: minValue(1) },
      duration: { required, decimal, min: minValue(1) },
      send_to_email: { email },
      tuition_conditions: {
        pt: {
          required: requiredIf(function() {
            return this.isRequiredTuition
          }),
          minIf,
        },
        en: {
          required: requiredIf(function() {
            return this.isRequiredTuition
          }),
          minIf,
        },
      },
      candidate_profile: {
        pt: { required, min: minLength(2) },
        en: { required, min: minLength(2) },
      },
      docs_to_submit: {
        pt: { required, min: minLength(2) },
        en: { required, min: minLength(2) },
      },
      location: { required, min: minLength(2), max: maxLength(250) },
      finance_source: {
        text: {
          pt: { required, min: minLength(2) },
          en: { required, min: minLength(2) },
        },
        value: { required },
      },
      scholarship_project: {
        reference: { required, min: minLength(2), max: maxLength(250) },
        name: {
          pt: { required, min: minLength(2) },
          en: { required, min: minLength(2) },
        },
        scientific_area: {
          pt: { required, min: minLength(2) },
          en: { required, min: minLength(2) },
        },
        advisors: { required, min: minLength(1) },
        requirements: {
          pt: { required, min: minLength(2) },
          en: { required, min: minLength(2) },
        },
        goals: {
          pt: { required, min: minLength(2) },
          en: { required, min: minLength(2) },
        },
      },
    },
  },

  computed: {
    api() {
      return this.$store.state.api.base
    },
    language() {
      return this.$store.state.language
    },
    secondaryLanguage() {
      return this.language === 'pt' ? 'en' : 'pt'
    },
    langs() {
      return [this.language, this.secondaryLanguage]
    },
    isRequiredTuition() {
      return this.metadata.tuition_payment == true
    },
    isProcedureTypeScholarship() {
      return this.procedure !== null && this.procedure.type == 'scholarships'
    },
    locations() {
      if (this.metadata.location) {
        return this.facultiesAndDepartments.filter(option => {
          return (
            option
              .toString()
              .toLowerCase()
              .indexOf(this.metadata.location.toLowerCase()) >= 0
          )
        })
      } else {
        return this.facultiesAndDepartments
      }
    },
    filteredDepartments() {
      if (this.metadata.department) {
        return this.departments.filter(option => {
          return (
            option
              .toString()
              .toLowerCase()
              .indexOf(this.metadata.department.toLowerCase()) >= 0
          )
        })
      } else {
        return this.departments
      }
    },
    filteredScholarshipAmounts() {
      let value = this.metadata.monthly_schoolarship_value ?? 0
      if (value == 0) {
        return this.amountOptions
      }
      return this.amountOptions.filter(option => option.startsWith(value))
    },
  },

  watch: {
    '$v.$invalid'(newInvalid) {
      this.$emit('update:isValid', !newInvalid)
    },

    'metadata.scholarship_project.advisors'(newAdvisors, oldAdvisors) {
      if (oldAdvisors) {
        this.$v.metadata.scholarship_project.advisors.$touch()
      }
    },
  },

  created() {
    this.metadata = {
      prefix: this.procedure.prefix,
      code: this.procedure.code,
      date: this.procedure.date,
      pep_code: this.procedure.pep_code,
      lugus: this.procedure.lugus,
      number_of_seats: this.procedure.number_of_seats,
      monthly_schoolarship_value: this.procedure.monthly_schoolarship_value,
      duration: this.procedure.duration,
      is_renewable: this.procedure.is_renewable,
      tuition_payment: this.procedure.tuition_payment,
      tuition_conditions: this.procedure.tuition_conditions,
      candidate_profile: this.procedure.candidate_profile,
      docs_to_submit: this.procedure.docs_to_submit,
      public_notice_id: this.procedure.public_notice_id,
      category_type: this.procedure.category_type,
      professional_category: this.procedure.professional_category,
      location: this.procedure.location,
      department: this.procedure.department,
      area: this.procedure.area,
      sub_area: this.procedure.sub_area,
      finance_source: this.procedure.finance_source,
      scholarship_project: this.procedure.scholarship_project,
      send_to_email: this.procedure.send_to_email,
    }

    this.$emit('update:isValid', !this.$v.$invalid)
  },

  methods: {
    async endpointSearch(params) {
      return await this.api.getUsers(params)
    },
    selectFinanceSource(value) {
      let data = JSON.parse(JSON.stringify(this.financeSources.find(el => el.value == value)))

      data['text'] = financingTemplates[data.value]

      this.$set(this.metadata, 'finance_source', data)
    },

    choosePeople() {
      this.choosingPeopleType = 'advisors'
      this.currentSelectedPeople = this.procedure.scholarship_project.advisors.map(el => el.key)
      console.log('currentSelectedPeople', this.currentSelectedPeople)
    },

    selectedPeople(selection) {
      console.log(`selectedPeople type:${this.choosingPeopleType}`, selection)
      this.metadata.scholarship_project.advisors = this.metadata.scholarship_project.advisors.concat(selection)
    },

    deleteAdvisor(userKey) {
      console.log('Delete advisor :>> ', userKey, this.metadata.scholarship_project.advisors)
      this.metadata.scholarship_project.advisors = this.metadata.scholarship_project.advisors.filter(
        el => el.key != userKey
      )
    },

    invitePerson(user, roles, type) {
      this.$emit('invite-person', user, roles, type)
    },

    saveProc() {
      this.$v.$touch()

      if (!this.$v.$invalid) {
        this.$emit('save-procedure', this.metadata)
      }
    },

    toggleEditMode() {
      this.editMode = !this.editMode
    },

    closeModal() {
      this.choosingPeopleType = null
    },
  },
}
</script>
<i18n>
{
  "pt": {
    "metadata": "Metadados",
    "thereAreErrors": "Existem erros no formulário",
    "close": "Fechar",
    "edit": "Editar",
    "save": "Guardar",
    "delete": "Remover",
    "generalDetails": "Detalhes gerais",
    "procedureCode": "Código do procedimento",
    "date": {
      "label": "Data",
      "placeholder": "Escolha uma data"
    },
    "pepCode": "Código PEP",
    "charsLimit": "Limite de {limit} caracteres.",
    "seatsNumber": "Número de vaga(s)",
    "scholarships": "bolsa(s)",
    "seats": "lugar(es)",
    "number": "Número",
    "monthlyValue": {
      "label": "Valor mensal da bolsa",
      "placeholder": "Valor mensal"
    },
    "month": "mês | meses",
    "noSugestions": "Sem sugestões",
    "noResults": "Sem resultados",
    "claimant": "Requerente (email)",
    "claimantInstructions": "A plataforma irá enviar uma cópia de todas as notificações relacionadas com este procedimento para o email definido.",
    "duration": "Duração",
    "duration": "Duração de bolsa",
    "possiblyRenewable": "Possivelmente renovável",
    "withTuitionPayment": "Prevê o pagamento de propinas",
    "valueAndConditions": {
      "label": "Valor e condições",
      "placeholder": {
        "ptAndEn": "condições do pagamento de propinas em português e inglês.",
        "pt": "Valor e condições do pagamento das propinas em português",
        "en": "Valor e condições do pagamento das propinas em inglês"
      }
    },
    "yes": "Sim",
    "no": "Não",
    "candidateProfile": {
      "label": "Perfil do candidato",
      "placeholder": {
        "ptAndEn": "perfil do candidato em português e inglês.",
        "pt": "Perfil do candidato em português",
        "en": "Perfil do candidato em inglês"
      }
    },
    "docsToSubmit": {
      "label": "Documentos a entregar",
      "placeholder": {
        "ptAndEn": "documentos a entregar em português e inglês.",
        "pt": "Documentos a entregar em português",
        "en": "Documentos a entregar em inglês"
      }
    },
    "location": "Localização",
    "scholarshipLocation": "Local de realização da bolsa",
    "facultyDepartment": "Faculdade / Departamento",
    "projectDetails": "Detalhes do Projeto",
    "projectReference": "Referência do projeto",
    "projectName": {
      "label": "Nome do projeto",
      "placeholder": {
        "ptAndEn": "nome do projeto em português e inglês.",
        "pt": "Nome do projeto em português",
        "en": "Nome do projeto em inglês"
      }
    },
    "finance": "Financiamento",
    "financeSource": {
      "placeholder": {
        "ptAndEn": "financiamento em Português e Inglês.",
        "pt": "Origem do financiamento em português",
        "en": "Origem do financiamento em inglês"
      }
    },
    "scientificArea": {
      "label": "Área(s) científica(s)",
      "placeholder": {
        "ptAndEn": "área científica em Português e Inglês.",
        "pt": "Área científica em português",
        "en": "Área científica em inglês"
      }
    },
    "advisor": "Orientador",
    "advisors": {
      "label": "Orientação científica",
      "notDefined": "Não foi definido um/a orientador/a científica.",
      "add": "Adicionar Orientador",
      "choose": "Escolha o(s) orientador(es)",
      "select": "Selecione o(s) utilizador(es) que quer como orientadores:"
    },
    "requirements": {
      "label": "Requisitos legais de admissão/destinatários da bolsa",
      "placeholder": {
        "ptAndEn": "requisitos em Português e Inglês.",
        "pt": "Requisitos em português",
        "en": "Requisitos em inglês"
      }
    },
    "goals": {
      "label": "Plano de trabalhos / objetivos",
      "placeholder": {
        "ptAndEn": "plano de trabalhos / objetivos em Português e Inglês.",
        "pt": "Plano de trabalhos / objetivos em português",
        "en": "Plano de trabalhos / objetivos em inglês"
      }
    },
    "notDefined": "Não definido.",
    "one": "um",
    "errors": {
      "required": "Insira o {field} | Insira os {field}",
      "requiredF": "Insira a {field} | Insira as {field}",
      "requiredAtLeast": "Insira pelo menos {number} {field}.",
      "invalid": "Insira um {field} válido",
      "invalidF": "Insira uma {field} válida"
    }
  },
  "en": {
    "metadata": "Metadata",
    "thereAreErrors": "There are errors in the form",
    "close": "Close",
    "edit": "Edit",
    "save": "Save",
    "delete": "Remove",
    "generalDetails": "General details",
    "procedureCode": "Procedure Code",
    "date": {
      "label": "Date",
      "placeholder": "Choose a date"
    },
    "pepCode": "PEP code",
    "charsLimit": "Limit of {limit} characters.",
    "scholarshipsNumber": "Número de lugares",
    "seatsNumber": "Number of scholarship(s)",
    "scholarships": "scholarships",
    "seats": "seats",
    "number": "Number",
    "monthlyValue": {
      "label": "Monthly scholarship amount",
      "placeholder": "Monthly value"
    },
    "month": "month | months",
    "noSugestions": "No suggestions",
    "noResults": "No results",
    "duration": "Duration",
    "claimant": "Requester email",
    "claimantInstructions": "If defined, we will send a copy of any notification related to this procedure.",
    "duration": "Scholarship duration",
    "possiblyRenewable": "Possibly renewable",
    "withTuitionPayment": "Provides for the payment of tuition fees",
    "valueAndConditions": {
      "label": "Value and Conditions",
      "placeholder": {
        "ptAndEn": "conditions for the payment of tuition fees in portuguese and english.",
        "pt": "Value and conditions for the payment of tuition fees in portuguese",
        "en": "Value and conditions for the payment of tuition fees in english"
      }
    },
    "yes": "Yes",
    "no": "No",
    "candidateProfile": {
      "label": "Candidate Profile",
      "placeholder": {
        "ptAndEn": "candidate profile in portuguese and english.",
        "pt": "Candidate profile in portuguese",
        "en": "Candidate profile in english"
      }
    },
    "docsToSubmit": {
      "label": "Documents to be submitted",
      "placeholder": {
        "ptAndEn": "documents to be submitted in portuguese and english.",
        "pt": "Documents to be submitted in portuguese",
        "en": "Documents to be submitted in english"
      }
    },
    "location": "Location",
    "scholarshipLocation": "Location of the scholarship",
    "facultyDepartment": "Faculty / Department",
    "projectDetails": "Project Details",
    "projectReference": "Project reference",
    "projectName": {
      "label": "Project name",
      "placeholder": {
        "ptAndEn": "project name in Portuguese and English.",
        "pt": "Project name in Portuguese",
        "en": "Project name in English"
      }
    },
    "finance": "Financing",
    "financeSource": {
      "placeholder": {
        "ptAndEn": "funding in Portuguese and English.",
        "pt": "Origin of funding in Portuguese",
        "en": "Origin of funding in English"
      }
    },
    "scientificArea": {
      "label": "Scientific area(s)",
      "placeholder": {
        "ptAndEn": "scientific area in Portuguese and English.",
        "pt": "Scientific area in Portuguese",
        "en": "Scientific area in English"
      }
    },
    "advisor": "Advisor",
    "advisors": {
      "label": "Scientific Guidance",
      "notDefined": "No scientific advisor defined.",
      "add": "Add Advisor",
      "choose": "Choose advisor(s)",
      "select": "Select the user(s) you want as advisors:"
    },
    "requirements": {
      "label": "Legal requirements for admission/bursary recipients",
      "placeholder": {
        "ptAndEn": "requirements in Portuguese and English.",
        "pt": "Requirements in Portuguese",
        "en": "Requirements in English"
      }
    },
    "goals": {
      "label": "Work plan / objectives",
      "placeholder": {
        "ptAndEn": "work plan / objectives in Portuguese and English.",
        "pt": "Work plan / objectives in Portuguese",
        "en": "Work plan / objectives in English"
      }
    },
    "notDefined": "Não definido.",
    "one": "one",
    "errors": {
      "required": "Enter the {field} | Enter the {field}",
      "requiredF": "Enter the {field} | Enter the {field}",
      "requiredAtLeast": "Enter at least {number} {field}.",
      "invalid": "Enter a valid {field}",
      "invalidF": "Enter a valid {field}"
    }
  }
}
</i18n>
