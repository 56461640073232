<template>
  <ChoosePeopleModal
    :title="$t('chooseManager')"
    :instructions="$t('selectNewManager')"
    :multiselect="false"
    :selected="[currentSecretariat]"
    :show-confirmation="true"
    :auto-close="false"
    :allow-transfer-message="allowTransferMessage"
    :transfer-to="newSecretariat ? [newSecretariat] : []"
    :confirm-button-text="$t('confirm')"
    :confirm-disabled="confirmDisabled"
    :query-filters="['role:procedure-manager']"
    :not-alowed-users="notAlowedUsers"
    :endpoint="endpointSearch"
    @selected="newSelection"
    @close="close"
    @confirm="confirmEvent"
  >
    <template #previous>
      <div class="flex flex-col gap-3 px-5 mt-1.5">
        <div class="flex-shrink-0 rounded-xl bg-gray-100 px-3 py-2">
          <fw-label v-if="newSecretariat === null">{{ $t('currentManager') }}</fw-label>
          <fw-label v-else>{{ $t('oldManager') }}</fw-label>
          <Person :person="currentSecretariat"></Person>
          <div v-if="newSecretariat !== null" class="flex-shrink-0">
            <fw-label class="mt-2">{{ $t('newmanager') }}</fw-label>
            <Person :person="newSecretariat"></Person>
          </div>
        </div>
      </div>
    </template>
    <template #transfer>
      <fw-panel :title="$t('sendMessage')" :subtitle="$t('optional')" class="mx-5 mt-5">
        <div>
          <fw-label>{{ $t('toNewManager') }}</fw-label>
          <Person :person="newSecretariat"></Person>
        </div>
        <div>
          <fw-label>{{ $t('message') }}</fw-label>
          <b-input v-model="transferMessage" type="textarea" :placeholder="$t('writeMessage')" />
        </div>
        <fw-panel-info clean class="mt-5">{{ $t('toCompleteInfo') }}</fw-panel-info>
        <fw-tip marginless>{{ $t('applWillSendNotification') }}</fw-tip>
      </fw-panel>
    </template>
  </ChoosePeopleModal>
</template>

<script>
import ChoosePeopleModal from '@/fw-modules/fw-core-vue/ui/components/modals/ChoosePeopleModal'
import Person from '@/fw-modules/fw-core-vue/ui/components/cards/Person'

export default {
  name: 'ChooseSecretariatModal',
  components: { ChoosePeopleModal, Person },
  props: {
    currentSecretariat: {
      type: Object,
      required: true,
    },
    notAlowedUsers: {
      type: Array,
      default: function() {
        return []
      },
    },
  },
  data() {
    return {
      newSecretariat: null,
      transferMessage: null,
    }
  },
  computed: {
    api() {
      return this.$store.state.api.base
    },
    user() {
      return this.$store.getters.getUser
    },
    confirmDisabled() {
      return this.newSecretariat === null
    },
    allowTransferMessage() {
      return !this.newSecretariat || (this.newSecretariat && this.newSecretariat.key !== this.user.key)
    },
  },

  methods: {
    async endpointSearch(params) {
      return await this.api.getUsers(params)
    },
    close() {
      this.$emit('close')
    },
    newSelection(users) {
      if (users.length > 0) {
        this.newSecretariat = users[0]
      } else {
        this.newSecretariat = null
      }
    },
    confirmEvent() {
      if (!this.confirmDisabled) {
        this.$emit('selected', this.newSecretariat, { message: this.transferMessage })
      }
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "chooseManager": "Escolha o secretariado",
    "selectNewManager": "Selecione a pessoa que quer como novo secretariado.",
    "confirm": "Confirmar",
    "currentManager": "Secretariado Atual",
    "oldManager": "Secretariado Antigo",
    "newmanager": "Novo Secretariado",
    "sendMessage": "Enviar mensagem",
    "message": "Mensagem",
    "optional": "Opcional",
    "toNewManager": "Para (novo secretariado)",
    "toCompleteInfo": "Para finalizar a tranferência de secretariado, carregue em Confirmar.",
    "writeMessage": "Escreva uma mensagem personalizada para o novo secretariado (opcional)",
    "applWillSendNotification": "A plataforma irá enviar uma notificação de aviso ao novo elemento (com ou sem mensagem personalizada)."
  },
  "en":{
    "chooseManager": "Choose the secretariat",
    "selectNewManager": "Select the person you want as the new secretariat.",
    "confirm": "Confirm",
    "currentManager": "Current Secretariat",
    "oldManager": "Old Secretariat",
    "newmanager": "New Secretariat",
    "sendMessage": "Send message",
    "message": "Message",
    "optional": "Optional",
    "toNewManager": "To (new secretariat)",
    "toCompleteInfo": "To finish the secretariat transfer, press Confirm.",
    "writeMessage": "Write a personalized message to the new secretariat (optional)",
    "applWillSendNotification": "The platform will send a notification to the new element (with or without a personalized message)."

  }

}
</i18n>
