<template>
  <fw-panel
    :title="$t('complaints')"
    custom-class="bg-gray-100"
    boxed="sm"
    :loading="loading"
    :after-loading-checked="true"
  >
    <!-- Complaint message -->
    <div v-if="complaint && complaint.description" class="flex flex-col gap-1">
      <div class="border-b mb-2 pb-3">
        <fw-label marginless>{{ $t('status') }}</fw-label>
        <div v-if="complaint.status === 'closed'" class="font-semibold">
          {{ $t('answered') }}
        </div>
        <div v-else class="font-semibold text-red-600">
          {{ $t('open') }}
        </div>
      </div>
      <div class="rounded-xl p-2 flex items-start gap-3">
        <div class="flex-shrink-0">
          <fw-avatar :user="application.candidate" />
        </div>
        <div class="flex-1 flex flex-col gap-0.5">
          <div class="flex gap-3 text-gray-500 items-center h-8">
            <div class="font-semibold flex-1">
              <v-clamp autoresize :max-lines="1">
                {{ application.candidate.full_name }}
              </v-clamp>
            </div>
            <div class="text-sm">{{ complaint.created_date | formatDateTime }}</div>
          </div>
          <div class="mb-2">
            <div class="message-content" v-html="complaint.description"></div>
          </div>
          <div v-if="complaint.files && complaint.files.length > 0">
            <fw-label>Ficheiros</fw-label>
            <div>
              <RecordFileEntry
                v-for="file in complaint.files"
                :key="file.key"
                paddingless
                class="mb-2"
                :allow-classified="false"
                :file="file"
                :can-edit="false"
                :can-remove="false"
                @download="downloadFile(file)"
              />
            </div>
          </div>
        </div>
      </div>
      <div v-if="canRespond">
        <fw-button type="link" @click.native="adding = true">
          {{ $t('reply') }}
        </fw-button>
      </div>
    </div>

    <!-- Reply -->
    <div v-if="canRespond && adding" class="flex flex-col gap-2 border-t px-2 pb-2 pt-4">
      <div>
        <fw-heading size="h3" muted>Responder</fw-heading>
      </div>
      <div>
        <div class="flex flex-col gap-1">
          <fw-label marginless>Mensagem</fw-label>
          <div class="html-editor bg-gray-200 bg-opacity-50 rounded-lg">
            <editor-content :editor="complaintEditor" />
          </div>
          <div class="my-2">
            <fw-label>{{ $t('attachedFiles') }}</fw-label>
            <div v-if="newMessage.files && newMessage.files.length > 0" class="files mb-1.5">
              <RecordFileEntry
                v-for="file in newMessage.files"
                :key="file.key"
                :can-edit="false"
                :can-remove="false"
                :allow-classified="false"
                :file="file"
                @remove="removeFile(file, $event)"
                @download="downloadFile(file)"
              />
            </div>
            <Uploader
              :label="$t('uploadFiles')"
              :is-docked="true"
              :layout="'minimal'"
              :reference-id="'uploader_update'"
              :allowed="'all'"
              :clear-after="true"
              input-id="upload_input"
              :files.sync="filesToUpload"
              :size="0"
              :new-file-context="{}"
              :file-type="'file'"
              :file-code="''"
              :uploader-class="'w-full rounded'"
              @upload="uploaded($event)"
            />
          </div>
        </div>
        <fw-tip v-if="errors" error>{{ errors }}</fw-tip>
      </div>
      <div class="flex gap-5 justify-end">
        <fw-button type="link-light" @click.native="adding = false">
          {{ $t('cancel') }}
        </fw-button>
        <fw-button type="primary" :disabled="loading" @click.native="addMessage">
          {{ $t('send') }}
        </fw-button>
      </div>
    </div>

    <!-- Messages -->
    <div v-if="complaint && messages.length">
      <div v-for="message in messages" :key="message.key" class="rounded-xl p-3 mb-2 flex items-start gap-3">
        <div v-if="message.user && message.user.key === application.key" :user="message.user" class="flex-shrink-0">
          <fw-avatar />
        </div>
        <div class="flex-1 flex flex-col gap-0.5">
          <div class="flex gap-3 text-gray-500 items-center h-8">
            <div class="font-semibold flex-1">
              <v-clamp autoresize :max-lines="1">
                {{ message.user.full_name }}
              </v-clamp>
            </div>
            <div class="text-sm">{{ message.created_date | formatDateTime }}</div>
          </div>
          <div class="mb-2">
            <div class="message-content" v-html="message.description"></div>
          </div>
          <div v-if="message.files && message.files.length > 0">
            <fw-label>Ficheiros</fw-label>
            <div>
              <RecordFileEntry
                v-for="file in message.files"
                :key="file.key"
                paddingless
                class="mb-2"
                :allow-classified="false"
                :file="file"
                :can-edit="false"
                :can-remove="false"
                @download="downloadFile(file)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <fw-panel-info v-if="!complaint" centered clean>{{ $t('noComplaints') }}</fw-panel-info>
  </fw-panel>
</template>

<script>
import ServiceStorage from '@/fw-modules/fw-core-vue/storage/services/ServiceStorage'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import { Editor, EditorContent } from '@tiptap/vue-2'
import StarterKit from '@tiptap/starter-kit'
import Placeholder from '@tiptap/extension-placeholder'

import Uploader from '@/fw-modules/fw-core-vue/storage/components/PathUploader.vue'
import RecordFileEntry from '@/fw-modules/fw-core-vue/ui/components/form/RecordFileEntry'

export default {
  components: {
    EditorContent,
    Uploader,
    RecordFileEntry,
  },

  props: {
    application: {
      type: Object,
    },
    complaint: {
      type: Object,
    },
    canRespond: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      adding: false,
      errors: null,
      filesToUpload: [],
      newMessage: {
        description: '',
        files: [],
      },
      // Editor respostas a reclamações
      complaintEditor: new Editor({
        editable: true,
        extensions: [
          StarterKit,
          Placeholder.configure({
            emptyNodeClass: 'placeholder-editor',
            placeholder: this.$t('placeholder'),
            showOnlyWhenEditable: true,
          }),
        ],
        onUpdate: ({ editor }) => {
          this.newMessage.description = editor.getHTML()
        },
      }),
    }
  },

  computed: {
    messages() {
      return this.complaint.messages.filter(el => el.type == 'message')
    },
  },

  methods: {
    async downloadFile(file) {
      const url = ServiceStorage.getFileUrl(file, this.$store.state.session.user.token)
      utils.downloadFile(url, file.filename)
    },

    removeFile(f) {
      this.$buefy.dialog.confirm({
        message: this.$t('deleteFileConfirm'),
        onConfirm: async () => {
          this.newMessage.files.splice(f, 1)
        },
      })
    },

    async uploaded(files) {
      let newfiles = []
      for (const file of files) {
        if (file.response.data) {
          if (file.response.status === 'success') {
            newfiles.push(file.response.data.file)
          }
        }
      }

      this.newMessage['files'] = this.newMessage['files'].concat(newfiles)
    },

    addMessage() {
      if (this.newMessage.description && this.newMessage.description.length > 2) {
        this.$buefy.dialog.confirm({
          message: this.$t('confirmSendMessage'),
          confirmText: this.$t('send'),
          cancelText: this.$t('cancel'),
          type: 'is-primary',
          onConfirm: () => {
            this.doAddMessage()
          },
        })
      } else {
        this.errors = 'Insira uma mensagem'
      }
    },

    doAddMessage() {
      console.log('this.newMessage :>> ', this.newMessage)

      if (this.newMessage.description && this.newMessage.description.length > 2) {
        this.$emit('add-message', this.complaint.key, this.newMessage)

        this.errors = null
        this.complaintEditor.commands.setContent('')
        this.adding = false
      } else {
        this.errors = 'Insira uma mensagem'
      }
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "complaints": "Audiência(s) de interessados",
    "complaint": "Alegação",
    "noComplaints": "Sem informação para apresentar.",
    "answered": "Respondido",
    "open": "Sem resposta",
    "reply": "Responder",
    "complaintDate": "Data da alegação",
    "limitDate": "Data limite de audiência(s) de interessados",
    "placeholder": "Escreva a sua resposta aqui...",
    "deleteFileConfirm": "Tem a certeza que deseja eliminar o ficheiro?",
    "confirmSendMessage": "Tem a certeza que deseja enviar a mensagem?",
    "status": "Estado",
    "cancel": "Cancelar",
    "send": "Enviar",
    "attachedFiles": "Ficheiros anexos",
    "uploadFiles": "Carregar ficheiros"
  },
  "en":{
    "complaints": "Stakeholder Hearing",
    "complaint": "Allegation",
    "noComplaints": "No allegations",
    "answered": "Answered",
    "open": "No answer",
    "reply": "Reply",
    "complaintDate": "Stakeholder Hearing date",
    "limitDate": "Max Stakeholder Hearing date",
    "placeholder": "Write your answer here...",
    "deleteFileConfirm": "Are you sure you want to delete the file?",
    "confirmSendMessage": "Are you sure you want to send this message?",
    "status": "Status",
    "send": "Send",
    "attachedFiles": "Attached files",
    "uploadFiles": "Upload files",
    "cancel": "Cancel"
  }
}
</i18n>

<style lang="postcss">
.ProseMirror p.is-editor-empty:first-child:before {
  content: attr(data-placeholder);
  float: left;
  color: #adb5bd;
  pointer-events: none;
  height: 0;
}
.file-uploads {
  @apply w-full;
}
</style>
