<template>
  <div>
    <fw-panel v-if="withCurriculumPanel" :title="$t('curriculumInfo')" custom-class="bg-gray-100" boxed="sm">
      <fw-label v-if="canEditCandidatesAdmission || candidate.admission_status">Admissão</fw-label>
      <fw-panel-info v-else clean centered>Sem informação para apresentar.</fw-panel-info>
      <div>
        <OnoffOption
          v-if="canEditCandidatesAdmission"
          :options="[$t('admitted'), $t('excluded')]"
          :selected-option="
            candidate.admission_status === 'rejected'
              ? $t('excluded')
              : candidate.admission_status === 'selected'
              ? $t('admitted')
              : null
          "
          :disabled="!canEditCandidatesAdmission"
          class="!bg-white shadow-sm"
          @input="admitUser"
        />
        <fw-tag
          v-else-if="!canEditCandidatesAdmission && candidate.admission_status === 'selected'"
          type="primary"
          class="w-24 flex items-center justify-center"
        >
          {{ $t('admitted') }}
        </fw-tag>
        <fw-tag
          v-else-if="!canEditCandidatesAdmission && candidate.admission_status === 'rejected'"
          type="medium"
          class="w-24 flex items-center justify-center"
        >
          {{ $t('excluded') }}
        </fw-tag>
      </div>

      <div v-if="candidate.rejected_step == 'admission'" class="my-5">
        <div class="flex justify-between items-center gap-2">
          <fw-label marginless>{{ $t('rejectReason') }}</fw-label>
          <fw-button v-if="canEditCandidatesAdmission" type="link" @click.native="openRejectedReason">
            {{ $t('editReason') }}
          </fw-button>
        </div>
        <div v-if="candidate.rejected_reason && candidate.rejected_reason.length">
          {{ candidate.rejected_reason }}
        </div>
        <div v-else class="text-sm text-gray-500">{{ $t('notDefined') }}</div>
      </div>

      <div v-if="candidate.admission_status !== 'rejected'">
        <CandidateSeriationResults
          v-if="hasCurriculumEvaluations"
          :evaluations="application.evaluations"
          :criteria-list="curriculumCriteriaList"
          :jury-list="juryList"
          class="mt-5"
        >
        </CandidateSeriationResults>

        <div
          v-if="application.evaluations.curriculum !== null"
          class="flex text-gray-500 font-semibold text-sm p-2 gap-3 border-b border-gray-100 items-end"
        >
          <div class="flex-1">{{ $t('interimResults') }}</div>
          {{ application.evaluations.curriculum | formatDecimal }}
        </div>
      </div>
    </fw-panel>

    <fw-panel
      v-if="withInterviewPanel && candidate.rejected_step != 'curriculum' && candidate.admission_status === 'selected'"
      :title="$t('interviewInfo')"
      class="mt-5"
      :loading="loading"
      :after-loading-checked="true"
      :custom-class="editingInterviewInfo ? '' : 'bg-gray-100'"
      :boxed="editingInterviewInfo ? '' : 'sm'"
    >
      <template v-if="canEditInterviewInfo && !editingInterviewInfo" #toolbar>
        <fw-button type="link" @click.native="editingInterviewInfo = true">
          Editar
        </fw-button>
      </template>

      <fw-label>Data e localização da entrevista</fw-label>
      <div v-if="canEditInterviewInfo && editingInterviewInfo" class="flex gap-3 flex-col">
        <div class="flex gap-3">
          <div class="flex-1">
            <b-datetimepicker
              v-model="$v.candidate.interview_datetime.$model"
              placeholder="Selecione uma data e hora"
              locale="pt-PT"
              :min-datetime="minInterviewDate"
              position="is-top-left"
              append-to-body
              :disabled="!canEditInterviewInfo"
            ></b-datetimepicker>
            <fw-tip v-if="$v.candidate.interview_datetime.$error" error>
              <span v-if="!$v.candidate.interview_datetime.required">Insira uma data</span>
              <span v-else>Data inválida</span>
            </fw-tip>
          </div>
          <div class="flex-1">
            <TextInput
              v-model="$v.candidate.interview_location.$model"
              :minlength="3"
              :maxlength="255"
              placeholder="Localização"
            />
            <fw-tip v-if="$v.candidate.interview_location.$error" error>Insira uma localização</fw-tip>
          </div>
        </div>

        <div class="flex gap-3 justify-end">
          <fw-button type="link-light" @click.native="editingInterviewInfo = false">
            Cancelar
          </fw-button>
          <fw-button type="light-primary" :disabled="loading" @click.native="setCandidatesInterview(false)">
            {{ $t('save') }}
          </fw-button>
          <fw-button type="primary" :disabled="loading" @click.native="setCandidatesInterview(true)">
            Guardar e fechar
          </fw-button>
        </div>
      </div>
      <div v-else>
        <div class="flex gap-3 items-center">
          <div
            class="flex-shrink-0"
            :class="{
              'text-primary': candidate.interview_datetime,
              'text-gray-400': !candidate.interview_datetime,
            }"
          >
            <fw-icon-calendar class="w-5 h-5" />
          </div>
          <div v-if="candidate.interview_datetime">
            {{ candidate.interview_datetime | formatDateTime }}
          </div>
          <div v-else class="text-gray-500">Data e hora não definida.</div>
        </div>

        <div class="flex gap-3 items-center">
          <div
            class="flex-shrink-0"
            :class="{
              'text-primary': candidate.interview_location,
              'text-gray-400': !candidate.interview_location,
            }"
          >
            <fw-icon-home class="w-5 h-5" />
          </div>
          <div v-if="candidate.interview_location">
            {{ candidate.interview_location }}
          </div>
          <div v-else class="text-gray-500">Local não definido.</div>
        </div>
      </div>

      <div class="my-3">
        <fw-label v-if="canEditCandidatesPresences || candidate.attendance_status">Presença na entrevista</fw-label>
        <div>
          <OnoffOption
            v-if="canEditCandidatesPresences"
            :disabled="!canEditCandidatesPresences"
            :options="[$t('present'), $t('absent')]"
            :selected-option="
              candidate.attendance_status === null
                ? null
                : candidate.attendance_status == 'attending'
                ? $t('present')
                : $t('absent')
            "
            class="!bg-white shadow-sm"
            @input="userPresence"
          />
          <fw-tag
            v-else-if="!canEditCandidatesPresences && candidate.attendance_status === 'attending'"
            type="primary"
            class="w-24 flex items-center justify-center"
          >
            {{ $t('present') }}
          </fw-tag>
          <fw-tag
            v-else-if="!canEditCandidatesPresences && candidate.attendance_status === 'missing'"
            type="medium"
            class="w-24 flex items-center justify-center"
          >
            {{ $t('absent') }}
          </fw-tag>
        </div>
      </div>

      <CandidateSeriationResults
        v-if="hasInterviewEvaluations"
        :evaluations="application.evaluations"
        :criteria-list="interviewCriteriaList"
        :jury-list="juryList"
        seriation-type="interview"
        class="mt-5"
      >
      </CandidateSeriationResults>

      <div
        v-if="application.evaluations.interview !== null"
        class="flex text-gray-500 font-semibold text-sm p-2 gap-3 border-b border-gray-100 items-end"
      >
        <div class="flex-1">{{ $t('interimResults') }}</div>
        {{ application.evaluations.interview | formatDecimal }}
      </div>
    </fw-panel>

    <fw-panel
      v-if="withCurriculumPanel && withInterviewPanel && !candidate.rejected_step && application.evaluations.final"
      :title="$t('finalResults')"
      boxed="sm"
      class="mt-5"
      :loading="loading"
      :after-loading-checked="true"
      custom-class="bg-gray-100"
    >
      <div>
        {{ application.evaluations.final | formatDecimal }}
      </div>
    </fw-panel>
  </div>
</template>

<script>
import OnoffOption from '@/fw-modules/fw-core-vue/ui/components/form/onoffOption'
import TextInput from '@/fw-modules/fw-core-vue/ui/components/form/TextInput'

import CandidateSeriationResults from '@/components/panels/CandidateSeriationResults'

import { required, maxLength, minLength, minValue } from 'vuelidate/lib/validators'

export default {
  components: {
    OnoffOption,
    TextInput,
    CandidateSeriationResults,
  },

  validations() {
    return {
      candidate: {
        interview_location: { required, min: minLength(2), max: maxLength(255) },
        interview_datetime: { required, min: minValue(this.minInterviewDate) },
      },
    }
  },

  props: {
    application: {
      type: Object,
      required: true,
    },
    curriculumCriteriaList: {
      type: Array,
      default: () => {
        return []
      },
    },
    interviewCriteriaList: {
      type: Array,
      default: () => {
        return []
      },
    },
    juryList: {
      type: Array,
      default: () => {
        return []
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
    canEditCandidatesPresences: {
      type: Boolean,
      default: false,
    },
    canEditCandidatesAdmission: {
      type: Boolean,
      default: false,
    },
    canEditInterviewInfo: {
      type: Boolean,
      default: false,
    },
    withCurriculumPanel: {
      type: Boolean,
      default: false,
    },
    withInterviewPanel: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      minInterviewDate: this.setMinDatetime(),
      editingInterviewInfo: false,
    }
  },

  computed: {
    candidate() {
      return this.application.candidate
    },

    hasCurriculumEvaluations() {
      return this.application.evaluations.curriculum && this.application.evaluations.curriculum > 0
    },

    hasInterviewEvaluations() {
      return this.application.evaluations.interview && this.application.evaluations.interview > 0
    },
  },

  methods: {
    setMinDatetime() {
      const date = new Date()
      date.setHours(new Date().getHours() + 1)
      return date
    },
    openRejectedReason() {
      this.$emit('open-rejected-reason', this.application.candidate)
    },

    admitUser(val) {
      this.$emit('admit-user', val)
    },

    userPresence(val) {
      this.$emit('set-interview-presence', val)
    },

    setCandidatesInterview(close) {
      this.$v.candidate.$touch()

      if (!this.$v.candidate.$invalid) {
        this.$emit('set-candidates-interview', this.candidate)

        if (close) this.editingInterviewInfo = false
      }
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "curriculumInfo": "Informações da avaliação curricular",
    "admitted": "Admitido",
    "excluded": "Excluído",
    "rejectReason": "Motivo de exclusão",
    "editReason": "Editar fundamentação",
    "notDefined": "Não definido.",
    "juryName": "Nome do Júri",
    "interimResults": "Resultado intercalar",
    "finalResults": "Resultados Finais",
    "save": "Guardar",
    "present": "Presente",
    "absent": "Ausente",
    "interviewInfo": "Informações da entrevista",
    "interviewDate": "Data da entrevista",
    "addInterviewDate": "Insira uma data",
    "interviewLocation": "Local da entrevista",
    "addInterviewLocation": "Insira uma localização",
    "noEval": "Sem avaliação"
  },
  "en": {
    "curriculumInfo": "Informações da avaliação curricular",
    "admitted": "Admitted",
    "excluded": "Excluded",
    "rejectReason": "Reason for exclusion",
    "editReason": "Edit reason",
    "notDefined": "Not defined.",
    "juryName": "Jury Name",
    "interimResults": "Interim results",
    "finalResults": "Final Results",
    "save": "Save",
    "present": "Present",
    "absent": "Absent",
    "interviewInfo": "Interview Information",
    "interviewDate": "Interview date",
    "addInterviewDate": "Enter an interview date",
    "interviewLocation": "Interview location",
    "addInterviewLocation": "Enter an interview location",
    "noEval": "No evaluation"
  }
}
</i18n>
