var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.people)?_c('section',{staticClass:"mt-5"},[_c('fw-heading',{attrs:{"size":"h4","muted":""}},[_vm._v(_vm._s(_vm.$t('juryMembers')))]),_c('fw-panel',{staticClass:"mb-5 mt-2",attrs:{"title":_vm.$t('president'),"boxed":"xs","custom-class":"bg-white","counter":_vm.people.presidents.length}},[(!_vm.people.presidents || _vm.people.presidents.length === 0)?_c('fw-panel-info',{staticClass:"my-3",attrs:{"centered":"","clean":""}},[_vm._v(_vm._s(_vm.$t('presidentRequired')))]):_vm._e(),_c('ExpandList',{attrs:{"add-title":_vm.$t('addPresident'),"empty":!_vm.people.presidents || _vm.people.presidents.length === 0,"show-add-items":_vm.canEdit && (!_vm.people.presidents || _vm.people.presidents.length === 0)},on:{"add-element":function($event){return _vm.choosePeople('presidents')}},scopedSlots:_vm._u([{key:"list",fn:function(){return _vm._l((_vm.people.presidents),function(person,p){return _c('Person',{key:person.key,class:{
            'border-b border-gray-100': p !== _vm.people.presidents.length - 1,
          },attrs:{"open-details-on-click":_vm.canOpenUserDetails,"person":person,"selectable":false,"clickable":_vm.canOpenUserDetails},scopedSlots:_vm._u([{key:"options",fn:function(){return [_c('TagConflitsOfInterest',{attrs:{"has-no-conflit-of-interest":person.no_conflict_of_interest}}),(_vm.canEdit)?_c('b-dropdown',{attrs:{"aria-role":"list","position":"is-bottom-left"}},[_c('fw-button-dropdown',{staticClass:"flex flex-col",attrs:{"slot":"trigger","chevron":false,"type":"simple","label":"Ações"},slot:"trigger"},[_c('fw-icon-more',{staticClass:"w-5 h-5"})],1),_c('b-dropdown-item',{staticClass:"paddingless",attrs:{"custom":"","aria-role":"menuitem"}},[_c('fw-button',{attrs:{"type":"basic-action","custom-class":"w-full"},nativeOn:{"click":function($event){return _vm.deletePresident(person.key)}}},[_vm._v(_vm._s(_vm.$t('delete')))])],1)],1):_vm._e()]},proxy:true}],null,true)})})},proxy:true}],null,false,1649951463)})],1),_c('fw-panel',{staticClass:"my-5",attrs:{"title":_vm.$t('effectiveVogals'),"boxed":"xs","custom-class":"bg-white","counter":_vm.people.vogals.effective.length}},[(!_vm.people.vogals.effective.length)?_c('fw-panel-info',{staticClass:"my-3",attrs:{"centered":"","clean":""}},[_vm._v(_vm._s(_vm.$t('effectiveVogalsRequired')))]):_vm._e(),_c('ExpandList',{attrs:{"add-title":_vm.$t('addEffectiveVogals'),"empty":!_vm.people.vogals.effective || _vm.people.vogals.effective.length < 1,"show-add-items":_vm.canEdit && _vm.totalVogalsMembers < _vm.maxVogals},on:{"add-element":function($event){return _vm.choosePeople('effective-vogals')}},scopedSlots:_vm._u([{key:"list",fn:function(){return _vm._l((_vm.people.vogals.effective),function(person,p){return _c('Person',{key:person.key,class:{
            'border-b border-gray-100': _vm.canEdit || p < _vm.people.vogals.effective.length - 1,
          },attrs:{"open-details-on-click":_vm.canOpenUserDetails,"person":person,"selectable":false,"clickable":_vm.canOpenUserDetails},scopedSlots:_vm._u([{key:"options",fn:function(){return [_c('TagConflitsOfInterest',{attrs:{"has-no-conflit-of-interest":person.no_conflict_of_interest}}),(_vm.canEdit)?_c('b-dropdown',{attrs:{"aria-role":"list","position":"is-bottom-left"}},[_c('fw-button-dropdown',{staticClass:"flex flex-col",attrs:{"slot":"trigger","chevron":false,"type":"simple","label":"Ações"},slot:"trigger"},[_c('fw-icon-more',{staticClass:"w-5 h-5"})],1),_c('b-dropdown-item',{staticClass:"paddingless",attrs:{"custom":"","aria-role":"menuitem"}},[_c('fw-button',{attrs:{"type":"basic-action","custom-class":"w-full"},nativeOn:{"click":function($event){return _vm.deleteVogal(person.key)}}},[_vm._v(_vm._s(_vm.$t('delete')))])],1)],1):_vm._e()]},proxy:true}],null,true)})})},proxy:true}],null,false,2012904031)})],1),_c('fw-panel',{staticClass:"my-5",attrs:{"title":_vm.$t('substituteVogals'),"boxed":"xs","custom-class":"bg-white","counter":_vm.people.vogals.substitute.length}},[(!_vm.people.vogals.substitute.length)?_c('fw-panel-info',{staticClass:"my-3",attrs:{"centered":"","clean":""}},[_c('div',[_vm._v(_vm._s(_vm.$t('noSubstituteVogals')))]),(_vm.canEdit && _vm.totalVogalsMembers === _vm.maxVogals)?_c('div',{staticClass:"text-xs mx-auto max-w-sm my-1"},[_vm._v(" "+_vm._s(_vm.$t('maxVogals'))+" ")]):_vm._e()]):_vm._e(),_c('ExpandList',{attrs:{"add-title":_vm.$t('addSubstituteVogals'),"empty":!_vm.people.vogals.substitute || _vm.people.vogals.substitute.length < 1,"show-add-items":_vm.canEdit && _vm.totalVogalsMembers < _vm.maxVogals},on:{"add-element":function($event){return _vm.choosePeople('substitute-vogals')}},scopedSlots:_vm._u([{key:"list",fn:function(){return _vm._l((_vm.people.vogals.substitute),function(person,p){return _c('Person',{key:person.key,class:{
            'border-b border-gray-100': _vm.canEdit || p < _vm.people.vogals.substitute.length - 1,
          },attrs:{"open-details-on-click":_vm.canOpenUserDetails,"person":person,"selectable":false,"clickable":_vm.canOpenUserDetails},scopedSlots:_vm._u([{key:"options",fn:function(){return [_c('TagConflitsOfInterest',{attrs:{"has-no-conflit-of-interest":person.no_conflict_of_interest}}),(_vm.canEdit)?_c('b-dropdown',{attrs:{"aria-role":"list","position":"is-bottom-left"}},[_c('fw-button-dropdown',{staticClass:"flex flex-col",attrs:{"slot":"trigger","chevron":false,"type":"simple","label":"Ações"},slot:"trigger"},[_c('fw-icon-more',{staticClass:"w-5 h-5"})],1),_c('b-dropdown-item',{staticClass:"paddingless",attrs:{"custom":"","aria-role":"menuitem"}},[_c('fw-button',{attrs:{"type":"basic-action","custom-class":"w-full"},nativeOn:{"click":function($event){return _vm.deleteVogal(person.key)}}},[_vm._v(_vm._s(_vm.$t('delete')))])],1)],1):_vm._e()]},proxy:true}],null,true)})})},proxy:true}],null,false,428169055)})],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }