<template>
  <div>
    <fw-label>Seriação</fw-label>
    <div
      class="grid gap-x-3 items-center overflow-x-auto fw-scrollbar"
      :style="`grid-template-columns: 1.5fr repeat(${criteriaList.length}, minmax(8rem, 1fr))`"
    >
      <div class="text-gray-500 font-semibold text-sm">{{ $t('juryName') }}</div>
      <div
        v-for="(criterium, c) in criteriaList"
        :key="`${seriationType}_criterium_header_${c}`"
        class="text-right text-gray-500 font-semibold text-sm"
        :title="criterium.name[language]"
      >
        <v-clamp autoresize :max-lines="2">{{ criterium.name[language] }}</v-clamp>
        <div class="text-gray-400 text-xs">{{ criterium.value }}%</div>
      </div>

      <hr class="dropdown-divider bg-white mb-0.5 mt-2" :style="`grid-column: 1 / span ${criteriaList.length + 1};`" />

      <template v-for="jury in juryList">
        <template v-if="jury[`joined_${seriationType}`]">
          <Person
            :key="`${seriationType}_person_${jury.key}`"
            no-style
            :person="jury"
            :selectable="false"
            :clickable="false"
          >
          </Person>
          <div
            v-for="(criterium, c) in criteriaList"
            :key="`${seriationType}_criterium_value_${c}_${jury.key}`"
            class="p-2 text-right"
          >
            <span>
              {{
                evaluations[jury.jury_key][criterium.key] === null
                  ? $t('noEval')
                  : evaluations[jury.jury_key][criterium.key] | formatDecimal
              }}
            </span>
          </div>

          <hr
            :key="`${seriationType}_hr_${jury.key}`"
            class="dropdown-divider bg-white m-0.5"
            :style="`grid-column: 1 / span ${criteriaList.length + 1};`"
          />
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import Person from '@/fw-modules/fw-core-vue/ui/components/cards/Person'

export default {
  components: {
    Person,
  },

  props: {
    evaluations: {
      type: Object,
      required: true,
    },
    criteriaList: {
      type: Array,
      default: () => {
        return []
      },
    },
    juryList: {
      type: Array,
      default: () => {
        return []
      },
    },
    seriationType: {
      type: String,
      default: 'curriculum',
    },
  },

  computed: {
    language() {
      return this.$store.state.language
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "juryName": "Nome do Júri",
    "noEval": "Sem avaliação"
  },
  "en": {
    "juryName": "Jury Name",
    "noEval": "No evaluation"
  }
}
</i18n>
