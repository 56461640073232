<template>
  <div class="bg-white p-5">
    <fw-heading>
      Abrir procedimento <span class="uppercase">{{ procedureFullCode }}</span> ao júri
    </fw-heading>

    <b-tabs v-model="activetab" class="mt-3 mb-0">
      <b-tab-item label="PT" value="pt" class="-mt-2">
        <template #header>
          <div class="legend px-2 py-1 bg-white uppercase rounded shadow leading-4 font-semibold">
            PT
          </div>
        </template>

        <div>
          <fw-label>Assunto</fw-label>
          <div class="html-editor rounded-lg">
            <template-editor
              :show-menu="false"
              :text="subject_pt"
              :variables-map="templateEditorVariables"
              @change="valueChanged('subject_pt', $event)"
            />
          </div>
        </div>

        <div>
          <fw-label>Mensagem</fw-label>
          <div class="html-editor rounded-lg overflow-y-auto flex-1">
            <template-editor
              :text="body_pt"
              :variables-map="templateEditorVariables"
              @change="valueChanged('body_pt', $event)"
            />
          </div>
        </div>
      </b-tab-item>

      <b-tab-item label="EN" value="en" class="-mt-2">
        <template #header>
          <div class="legend px-2 py-1 bg-white uppercase rounded shadow leading-4 font-semibold">
            EN
          </div>
        </template>

        <div>
          <fw-label>Subject</fw-label>
          <div class="html-editor rounded-lg">
            <template-editor
              :show-menu="false"
              :text="subject_en"
              :variables-map="templateEditorVariables"
              @change="valueChanged('subject_en', $event)"
            />
          </div>
        </div>
        <div>
          <fw-label>Message</fw-label>
          <div class="html-editor rounded-lg overflow-y-auto flex-1">
            <template-editor
              :text="body_en"
              :variables-map="templateEditorVariables"
              @change="valueChanged('body_en', $event)"
            />
          </div>
        </div>
      </b-tab-item>
    </b-tabs>

    <fw-panel-info clean class="-mt-2">
      Utilize o símbolo
      <div
        class=" border border-gray-200 text-gray-600 bg-gray-100 inline-flex w-7 h-6 rounded item-center justify-center border-b-2"
      >
        $
      </div>
      do seu teclado para adicionar variáveis ao texto
    </fw-panel-info>

    <div class="flex items-center justify-end gap-5 mt-5">
      <fw-button type="link-muted" @click.native="$emit('close')">
        {{ cancelButtonText }}
      </fw-button>
      <fw-button type="primary" @click.native="save">
        <template #icon>
          <fw-icon-send-plane class="w-5 h-5" />
        </template>
        {{ confirmButtonText }}
      </fw-button>
    </div>
  </div>
</template>

<script>
import TemplateEditor from '@/components/editor/TemplateEditor'
export default {
  components: {
    TemplateEditor,
  },
  props: {
    defaultTemplate: {
      type: Object,
      default: () => {
        return {}
      },
    },
    templateEditorVariables: {
      type: Object,
      default: () => {
        return {}
      },
    },
    procedureFullCode: {
      type: String,
      default: 'Teste',
    },

    cancelButtonText: {
      type: String,
      default: 'Cancelar',
    },

    confirmButtonText: {
      type: String,
      default: 'Guardar',
    },
  },
  data() {
    return {
      activetab: 'pt',
      subject_pt: '',
      subject_en: '',
      body_pt: '',
      body_en: '',
    }
  },

  created() {
    this.subject_pt = this.defaultTemplate.title.pt.replace(/(\r\n|\n|\r)/gm, '')
    this.subject_en = this.defaultTemplate.title.en.replace(/(\r\n|\n|\r)/gm, '')
    this.body_pt = this.defaultTemplate.email.pt.replace(/(\r\n|\n|\r)/gm, '')
    this.body_en = this.defaultTemplate.email.en.replace(/(\r\n|\n|\r)/gm, '')
  },

  methods: {
    valueChanged(type, value) {
      this[type] = value
    },

    save() {
      this.$emit('save', {
        subject: {
          pt: this.subject_pt,
          en: this.subject_en,
        },
        body: {
          pt: this.body_pt,
          en: this.body_en,
        },
      })
    },
  },
}
</script>
