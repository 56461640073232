<template>
  <section v-if="procedure">
    <fw-panel :title="$t('complaints')" boxed="xs" custom-class="bg-white" class="my-5" :counter="complaints.length">
      <fw-panel-info v-if="!complaints.length" centered clean class="my-3">
        {{ $t('noComplaints') }}
      </fw-panel-info>

      <RecycleScroller
        v-if="complaints.length > 0"
        v-slot="{ item }"
        key-field="key"
        :items="complaints"
        :item-size="57"
        :buffer="50"
      >
        <div class="p-1 border-b border-gray-100 flex gap-2">
          <Person
            :person="item.user"
            :selectable="false"
            :clickable="true"
            class="flex-1"
            @click.native="openApplication(item.user.candidate_key)"
          >
            <template #options>
              <div class="flex items-end gap-5">
                <div class="text-sm font-semibold min-w-24">
                  <fw-label size="xs" marginless>{{ $t('complaintDate') }}</fw-label>
                  <div class="text-gray-500">{{ item.created_date | formatDate }}</div>
                </div>
                <div class="text-sm font-semibold min-w-24">
                  <fw-label size="xs" marginless>{{ $t('limitDate') }}</fw-label>
                  <div v-if="item.user.admission_status == 'rejected'" class="text-gray-500">
                    {{ procedure.complaints_info.rejected_end_date | formatDate }}
                  </div>
                  <div v-else class="text-gray-500">{{ procedure.complaints_info.end_date | formatDate }}</div>
                </div>
                <div class="text-sm font-semibold min-w-24">
                  <fw-label size="xs" marginless>{{ $t('complaintStatus') }}</fw-label>
                  <div v-if="item.status === 'closed'" class="text-primary">
                    {{ $t('answered') }}
                  </div>
                  <div v-else class="text-red-600">{{ $t('reply') }}</div>
                </div>
                <div class="w-5 hidden md:flex">
                  <fw-icon-check v-if="item.status === 'closed'" class="w-5 h-5 text-primary flex-shrink-0" />
                </div>
              </div>
            </template>
          </Person>
        </div>
      </RecycleScroller>
    </fw-panel>
  </section>
</template>

<script>
import Person from '@/fw-modules/fw-core-vue/ui/components/cards/Person'
import { RecycleScroller } from 'vue-virtual-scroller'

export default {
  components: {
    Person,
    RecycleScroller,
  },

  props: {
    procedure: {
      type: Object,
      default: () => {
        return {}
      },
    },
    complaints: {
      type: Array,
      default: () => {
        return []
      },
    },
  },

  methods: {
    openApplication(userkey) {
      this.$emit('open-application', userkey)
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "complaints": "Audiência(s) de interessados",
    "complaintStatus": "Estado",
    "noComplaints": "Não existem registos de audiências de interessados",
    "answered": "Respondido",
    "reply": "Sem resposta",
    "complaintDate": "Data de submissão",
    "limitDate": "Data limite"
  },
  "en":{
    "complaints": "Stakeholder Hearing",
    "complaintStatus": "Status",
    "noComplaints": "No allegations",
    "answered": "Answered",
    "reply": "No answer",
    "complaintDate": "Allegation date",
    "limitDate": "Max date"
  }
}
</i18n>
