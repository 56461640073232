<template>
  <fw-panel v-if="people" :title="title" boxed="xs" custom-class="bg-white">
    <template #toolbar>
      <CandidatesInfoDownload
        v-if="people.length > 0 && allowDownloadCandidatesZip"
        :downloading="downloading"
        @download="downloadCandidates"
      />
    </template>
    <div v-if="people.length > 0">
      <Person
        v-for="person in people"
        :key="person.key"
        no-style
        :person="person"
        class="border-b border-gray-100"
        :selectable="false"
        :clickable="true"
        @click.native="openApplication(person.candidate_key)"
      >
        <template #options>
          <div class="flex-col gap-3" @click.stop>
            <OnoffOption
              v-if="canEdit"
              :disabled="!canEdit"
              :options="['Presente', 'Ausente']"
              :selected-option="
                person.attendance_status ? (peoplePresent.includes(person.key) ? 'Presente' : 'Ausente') : null
              "
              @input="userPresence(person.candidate_key, $event)"
            />
            <div v-else-if="person.attendance_status" @click.stop>
              <b-dropdown
                v-if="
                  canDownloadPresenceFile &&
                    person.attendance_status == 'attending' &&
                    peoplePresent.includes(person.key)
                "
                aria-role="list"
                position="is-bottom-left"
              >
                <button slot="trigger">
                  <fw-tag type="primary" class="w-28 flex items-center justify-center hover:opacity-80">
                    {{ $t('present') }} <fw-icon-chevron-down class="w-5 h-5 ml-2" />
                  </fw-tag>
                </button>
                <b-dropdown-item custom aria-role="menuitem" class="paddingless w-56">
                  <DownloadButton
                    custom-label="Declaração de presença"
                    expanded
                    @download="downloadPresenceFile(person)"
                  />
                </b-dropdown-item>
              </b-dropdown>
              <fw-tag
                v-else-if="peoplePresent.includes(person.key)"
                type="primary"
                class="w-24 flex items-center justify-center"
              >
                {{ $t('present') }}
              </fw-tag>
              <fw-tag v-else type="medium" class="w-24 flex items-center justify-center">
                {{ $t('absent') }}
              </fw-tag>
            </div>
          </div>
        </template>
      </Person>
    </div>
    <fw-panel-info v-else empty>
      {{ noContentMessage }}
    </fw-panel-info>
  </fw-panel>
</template>

<script>
import Person from '@/fw-modules/fw-core-vue/ui/components/cards/Person'
import OnoffOption from '@/fw-modules/fw-core-vue/ui/components/form/onoffOption'
import CandidatesInfoDownload from '@/components/buttons/CandidatesInfoDownload'
import DownloadButton from '@/fw-modules/fw-core-vue/ui/components/buttons/DownloadButton'

export default {
  components: {
    Person,
    OnoffOption,
    DownloadButton,
    CandidatesInfoDownload,
  },
  props: {
    people: {
      type: Array,
      required: true,
    },

    peoplePresent: {
      type: Array,
      required: true,
    },

    procedure: {
      type: Object,
    },

    title: {
      type: String,
      default: 'Presenças',
    },

    noContentMessage: {
      type: String,
      default: 'Sem conteúdo',
    },

    canEdit: {
      type: Boolean,
      default: false,
    },

    allowDownloadCandidatesZip: {
      type: Boolean,
      default: false,
    },

    downloading: {
      type: Boolean,
      default: false,
    },

    canDownloadPresenceFile: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    downloadCandidates(option = 'files') {
      if (this.allowDownloadCandidatesZip) {
        this.$emit('download-candidates', option)
      }
    },

    userPresence(candidateKey, rawValue) {
      this.$emit('set-user-presence', candidateKey, rawValue)
    },

    openApplication(candidateKey) {
      this.$emit('open-application', candidateKey)
    },

    downloadPresenceFile(candidate) {
      console.log('downloadPresenceFile :>> ', candidate)
      this.$emit('download-presence-file', candidate)
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "present": "Presente",
    "absent": "Ausente"
  },
  "en": {
    "present": "Present",
    "absent": "Absent"
  }
}
</i18n>
