<template>
  <fw-panel
    v-if="application"
    title="Fundamentação de exclusão"
    class="h-full flex flex-col p-5 gap-5"
    :loading="savingData"
    after-loading-checked
  >
    <Person :person="application" class="mb-2 -mx-1 -mt-2" />
    <div>
      <div v-if="!editable" class="text-sm">
        <div class="text-gray-400 font-semibold">Motivo de exclusão</div>
        {{ application.rejected_reason.length ? application.rejected_reason : 'Motivo de exclusão não definido' }}
      </div>
      <div v-else>
        <b-input
          v-model="$v.rejectedReason.$model"
          type="textarea"
          placeholder="Fundamentação do resultado"
          rows="10"
          :disabled="!editable"
        ></b-input>
        <fw-tip v-if="$v.rejectedReason.$error" error>
          Insira uma fundamentação que justifique a exclusão do candidato/a.
        </fw-tip>
      </div>
    </div>
    <div class="flex gap-3 items-center justify-end mt-5">
      <fw-button type="link-muted" @click.native="close">
        Cancelar
      </fw-button>
      <fw-button v-if="editable" type="light-primary" @click.native="saveRejectReason">
        Guardar
      </fw-button>
      <fw-button v-if="editable" type="primary" @click.native="saveRejectReasonAndClose">
        Guardar e fechar
      </fw-button>
    </div>
  </fw-panel>
</template>

<script>
import Person from '@/fw-modules/fw-core-vue/ui/components/cards/Person'

import { required, minLength } from 'vuelidate/lib/validators'

export default {
  components: {
    Person,
  },

  props: {
    application: {
      type: Object,
      default: () => {
        return {}
      },
    },

    editable: {
      type: Boolean,
      default: true,
    },

    savingData: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      error: null,
      rejectedReason: null,
    }
  },

  created() {
    this.rejectedReason = this.application.rejected_reason
  },

  validations: {
    rejectedReason: { required, min: minLength(2) },
  },

  methods: {
    close() {
      this.$emit('close')
    },
    saveRejectReason(closeAfterSave) {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.$emit('save', this.application.candidate_key, this.rejectedReason)
        if (closeAfterSave === true) {
          setTimeout(() => {
            this.close()
          }, 500)
        }
      }
    },
    saveRejectReasonAndClose() {
      this.saveRejectReason(true)
    },
  },
}
</script>
