<template>
  <div class="items">
    <div class="bg-gray-100 py-1 px-4 text-sm text-gray-400 font-semibold">{{ $t('variables') }}</div>
    <div class="p-2">
      <template v-if="items.length">
        <button
          v-for="(item, index) in items"
          :key="index"
          class="item"
          :class="{ 'is-selected': index === selectedIndex }"
          @click="selectItem(index)"
        >
          {{ item.text }}
        </button>
      </template>
      <div v-else class="item">
        {{ $t('noOptions') }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    command: {
      type: Function,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selectedIndex: 0,
    }
  },

  watch: {
    items() {
      this.selectedIndex = 0
    },
  },

  methods: {
    onKeyDown({ event }) {
      if (event.key === 'ArrowUp') {
        this.upHandler()
        return true
      }

      if (event.key === 'ArrowDown') {
        this.downHandler()
        return true
      }

      if (event.key === 'Enter') {
        this.enterHandler()
        return true
      }

      return false
    },

    upHandler() {
      this.selectedIndex = (this.selectedIndex + this.items.length - 1) % this.items.length
    },

    downHandler() {
      this.selectedIndex = (this.selectedIndex + 1) % this.items.length
    },

    enterHandler() {
      this.selectItem(this.selectedIndex)
    },

    selectItem(index) {
      const item = this.items[index]

      if (item) {
        this.command({ code: item.code, text: item.text })
      }
    },
  },
}
</script>

<style lang="scss">
.items {
  position: relative;
  border-radius: 0.5rem;
  background: #fff;
  color: rgba(0, 0, 0, 0.8);
  overflow: hidden;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0px 10px 20px rgba(0, 0, 0, 0.1);
}

.item {
  @apply border-2 cursor-pointer px-2 py-1 border-transparent rounded-md w-full m-0 text-left block text-base;

  &.is-selected {
    @apply border-primary;
  }
  &:hover {
    @apply border-primary border-opacity-40;
  }
}
</style>

<i18n>
{
  "pt": {
    "variables": "Variáveis",
    "noOptions": "Sem opções"
  },
  "en": {
    "variables": "Variables",
    "noOptions": "No options"
  }
}
</i18n>
