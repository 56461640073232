<template>
  <MeetingModal
    :key="meetingData.key ? meetingData.key : 'new'"
    :type-options="meetingTypes"
    :modal-title="
      meetingGoalOverride === 'interview_notification'
        ? $t('notifyToInterview')
        : meetingGoalOverride === 'set_interview'
        ? $t('setInterview')
        : ''
    "
    :save-text="
      meetingGoalOverride === 'interview_notification'
        ? $t('notifyCandidates')
        : meetingGoalOverride === 'set_interview'
        ? $t('setInterview')
        : $t('save')
    "
    :show-send-message-form="showSendMessageForm"
    :confirm-disabled="false"
    :meeting-data="meetingData"
    :message-text="meetingModalText"
    :virtual-meeting="virtualMeetingData"
    :subject-options="availableMeetingGoals"
    :min-date="minDate"
    @save="saveMeeting"
    @close="$emit('close')"
  >
    <b-checkbox v-if="canNotifyCalendar" v-model="notify">{{ $t('notifyCalendarUpdate') }}</b-checkbox>
    <template v-if="canNotifyCalendar" #message>
      <div class="px-0.5">
        <fw-label>{{ $t('message') }}</fw-label>
        <b-input v-model="transferMessage" type="textarea" :placeholder="$t('writeMessage')" />
        <fw-tip><span v-html="$t('messageInstructions')"></span></fw-tip>
      </div>
    </template>
  </MeetingModal>
</template>

<script>
import MeetingModal from '@/fw-modules/fw-core-vue/ui/components/modals/MeetingModal'

import { required, requiredIf } from 'vuelidate/lib/validators'
import Dates from '@/fw-modules/fw-core-vue/utilities/dates'

export default {
  name: 'ProcedureMeetingModal',
  components: { MeetingModal },
  props: {
    meetingData: {
      type: Object,
      required: true,
    },
    meetingTypes: {
      type: Array,
      required: true,
    },
    meetingGoalOverride: {
      type: String,
    },
    meetingModalText: {
      type: String,
    },
    virtualMeetingData: {
      type: Object,
    },
    availableMeetingGoals: {
      type: Array,
      required: true,
    },
    canNotifyCalendar: {
      type: Boolean,
      default: false,
    },
  },

  validations() {
    return {
      meetingData: {
        date: { required },
        subject: { required },
        type: { required },
        location: {
          required: requiredIf(function(nestedModel) {
            return nestedModel.type == 'in_person'
          }),
        },
      },
    }
  },

  data() {
    return {
      minDate: new Date(),
      newSecretariat: null,
      transferMessage: null,
      notify: false,
    }
  },

  computed: {
    showSendMessageForm() {
      return this.notify
    },
  },

  created() {
    this.minDate = Dates.now()
      .subtract(1, 'day')
      .toDate()
  },

  methods: {
    saveMeeting(data) {
      this.$v.$touch()

      if (!this.$v.$invalid) {
        this.$emit('save', data, this.notify, this.transferMessage)
      }
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "notifyToInterview": "Notificar Candidatos para Entrevista",
    "setInterview": "Marcar Entrevistas",
    "notifyCandidates": "Notificar Candidatos",
    "save": "Guardar",
    "notifyCalendarUpdate": "Notificar agendamento/atualização de calendário",
    "message": "Mensagem",
    "sendMessage": "Enviar mensagem",
    "writeMessage": "Escreva uma mensagem personalizada (opcional).",
    "messageInstructions": "A plataforma irá enviar uma notificação de aviso aos elementos de júri (com ou sem mensagem personalizada)."
  },
  "en": {
    "notifyToInterview": "Notify Candidates for Interview",
    "setInterview": "Schedule Interviews",
    "notifyCandidates": "Notify Candidates",
    "save": "Save",
    "notifyCalendarUpdate": "Notify scheduling/calendar update",
    "message": "Message",
    "sendMessage": "Send message",
    "writeMessage": "Write a personalized message (optional).",
    "messageInstructions": "The platform will send a warning notification to the jury members (with or without a personalized message)."
  }
}
</i18n>
