<template>
  <div>
    <fw-heading size="xl">Dashboard</fw-heading>
    <DashboardHero :title-label="$t('procedureCode')" :tag="$t('scholarship')" class="mt-2 mb-5">
      <template #title>
        <fw-heading size="h1">
          <span class="font-medium opacity-90">{{ procedure.prefix }}</span>
          <span>{{ procedure.code }}</span>
        </fw-heading>
      </template>
      <template #footer>
        <div v-if="isProcedureTypeScholarship" class="text-center" style="max-width: 33%;">
          <fw-label color="text-white opacity-50">LUGUS</fw-label>
          <div>{{ procedure.lugus || $t('notDefined') }}</div>
        </div>
        <div>
          <fw-label color="text-white opacity-50">{{
            isProcedureTypeScholarship ? $t('scholarshipsNumber') : $t('seatsNumber')
          }}</fw-label>
          <div>{{ procedure.number_of_seats || $t('notDefined') }}</div>
        </div>
        <div v-if="isProcedureTypeScholarship">
          <fw-label color="text-white opacity-50">{{ $t('selectionMethods') }}</fw-label>
          <div class="flex gap-3">
            <fw-tag size="sm" custom-class="bg-opacity-30 bg-white"
              >{{ $t('curriculumEval') }}
              <span class="font-bold opacity-80 ml-2">{{ procedure.curriculum_weight }}%</span>
            </fw-tag>
            <fw-tag v-if="procedure.with_interview" size="sm" custom-class="bg-opacity-30 bg-white">
              {{ $t('interview') }}
              <span class="font-bold opacity-80 ml-2">{{ procedure.interview_weight }}%</span>
            </fw-tag>
          </div>
        </div>
      </template>
    </DashboardHero>

    <fw-panel :title="$t('statusMainBoardTitle')" class="my-5">
      <div class="flex flex-col gap-4">
        <div class="flex gap-3">
          <template v-for="(status, key) in procedureStatus">
            <div
              v-if="procedure.status !== 'closed' || (procedure.status == 'closed' && key === procedure.status)"
              :key="key"
              :class="{
                'w-1/4 border-primary min-h-28': procedure.status == key && procedure.status != 'closed',
                'w-1/4 border-white min-h-28': procedure.status != key,
                'w-full border-white': procedure.status == 'closed',
              }"
              class="relative rounded-xl bg-white border p-4 flex flex-col text-center font-semibold items-center gap-1 cursor-pointer group"
              @click="openStatusInfoModal({ key: key, meta: status })"
            >
              <div class="w-full flex items-center justify-center">
                <div v-if="status.isDone">
                  <fw-icon-check-solid class="w-6 h-6 flex-shrink-0 text-primary" />
                </div>
                <div v-else-if="procedure.status == key">
                  <fw-icon-run class="w-6 h-6 flex-shrink-0 text-primary animate-pulse" />
                </div>
                <div v-else>
                  <fw-icon-rest class="w-6 h-6 flex-shrink-0 text-gray-300" />
                </div>
              </div>
              <div
                class="flex-1"
                :class="{
                  'font-bold text-primary': status.isActive || status.isDone,
                  'font-bold': status.canChange,
                  'font-medium': status.isDone && !status.isActive,
                  'text-gray-500': !status.isDone && !status.isActive,
                }"
              >
                {{ status.label }}
              </div>
              <div v-if="procedure.status != 'closed'" class="flex justify-center items-center font-normal text-xs">
                <div v-if="procedure.status == key && key != 'closed'" class="text-primary">
                  {{ $t('phaseOngoing') }}
                </div>
                <div v-else-if="!status.canChange && status.isDone && key != 'closed'" class="text-primary">
                  {{ $t('phaseFinished') }}
                </div>
                <div v-else-if="status.canChange && !status.isDone" class="text-primary">
                  {{ $t('phaseAvailable') }}
                </div>
                <div v-else-if="key != 'closed'" class="text-gray-400">
                  {{ $t('phasePending') }}
                </div>
              </div>
              <div class="absolute top-2 right-2">
                <fw-icon-info class="w-4 h-4 text-gray-300 group-hover:text-gray-400" />
              </div>
            </div>
          </template>
        </div>
        <fw-panel-info v-if="procedure.status != 'closed'" clean :icon="false" centered size="xs"
          >{{ $t('statusInfo') }}.</fw-panel-info
        >
      </div>
    </fw-panel>

    <fw-panel :title="$t('calendar')" boxed="xs" custom-class="bg-white" class="my-5">
      <div class="flex px-4 pb-3 pt-1 w-full gap-12">
        <div class="w-1/2 lg:w-1/3">
          <fw-label :checked="procedure.publish_ended">{{ $t('applications') }}</fw-label>
          <div v-if="procedure.publish_start && procedure.publish_end" class="font-medium items-center">
            <div class="flex gap-1 items-center">
              <fw-icon-calendar class="w-5 h-5 opacity-50" style="min-width: 1.25rem;" />
              <span> {{ procedure.publish_start | formatDate }} a {{ procedure.publish_end | formatDate }}</span>
            </div>
            <div v-if="procedure.publish_ended" class="text-gray-500 text-sm">({{ $t('closed') }})</div>
          </div>
          <div v-else class="font-medium flex gap-2 items-center text-sm text-gray-500">
            <fw-icon-calendar class="w-5 h-5 opacity-50" />
            {{ $t('notDefined') }}
          </div>
        </div>
        <div class="w-1/2 lg:w-2/3">
          <fw-label>{{ $t('nextMeeting') }}</fw-label>
          <div v-if="procedure.next_meeting" class="font-medium">
            <div class="flex gap-1 items-center text-primary">
              <fw-icon-calendar class="w-5 h-5 opacity-100 flex-shrink-0" />
              <span>{{ procedure.next_meeting.date | formatDateTime }}</span> -
              <span>{{ $t(`meetingGoals.${procedure.next_meeting.goal}`) }}</span>
            </div>
            <div class="text-sm text-gray-500 truncate min-w-0 max-w-xl mt-0.5" :title="procedure.next_meeting.locatio">
              {{ procedure.next_meeting.location }}
            </div>
          </div>
          <div v-else class="font-medium flex gap-2 items-center text-sm text-gray-500">
            <fw-icon-calendar class="w-5 h-5 opacity-50" />
            {{ $t('noMeetingsScheduled') }}
          </div>
        </div>
      </div>
    </fw-panel>

    <fw-panel :title="$t('people')" class="my-5">
      <div class="flex justify-items-stretch gap-3">
        <div class="bg-white rounded-xl px-4 py-2 flex-1">
          <fw-label>{{ $t('manager') }}</fw-label>
          <Person :no-style="true" :person="procedure.manager"></Person>
        </div>
        <div class="bg-white rounded-xl px-4 py-2 flex gap-10 flex-1">
          <div class="flex flex-col">
            <fw-label>{{ $t('president') }}</fw-label>
            <div v-if="procedure.presidents && procedure.presidents.length">
              <Person
                v-for="president in procedure.presidents"
                :key="president.key"
                :no-style="true"
                :person="president"
              ></Person>
            </div>
            <div v-else class="text-sm text-gray-500 flex-1 items-center justify-center flex">
              {{ $t('notDefined') }}
            </div>
          </div>
          <div class="flex flex-col">
            <fw-label>{{ $t('jury') }}</fw-label>
            <div v-if="procedure.vogals && procedure.vogals.length" class="flex gap-3">
              <b-tooltip
                v-for="vogal in procedure.vogals"
                :key="vogal.key"
                :label="vogal.full_name"
                type="is-dark"
                position="is-bottom"
              >
                <fw-avatar :user="vogal" class="my-2" />
              </b-tooltip>
            </div>
            <div v-else class="text-sm text-gray-500 flex-1 items-center justify-center flex">
              {{ $t('notDefined') }}
            </div>
          </div>
        </div>
        <div class="bg-white rounded-xl px-4 py-2 flex flex-col">
          <fw-label>{{ $t('candidates') }}</fw-label>
          <div class="text-lg font-semibold flex gap-2 items-center flex-1">
            <fw-icon-people class="w-6 h-6" /> {{ procedure.num_applications.submitted }}
          </div>
          <div class="text-sm text-gray-500">{{ $t('submittedApplications') }}</div>
        </div>
      </div>
    </fw-panel>

    <fw-panel
      v-if="procedure.notifications && procedure.status !== 'draft'"
      :title="$t('notifications')"
      boxed="sm"
      class="my-5"
    >
      <div class="grid grid-cols-2 md:grid-cols-5 gap-5 text-gray-500 font-medium">
        <div class="flex flex-col gap-1">
          <div class="flex-1 pr-3">
            <fw-label marginless>Abertura de candidaturas</fw-label>
          </div>
          <div>
            <div v-if="procedure.notifications['notification-publish']">
              {{ procedure.notifications['notification-publish'] | formatDateTime }}
            </div>
            <div v-else class="text-xs text-gray-400">{{ $t('notificationNotSent') }}</div>
          </div>
        </div>
        <div class="flex flex-col gap-1">
          <div class="flex-1 pr-3">
            <fw-label marginless>Abertura da fase de avaliação (ao júri)</fw-label>
          </div>
          <div>
            <div v-if="procedure.notifications['notification-start']">
              {{ procedure.notifications['notification-start'] | formatDateTime }}
            </div>
            <div v-else class="text-xs text-gray-400">{{ $t('notificationNotSent') }}</div>
          </div>
        </div>
        <div v-if="procedure.with_interview" class="flex flex-col gap-1">
          <div class="flex-1 pr-3">
            <fw-label marginless>Calendarização de entrevistas (candidatos selecionados)</fw-label>
          </div>
          <div>
            <div v-if="procedure.notifications['notification-interview']">
              {{ procedure.notifications['notification-interview'] | formatDateTime }}
            </div>
            <div v-else class="text-xs text-gray-400">{{ $t('notificationNotSent') }}</div>
          </div>
        </div>
        <div class="flex flex-col gap-1">
          <div class="flex-1 pr-3">
            <fw-label marginless>Resultados finais</fw-label>
          </div>
          <div>
            <div v-if="procedure.notifications['notification-results-final']">
              {{ procedure.notifications['notification-results-final'] | formatDateTime }}
            </div>
            <div v-else class="text-xs text-gray-400">{{ $t('notificationNotSent') }}</div>
          </div>
        </div>
      </div>
    </fw-panel>

    <fw-panel
      v-if="Boolean(myJuryInfo)"
      :title="$t('declarationsPanelTitle')"
      boxed="xs"
      custom-class="bg-white"
      class="my-5"
    >
      <div class="bg-white rounded-xl px-4 py-2 flex-1">
        <div class="flex gap-2 items-center">
          <fw-icon-info class="w-4 h-4 text-gray-500" />
          <fw-label>{{ $t('declarationConflitOfInterest') }}</fw-label>
        </div>
        <div class="flex items-center justify-between gap-2">
          <div>
            <div v-if="myJuryInfo.no_conflict_of_interest" class="text-primary">
              {{ $t('noConflitOfInterests') }}
            </div>
            <div v-else-if="myJuryInfo.no_conflict_of_interest == false">{{ $t('hasConflitOfInterests') }}</div>
            <div v-else class="text-sm text-gray-500">{{ $t('undefinedConflitOfInterests') }}</div>
          </div>
          <div>
            <fw-button type="link" @click.native="openModal('conflictOfInterest')">Abrir declaração</fw-button>
          </div>
        </div>
      </div>
    </fw-panel>

    <b-modal
      :active="Boolean(activeModal)"
      scroll="keep"
      :can-cancel="true"
      trap-focus
      :destroy-on-hide="true"
      aria-role="dialog"
      aria-modal
      :width="700"
      :on-cancel="closeModal"
      :custom-class="'rounded-buefy-modal'"
    >
      <div v-if="activeModal === 'statusInfo' && activeStatusInfo" class="h-full flex flex-col p-5 gap-5">
        <fw-panel>
          <div class="flex flex-col gap-2">
            <fw-heading size="h2">
              <span class="font-medium text-gray-500">{{ $t('status') }} </span> {{ activeStatusInfo.meta.label }}
            </fw-heading>
            <div>
              <div v-html="$t(`activeStatusInfoText.${activeStatusInfo.key}`)"></div>

              <div class="text-sm mt-5 pl-5">
                <ul class="flex flex-col gap-2">
                  <li v-for="item in procedureStatusChecks[activeStatusInfo.key]" :key="item.$index" class="flex gap-2">
                    <fw-icon-check
                      class="w-5 h-5 text-white rounded-full p-0.5"
                      :class="item.valid ? 'bg-primary' : 'bg-gray-300'"
                    />
                    <p>{{ item.text }}</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </fw-panel>
        <div class="flex items-center justify-end gap-5">
          <fw-button type="link-muted" @click.native="closeModal">{{ $t('close') }}</fw-button>
        </div>
      </div>
      <ConflitOfInterestModal
        v-else-if="activeModal === 'conflictOfInterest'"
        :procedure="procedure"
        :my-jury-info="myJuryInfo"
        :can-edit="canEditConflitOfInterest"
        @edit-no-conflict-of-interest="editNoConflictOfInterest"
        @close="closeModal"
      />
    </b-modal>
  </div>
</template>

<script>
import Person from '@/fw-modules/fw-core-vue/ui/components/cards/Person'
import DashboardHero from '@/fw-modules/fw-core-vue/ui/components/dashboard/DashboardHero'

import ConflitOfInterestModal from '@/components/modals/ConflitOfInterestModal'

export default {
  components: {
    Person,
    DashboardHero,
    ConflitOfInterestModal,
  },

  props: {
    procedureStatus: {
      type: Object,
      default: () => {
        return []
      },
    },
    procedure: {
      type: Object,
      default: () => {
        return {}
      },
    },
    procedureStatusChecks: {
      type: Object,
      default: () => {
        return {}
      },
    },

    myJuryInfo: {
      type: Object,
    },

    canEditConflitOfInterest: {
      type: Boolean,
      default: false,
    },

    canDeleteProcedure: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      activeStatusInfo: null,
      activeModal: null,
    }
  },

  computed: {
    isProcedureTypeScholarship() {
      return this.procedure !== null && this.procedure.type == 'scholarships'
    },

    me() {
      return this.$store.state.session && this.$store.state.session.user ? this.$store.state.session.user : null
    },
  },

  methods: {
    openModal(modal) {
      this.activeModal = modal
    },

    openStatusInfoModal(data) {
      this.activeModal = 'statusInfo'
      this.activeStatusInfo = data
    },

    closeModal() {
      this.activeModal = null
      this.activeStatusInfo = null
    },

    editNoConflictOfInterest(accept) {
      this.$emit('edit-no-conflict-of-interest', accept)
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "notificationNotSent": "Notificação ainda não enviada.",
    "closed": "fechadas",
    "status": "Estado",
    "ready": "Pronto",
    "statusInfo": "Os estados são sequênciais e só podem ser alterados a partir de ações (disponíveis no lado direito neste cabeçalho)",
    "statusMainBoardTitle": "Estado",
    "statusBoardTitle": "Estados do procedimento",
    "metadataResume": "Metadados principais",
    "scholarshipsNumber": "Número de lugares",
    "seatsNumber": "Número de lugares",
    "scholarships": "bolsa(s)",
    "scholarship": "Bolsa",
    "seats": "seats",
    "notDefined": "Não definido.",
    "selectionMethods": "Métodos de seleção",
    "curriculumEval": "Avaliação Curricular",
    "interview": "Entrevista",
    "procedureCode": "Código do procedimento",
    "phaseOngoing": "A decorrer",
    "phasePending": "Pendente",
    "phaseAvailable": "Disponível",
    "phaseFinished": "Terminado",
    "people": "Pessoas",
    "calendar": "Calendarização",
    "close": "Fechar",
    "meetingGoals": {
      "jury": "Definição do júri",
      "criteria": "Definição de critérios",
      "admission": "Admissão de candidatos",
      "complaints": "Audiência(s) de interessados",
      "seriation-curriculum": "Avaliação curricular",
      "seriation-interview": "Entrevista a candidatos",
      "publicHearing": "Audição pública"
    },
    "candidates": "Candidatos",
    "applications": "Candidaturas",
    "nextMeeting": "Próxima reunião agendada",
    "noMeetingsScheduled": "Sem reuniões agendadas",
    "notifications": "Notificações",
    "manager": "Secretariado",
    "president": "Presidente",
    "jury": "Júri",
    "submittedApplications": "candidaturas submetidas",
    "declarationsPanelTitle": "Declarações",
    "declarationConflitOfInterest": "Declaração de inexistência de conflitos de interesse",
    "hasConflitOfInterests": "Declarou que tem conflitos de interesse neste procedimento.",
    "noConflitOfInterests": "Declarou que não tem conflitos de interesse neste procedimento.",
    "undefinedConflitOfInterests": "Ainda não indicou se tem conflitos de interesse neste procedimento. Por favor, aceda à área de avaliação para responder.",
    "activeStatusInfoText": {
      "draft": "O estado <b>Rascunho</b> representa o momento antes do inicio do procedimento, onde são definidos todos os metadados, pessoas associadas e calendarização da fase de candidatura e de avaliação.<br>O estado seguinte, <b>Publicado</b>, apenas ficará disponível após verificação dos seguintes requisitos:",
      "published": "O estado <b>Publicado</b> representa a fase inicial do procedimento. É nesta fase que o concurso se torna visível para o público, que pode consultar os detalhes do mesmo e candidatar-se à oferta.<br>Existindo qualquer candidatura válida, o estado seguinte, <b>Júri</b>, estará disponível assim que o período de candidaturas termine. Se não existirem candidaturas, o processo não transita para o estado Júri, pelo que o estado seguinte será <b>Encerrado</b>.<br>Em ambos os casos o processo apenas transita de estado quando as candidaturas estiverem terminadas:",
      "jury": "O estado <b>Júri</b> existirá sempre que um procedimento tiver candidatura(s), ficando disponível após o término desta(s).<br>No decorrer deste estado serão realizados os aspetos relacionados com o agendamento das reuniões, o processo de recrutamento e avaliação das candidaturas e correspondentes notificações associadas (por exemplo, audiência de interessados e convocatórias), até à proposta de decisão do júri.",
      "ended": "O estado <b>Audiência(s) de interessados</b> poderá estar ativo dois momentos distintos, ao longo do procedimento. Caso haja apenas Avaliação curricular, o momento será único porque a existirem candidatos exluidos, os mesmos receberão essa notificação ao mesmo tempo que os candidatos avaliados recebem a divulgação dos resultados.<br>Caso o procedimento tenha, para além da avaliação curricular, entrevista, na eventualidade de existirem candidatos excluídos, os mesmos irão conseguir apresentar pronúncia assim que sejam informados da exclusão e para todos os que sejam avaliados, a possibilidade de reclamar só surgirá assim que termine a fase avaliativa e sejam informados dos resultados da mesma.",
      "closed": "O estado <b>Encerrado</b> determina o fim do procedimento na plataforma. Um procedimento só poderá passar para este estado depois de terminar o prazo legal de pronúncia e, na eventualidade de terem existido, caso se verifique que todas as alegações foram alvo de resposta."
    }
  },
  "en": {
    "notificationNotSent": "Notification not sent yet.",
    "closed": "closed",
    "status": "Status",
    "ready": "Ready",
    "statusInfo": "The statuses are sequential and can only be changed from actions (available on the right side in this header)",
    "statusMainBoardTitle": "Status",
    "statusBoardTitle": "Procedure phases resume",
    "metadataResume": "Metadada (main)",
    "scholarshipsNumber": "Número de lugares",
    "seatsNumber": "Number of scholarships",
    "scholarships": "scholarships",
    "scholarship": "Scholarship",
    "seats": "lugar(es)",
    "notDefined": "Not defined",
    "selectionMethods": "Selection methods",
    "curriculumEval": "Curriculum seriation",
    "interview": "Interview",
    "procedureCode": "Procedure Code",
    "phaseOngoing": "Ongoing",
    "phasePending": "Pending",
    "phaseAvailable": "Available",
    "phaseFinished": "Finished",
    "people": "People",
    "calendar": "Calendar",
    "close": "Close",
    "meetingGoals": {
      "jury": "Jury Definition",
      "criteria": "Criteria Definition",
      "admission": "Candidate Admission",
      "complaints": "Stakeholder Hearing",
      "seriation-curriculum": "Curriculum Seriation",
      "seriation-interview": "Candidate Interviews",
      "publicHearing": "Public Hearing"
    },
    "candidates": "Candidates",
    "applications": "Applications",
    "nextMeeting": "Next scheduled meeting",
    "noMeetingsScheduled": "No scheduled meetings",
    "notifications": "Notifications",
    "manager": "Manager",
    "president": "President",
    "jury": "Jury",
    "submittedApplications": "submitted applications",
    "declarationsPanelTitle": "Declarations",
    "declarationConflitOfInterest": "Declaration of no conflicts of interest",
    "hasConflitOfInterests": "Has conflicts of interest",
    "noConflitOfInterests": "No conflicts of interest",
    "undefinedConflitOfInterests": "No response yet",
    "activeStatusInfoText": {
      "draft": "The <b>Draft</b> state represents the moment before the start of the procedure, where all metadata, associated people and timing of the application and evaluation phase are defined.<br>The next status, <b>Published</b>, will only be available after checking the following requirements:",
      "published": "The <b>Published</b> state represents the initial phase of the procedure. It is at this stage that the procedure becomes visible to the public, who can consult its details and apply for the offer.<br>If there is any valid application, the next state, <b>Jury</b>, will be available as soon as the application period ends. If there are no candidacies, the process does not move to the Jury status, so the next status will be Closed.<br>In both cases, the process only changes state when the applications are completed",
      "jury": "The <b>Jury</b> state will exist whenever a procedure has candidacy, becoming available after the end of these.<br>During this state, aspects related to the scheduling of meetings, the process of recruitment and evaluation of applications and corresponding associated notifications (for example, hearing of interested parties and invitations) will be carried out, up to the proposal for a decision by the jury.<br>The next status, <b>Stakeholder Hearing</b>, will be available if the following requirements are met:",
      "ended": "The <b>Stakeholder Hearing</b> status may be active at two different times throughout the procedure. If there is only Curricular Evaluation, the moment will be unique because if there are candidates who are excluded, they will receive this notification at the same time that those who were evaluated receive the results.<br><br>If the procedure has, in addition to the curricular evaluation, an interview, and if there are candidates who are excluded, they will be able to submit a statement as soon as they are informed of the exclusion, and for all those who are evaluated, the possibility to complain will only arise as soon as the evaluation phase ends and they are informed of the results. ",
      "closed": "The <b>Closed</b> status determines the end of the procedure on the platform. A procedure can only move to this status after the end of the legal deadline for the Stakeholder Hearing and, if there have been any, if it is verified that all allegations have been answered."
    }
  }
}
</i18n>
