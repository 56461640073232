<template>
  <section v-if="procedure">
    <fw-heading size="xl">{{ $t('people') }}</fw-heading>

    <fw-panel
      v-if="view === 'settings'"
      :title="$t('procedureManager')"
      boxed="xs"
      custom-class="bg-white"
      class="mt-5 mb-6"
      counter="1"
    >
      <template #toolbar>
        <fw-button v-if="canChangeSecretariat" type="link" @click.native="choosePeople('manager')">{{
          $t('edit')
        }}</fw-button>
      </template>
      <div>
        <Person
          :key="people.manager.key"
          :person="people.manager"
          :open-details-on-click="canOpenUserDetails"
          :selectable="false"
          :clickable="canOpenUserDetails"
        >
        </Person>
      </div>
    </fw-panel>

    <PanelProcedurePeopleJury
      v-if="view === 'jury'"
      :people.sync="people"
      :can-edit="canEdit"
      @choose-people="choosePeople"
      @invite-person="invitePerson"
      @update-vogals="updateVogals"
      @delete-vogal="deleteVogal"
      @update-presidents="updatePresidents"
      @delete-president="deletePresident"
    >
    </PanelProcedurePeopleJury>

    <fw-panel
      v-if="view === 'candidates'"
      :title="$t('candidates')"
      boxed="xs"
      custom-class="bg-white"
      class="my-5"
      :counter="applications.length"
    >
      <template #toolbar>
        <CandidatesInfoDownload
          v-if="allowDownloadCandidatesZip"
          :downloading="downloadingFiles"
          @download="downloadCandidates"
        />
      </template>

      <fw-panel-info v-if="!applications.length" centered clean class="my-3">
        {{ ['draft', 'published'].includes(procedure.status) ? $t('notYet') : $t('no') }} {{ $t('noVogals') }}
      </fw-panel-info>

      <RecycleScroller
        v-if="applications.length > 0"
        v-slot="{ item }"
        key-field="candidate_key"
        :items="applications"
        :item-size="57"
        :buffer="50"
      >
        <Person
          :person="item"
          :selectable="false"
          :clickable="true"
          class="flex-1 border-b border-gray-100 p-1"
          @click.native="openApplication(item.candidate_key)"
        >
          <template #options>
            <div class="flex items-end gap-1">
              <div class="text-sm font-semibold min-w-24">
                <fw-label size="xs" marginless>{{ $t('application') }}</fw-label>
                <div v-if="item.application_status === 'submitted'" class="text-primary">
                  {{ $t('applicationsSubmitted') }}
                </div>
                <div v-else class="text-gray-500">{{ $t('applicationsCancelled') }}</div>
              </div>
              <div class="w-5 hidden md:flex">
                <fw-icon-check
                  v-if="item.application_status === 'submitted'"
                  class="w-5 h-5 text-primary flex-shrink-0"
                />
              </div>
            </div>
          </template>
        </Person>
      </RecycleScroller>
    </fw-panel>

    <fw-panel-info debug label="People data (raw)" class="my-5">
      <json-viewer :value="{ people, applications }"></json-viewer>
    </fw-panel-info>

    <b-modal
      :active="!!choosingPeopleType"
      scroll="keep"
      :can-cancel="true"
      trap-focus
      :destroy-on-hide="true"
      aria-role="dialog"
      aria-modal
      :width="700"
      :on-cancel="closeModal"
      :custom-class="'rounded-buefy-modal'"
    >
      <ChooseSecretariatModal
        v-if="choosingPeopleType === 'manager'"
        :current-secretariat="people.manager"
        :not-alowed-users="notAlowedUsers"
        @selected="selectedPeople"
        @close="closeModal"
      ></ChooseSecretariatModal>
      <ChoosePeopleModal
        v-else-if="choosingPeopleType"
        :title="selectPeopleTitle"
        :instructions="selectPeopleInstructions"
        :multiselect="multiselectPeople"
        :not-alowed-users="notAlowedUsers"
        :invite-people="canInvitePeople"
        :endpoint="endpointSearch"
        @save-invite="invitePerson"
        @selected="selectedPeople"
        @close="closeModal"
      ></ChoosePeopleModal>
    </b-modal>
  </section>
</template>

<script>
import { RecycleScroller } from 'vue-virtual-scroller'
import Person from '@/fw-modules/fw-core-vue/ui/components/cards/Person'
import ChoosePeopleModal from '@/fw-modules/fw-core-vue/ui/components/modals/ChoosePeopleModal'
import ChooseSecretariatModal from '@/components/modals/ChooseSecretariatModal'
import PanelProcedurePeopleJury from '@/components/panels/PanelProcedurePeopleJury'
import CandidatesInfoDownload from '@/components/buttons/CandidatesInfoDownload'

export default {
  components: {
    RecycleScroller,
    Person,
    ChoosePeopleModal,
    ChooseSecretariatModal,
    PanelProcedurePeopleJury,
    CandidatesInfoDownload,
  },

  props: {
    procedure: {
      type: Object,
      default: () => {
        return {}
      },
    },

    isValid: {
      type: Boolean,
    },

    canInvitePeople: {
      type: Boolean,
      default: false,
    },

    canEdit: {
      type: Boolean,
      default: true,
    },

    view: {
      type: String,
      default: 'metadata',
    },

    canChangeSecretariat: {
      type: Boolean,
      default: false,
    },

    savingData: {
      type: Boolean,
      default: false,
    },

    downloadingFiles: {
      type: Boolean,
      default: false,
    },

    allowDownloadCandidatesZip: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      people: {
        presidents: [],
        vogals: {
          effective: [],
          substitute: [],
        },
        manager: {},
      },
      maxVogals: 8, // this will be different for each procedure type
      choosingPeopleType: null,
      currentSelectedPeople: [],
      usersList: [],
    }
  },

  computed: {
    api() {
      return this.$store.state.api.base
    },

    selectPeopleTitle() {
      let titlesMap = {
        manager: this.$t('chooseManager'),
        presidents: this.$t('choosePresidente'),
        vogals: this.$t('chooseVogals'),
        'effective-vogals': this.$t('chooseEffectiveVogals'),
        'substitute-vogals': this.$t('chooseSubstituteVogals'),
      }
      return titlesMap[this.choosingPeopleType] ? titlesMap[this.choosingPeopleType] : this.$t('choosePerson')
    },

    selectPeopleInstructions() {
      let instructionsMap = {
        manager: '',
        presidents: this.$t('selectPresidente'),
        vogals: this.$t('selectVogals'),
        'effective-vogals': this.$t('selectEffectiveVogals'),
        'substitute-vogals': this.$t('selectSubstituteVogals'),
      }
      return instructionsMap[this.choosingPeopleType] ? instructionsMap[this.choosingPeopleType] : this.$t('selectUser')
    },

    multiselectPeople() {
      return this.choosingPeopleType === 'effective-vogals' ||
        this.choosingPeopleType === 'vogals' ||
        this.choosingPeopleType === 'substitute-vogals' ||
        this.choosingPeopleType === 'advisors'
        ? true
        : false
    },

    notAlowedUsers() {
      let userKeys = this.people.vogals.effective
        .concat(this.people.presidents)
        .concat(this.people.vogals.substitute)
        .map(el => el.key)

      userKeys.push(this.people.manager.key)

      return userKeys
    },

    haveRequiredPresident() {
      // We need one president
      return !!this.people.presidents
    },

    haveRequiredEffectiveVogals() {
      // We need at least two effective vogals
      return (
        this.people.vogals.effective &&
        this.people.vogals.effective.length > 1 &&
        this.totalVogalsMembers <= this.maxVogals
      )
    },

    haveRequiredSubstituteVogals() {
      // Not required
      return this.totalVogalsMembers <= this.maxVogals
    },

    totalVogalsMembers() {
      return this.people.vogals.effective.length + this.people.vogals.substitute.length
    },

    // Roles
    userRoles() {
      return this.$store.getters.userPermissions
    },

    canOpenUserDetails() {
      return this.userRoles.isProcedureManager || this.userRoles.isAdmin || this.userRoles.isManager
    },
  },

  watch: {
    'procedure.vogals'(newValue) {
      this.people.vogals = {
        effective: newValue.filter(el => !el.as_president_substitute),
        substitute: newValue.filter(el => el.as_president_substitute),
      }
    },
    'procedure.presidents'(newValue) {
      this.people.presidents = newValue
    },
  },

  created() {
    this.people = {
      presidents: this.procedure.presidents,
      vogals: {
        effective: this.procedure.vogals ? this.procedure.vogals.filter(el => !el.as_president_substitute) : [],
        substitute: this.procedure.vogals ? this.procedure.vogals.filter(el => el.as_president_substitute) : [],
      },
      manager: this.procedure.manager,
    }

    this.applications = this.procedure.applications
  },

  methods: {
    downloadCandidates(option = 'files') {
      if (this.allowDownloadCandidatesZip) {
        this.$emit('download-candidates', option)
      }
    },

    async endpointSearch(params) {
      return await this.api.getUsers(params)
    },

    choosePeople(type) {
      this.choosingPeopleType = type
      this.currentSelectedPeople = []

      if (type === 'effective-vogals') {
        this.currentSelectedPeople = this.people.vogals.effective.map(el => el.key)
      } else if (type === 'substitute-vogals') {
        this.currentSelectedPeople = this.people.vogals.substitute.map(el => el.key)
      } else if (type === 'manager') {
        this.currentSelectedPeople = [this.people.manager]
      } else if (type === 'presidents') {
        this.currentSelectedPeople = this.people.presidents.map(el => el.key)
      }
    },

    invitePerson(user, roles, type) {
      console.log('panel people :>> ', user, roles, type)
      this.$emit('invite-person', user, roles, type)
    },

    selectedPeople(selection, extra = {}) {
      switch (this.choosingPeopleType) {
        case 'manager':
          this.updateManager(selection, extra)
          break
        case 'effective-vogals':
          this.updateVogals(selection, false)
          break
        case 'substitute-vogals':
          this.updateVogals(selection, true)
          break
        case 'presidents':
          this.updatePresidents(selection)
          break
        default:
          console.log('not a type')
      }
    },

    updateManager(selection, transferData = {}) {
      this.people.manager = selection
      this.$emit('update-manager', selection, transferData)
      this.closeModal()
    },

    updateVogals(users, substitute = false) {
      const newUsersData = users.map(user => {
        return {
          key: user.key,
          as_president_substitute: substitute,
        }
      })

      const effectiveVogalsData = this.people.vogals.effective.map(user => {
        return {
          key: user.key,
          as_president_substitute: false,
        }
      })

      if (!substitute && users.length + effectiveVogalsData.length > this.maxVogals) {
        console.log('We have reached max number of allowed vogals.')
        this.$buefy.dialog.alert({
          title: this.$t('maxVogalsNum.title'),
          message: this.$t('maxVogalsNum.message', { max: this.maxVogals }),
          type: 'is-dark',
          ariaRole: 'alertdialog',
          ariaModal: true,
        })
        return
      }

      const substituteVogalsData = this.people.vogals.substitute.map(user => {
        return {
          key: user.key,
          as_president_substitute: true,
        }
      })

      this.$emit('update-members', newUsersData.concat(effectiveVogalsData).concat(substituteVogalsData))
    },

    deleteVogal(key) {
      const members = this.people.vogals.substitute
        .concat(this.people.vogals.effective)
        .filter(el => el.key != key)
        .map(user => {
          return {
            key: user.key,
            as_president_substitute: true,
          }
        })

      this.$emit('update-members', members)
    },

    deletePresident(key) {
      console.log('deletePresident :>> ', key)

      this.$emit('update-presidents', null, null)
    },

    updatePresidents(selection) {
      let presidentSubtitute = selection.find(user => user.substitute)
      let president = selection.find(user => !user.substitute)
      president = president
        ? {
            key: president.key,
          }
        : null
      presidentSubtitute = presidentSubtitute
        ? {
            key: presidentSubtitute.key,
          }
        : null

      this.$emit('update-presidents', president, presidentSubtitute)
    },

    openApplication(candidateKey) {
      this.$emit('open-application', candidateKey)
    },

    closeModal() {
      this.choosingPeopleType = null
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "candidates": "Candidatos",
    "application": "Candidatura",
    "people": "Pessoas",
    "edit": "Alterar",
    "juryMembers": "Elementos do júri",
    "procedureManager": "Gestor/a do procedimento",
    "president": "Presidente",
    "addPresident": "Adicionar Presidente",
    "presidentRequired": "Ainda não foi definido um presidente. Para avançar, precisa associar uma pessoa neste cargo.",
    "effectiveVogals": "Vogais efetivos",
    "addEffectiveVogals": "Adicionar Vogais efetivos",
    "effectiveVogalsRequired": "Ainda não foram associados vogais efectivos. Para avançar, precisa associar pelo menos duas pessoas pessoa neste cargo.",
    "substituteVogals": "Vogais suplentes",
    "addSubstituteVogals": "Adicionar Vogais suplentes",
    "noSubstituteVogals": "Ainda não foram associados foram associados vogais suplentes. Para avançar, precisa associar pelo menos uma pessoa pessoa neste cargo.",
    "maxVogals": "Caso pretenda associar membros, como o número máximo de Vogais foi atingido, por favor, remova um membro da lista de vogais efetivos.",
    "delete": "Remover",
    "notYet": "Ainda não",
    "no": "Não",
    "noVogals": "existem candidatos associados ao procedimento.",
    "applicationsSubmitted": "Submetida",
    "applicationsCancelled": "Cancelada",
    "chooseManager": "Escolha o secretariado",
    "choosePresidente": "Escolha o presidente",
    "chooseVogals": "Escolha o(s) vogal/vogais",
    "chooseEffectiveVogals": "Escolha o(s) vogal/vogais efectivo(s)",
    "chooseSubstituteVogals": "Escolha o(s) vogal/vogais suplente(s)",
    "choosePerson": "Escolher pessoa",
    "selectPresidente": "Selecione o utilizador que quer como presidente:",
    "selectVogals": "Selecione o(s) utilizador(es) que quer como vogal/vogais:",
    "selectEffectiveVogals": "Selecione o(s) utilizador(es) que quer como vogal/vogais efectivo(s):",
    "selectSubstituteVogals": "Selecione o(s) utilizador(es) que quer como vogal/vogais suplente(s):",
    "selectUser": "Seleccione um utilizador",
    "hasConflitOfInterests": "Com conflitos de interesse",
    "noConflitOfInterests": "Sem conflitos de interesse",
    "undefinedConflitOfInterests": "Sem resposta",
    "maxVogalsNum": {
      "title": "Número de vogais acima do permitido",
      "message": "A cada procedimento deve apenas associar <strong>{max} vogais</strong> (efetivos + suplentes). Para atualizar a lista de membros, por favor, remova pelo menos uma pessoa da lista de vogais."
    }
  },
  "en": {
    "candidates": "Candidates",
    "application": "Application",
    "people": "People",
    "edit": "Edit",
    "juryMembers": "Jury members",
    "procedureManager": "Procedure manager",
    "president": "President",
    "addPresident": "Add President",
    "presidentRequired": "A president has not yet been defined. To move forward, it needs to associate a person in this position.",
    "effectiveVogals": "Effective vogals",
    "addEffectiveVogals": "Adicionar Effective vogals",
    "effectiveVogalsRequired": "No effective vogals have been associated yet. To move forward, you need to associate at least two people in this position.",
    "substituteVogals": "Substitute vogals",
    "addSubstituteVogals": "Add Vogal Substitute",
    "noSubstituteVogals": "No substitute vogals have been associated yet. To move forward, you need to associate at least one person in this position.",
    "maxVogals": "If you want to associate members, as the maximum number of Vogals has been reached, please remove a member from the list of Effective vogals.",
    "delete": "Remove",
    "notYet": "No",
    "no": "No",
    "noVogals": "candidates associated with the procedure.",
    "applicationsSubmitted": "Submitted",
    "applicationsCancelled": "Cancelled",
    "chooseManager": "Choose the secretariat",
    "choosePresidente": "Choose the president",
    "chooseVogals": "Choose the vogal(s)",
    "chooseEffectiveVogals": "Choose the effective vogal(s)",
    "chooseSubstituteVogals": "Choose the substitute vogal(s)",
    "choosePerson": "Choose person",
    "selectPresidente": "Select the user you want as president:",
    "selectVogals": "Select the user(s) you want as vogals:",
    "selectEffectiveVogals": "Select the user(s) you want as the effective vogals:",
    "selectSubstituteVogals": "Select the user(s) you want as substitute vogals:",
    "selectUser": "Select a user",
    "hasConflitOfInterests": "Has conflicts of interest",
    "noConflitOfInterests": "No conflicts of interest",
    "undefinedConflitOfInterests": "No response",
    "maxVogalsNum": {
      "title": "Number of vogals above allowed",
      "message": "To each procedure you should only associate <strong>{max} vogals</strong> (effective vogals + substitute). To update the list of vogals, please remove at least one person from the list of vogals."
    }
  }
}
</i18n>
