<template>
  <div v-if="entry" :class="{ 'p-1 border-b border-gray-100': row }">
    <div class="relative rounded-lg px-3 py-1.5 hover:bg-gray-200 hover:bg-opacity-50 group w-full text-left">
      <div class="flex gap-3 items-center">
        <a class="flex flex-col flex-1 gap-2" :class="{ 'p-1.5': !canEdit }" @click="editUpdate(entry)">
          <div class="flex-1 flex justify-between items-center gap-3">
            <div class="w-24 flex items-center">
              <fw-tag v-if="entry.is_private" size="sm" type="light">
                <fw-icon-lock-line class="w-4 h-4 mr-1" /> {{ $t('private') }}
              </fw-tag>
              <fw-tag v-else size="sm" type="primary">
                <fw-icon-globe class="w-4 h-4 mr-1" /> {{ $t('public') }}
              </fw-tag>
            </div>
            <div class="flex-1 flex flex-col gap-0.5">
              <v-clamp class="font-medium" autoresize :max-lines="1">{{ entry.title[language] }}</v-clamp>
            </div>
            <div class="text-sm flex-shrink-0">
              <fw-tag type="xlight">
                {{
                  entry.type == 'curriculum' && !procedure.with_interview
                    ? $t(`updateTypes.curriculumLUOF`)
                    : $t(`updateTypes.${entry.type}`)
                }}
              </fw-tag>
            </div>
            <div class="text-gray-500 group-hover:text-gray-800 text-sm flex-shrink-0">
              {{ entry.publish_date | formatDateTime }}
            </div>
          </div>
        </a>
        <div v-if="canEdit">
          <b-dropdown aria-role="list" position="is-bottom-left">
            <fw-button-dropdown slot="trigger" :chevron="false" type="xlight" class="flex flex-col" label="Ações">
              <fw-icon-more class="w-5 h-5" />
            </fw-button-dropdown>
            <b-dropdown-item custom aria-role="menuitem" class="paddingless">
              <fw-button type="basic-action" size="sm" custom-class="w-full" @click.native="editUpdate(entry, true)">
                <template #icon>
                  <fw-icon-edit class="w-5 h-5" />
                </template>
                {{ $t('edit') }}
              </fw-button>
            </b-dropdown-item>

            <b-dropdown-item v-if="canDelete" custom aria-role="menuitem" class="paddingless">
              <fw-button
                type="basic-action"
                size="sm"
                custom-class="w-full"
                @click.native="deleteUpdateModal(entry, entryKey)"
              >
                <template #icon>
                  <fw-icon-trash class="w-5 h-5" />
                </template>
                {{ $t('delete') }}
              </fw-button>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
    </div>
    <b-modal
      v-if="editingUpdate"
      :active="activeModal == 'new_update'"
      scroll="keep"
      :can-cancel="true"
      trap-focus
      :destroy-on-hide="true"
      aria-role="dialog"
      aria-modal
      :width="700"
      :on-cancel="closeModal"
      :custom-class="'rounded-buefy-modal'"
    >
      <ModalProcedureUpdateEntry
        :procedure-key="procedure.key"
        :data="editingUpdate"
        :can-edit="canEdit"
        :procedure="procedure"
        :open-as-editing="openEditing"
        class="min-h-full"
        @edited-update="editedUpdate"
        @close="closeModal"
      ></ModalProcedureUpdateEntry>
    </b-modal>
  </div>
</template>

<script>
import ModalProcedureUpdateEntry from '@/components/modals/ModalProcedureUpdateEntry'

export default {
  name: 'CardUpdateEntry',
  components: {
    ModalProcedureUpdateEntry,
  },

  props: {
    entryKey: {
      type: String,
      default: null,
    },
    entry: {
      type: Object,
      default: function() {
        return {}
      },
    },
    procedure: {
      type: Object,
      require: true,
    },
    row: {
      type: Boolean,
      default: true,
    },
    canEdit: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      activeModal: null,
      editingUpdate: null,
      openEditing: false,
    }
  },

  computed: {
    language() {
      return this.$store.state.language
    },
    secondaryLanguage() {
      return this.language === 'pt' ? 'en' : 'pt'
    },
    canDelete() {
      return this.entry.validations.can_remove
    },
  },

  methods: {
    editedUpdate(data) {
      let update = this.entry
      console.log('update :>> ', update)
      if (update) {
        update['files'] = data.files
        update['title'] = data.title
        update['description'] = data.description
        update['is_private'] = data.is_private
        update['publish_date'] = data.publish_date
        update['type'] = data.type
      }
      this.$emit('edited-update')
    },
    deleteUpdateModal(update, u) {
      this.$buefy.dialog.confirm({
        title: this.$t('deleteUpdate.title'),
        message: this.$t('deleteUpdate.message'),
        confirmText: this.$t('delete'),
        cancelText: this.$t('cancel'),
        type: 'is-danger',

        onConfirm: () => {
          this.$emit('delete-update', update, u)
        },
      })
    },
    closeModal() {
      this.activeModal = null
    },
    editUpdate(data, editing = false) {
      this.openEditing = editing
      this.editingUpdate = data
      this.activeModal = 'new_update'
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "public": "Pública",
    "private": "Privada",
    "candidates": "Candidatos",
    "edit": "Editar",
    "delete": "Eliminar",
    "cancel": "Cancelar",
    "updateTypes": {
      "notice": "Edital",
      "curriculum": "Ata Admissão/exclusão + AC",
      "curriculumLUOF": "Ata Admissão/exclusão + AC + LUOF",
      "interview": "Ata Entrevista + LUOF",
      "other": "Outro"
    },
    "deleteUpdate": {
      "title":"Eliminar Atualização",
      "message": "Tem a certeza que quer <b>eliminar</b> a atualização?"
    }
  },
  "en": {
    "public": "Public",
    "private": "Private",
    "candidates": "Candidates",
    "edit": "Edit",
    "delete": "Delete",
    "cancel": "Cancel",
    "updateTypes": {
      "notice": "Edital",
      "curriculum": "Minutes of Admission/Exclusion + AC",
      "curriculumLUOF": "Minutes of Admission/Exclusion + AC + LUOF",
      "interview": "Minutes of Interview + LUOF",
      "other": "Other"
    },
    "deleteUpdate": {
      "title":"Delete Update",
      "message": "Are you sure you want to <b>delete</b> the update?"
    }
  }
}
</i18n>
