<template>
  <div v-if="evaluationMap" class="p-5 overflow-y-auto">
    <fw-panel
      title="Mapa de seriação individual"
      featured
      sticky
      sticky-bg-color="bg-white"
      :subtitle="subtitle"
      :loading="loading"
      after-loading-checked
    >
      <template #after-heading>
        <fw-tag v-if="mapIsOpen && !loading" type="xlight" size="sm"
          ><fw-icon-unlock-line class="h-4 w-4 mr-1" /> Aberto</fw-tag
        >
        <fw-tag v-else-if="!loading" type="light-primary" size="sm"
          ><fw-icon-lock-solid class="h-4 w-4 mr-1" /> Fechado</fw-tag
        >
      </template>
      <template v-if="canToggleMap" #toolbar>
        <div class="flex gap-2">
          <UsersConnected v-if="connectedUsers.length" :users="connectedUsers" />
          <fw-button
            v-if="mapIsOpen"
            :disabled="!canCloseMap"
            type="light-primary"
            @click.native="toggleMap(evaluationMap.key)"
          >
            Bloquear mapa</fw-button
          >
          <fw-button
            v-else-if="currentViewChecks.meeting_started && !mapIsOpen"
            type="light"
            :disabled="
              currentViewChecks.can_show_seriation ||
                !(currentViewChecks.jury && currentViewChecks.jury.includes(evaluationMap.key))
            "
            @click.native="toggleMap(evaluationMap.key)"
          >
            Desbloquear</fw-button
          >
        </div>
      </template>
      <template #default>
        <div class="mb-3 flex gap-2 text-sm items-center">
          <div class="text-gray-500 text-xs">Mapa de</div>
          <div>
            <fw-avatar :user="jury" size="2xs" class="inline-flex mr-1" />
            <span class="font-semibold">{{ jury.full_name }}</span>
          </div>
        </div>
        <div
          class="flex flex-col relative w-full items-center bg-gray-200 bg-opacity-50 mb-2 outline-none focus:ring-2 ring-primary ring-opacity-30 rounded-xl"
        >
          <div class="flex w-full items-center px-3 py-1.5">
            <div class="w-6 ml-1">
              <fw-icon-search class="w-6 h-6 opacity-50" />
            </div>
            <input
              v-model="mapSearchQuery"
              class="outline-none font-semibold flex-1 px-1 py-0.5 bg-transparent ml-1"
              type="search"
              placeholder="Pesquisar..."
              name="search"
            />
          </div>
        </div>
        <fw-panel-info v-if="editable && editMode" size="xs" clean centered class="mt-3">
          Insira valores entre entre 0 e 100 com um máximo 2 casas decimais.
        </fw-panel-info>
      </template>
    </fw-panel>

    <div>
      <div
        class="
          flex
          sticky
          top-0
          z-10
          bg-gray-50
          text-gray-500
          font-semibold
          pl-3
          pr-2
          text-sm
          py-2
          gap-3
          border-b border-gray-100
          items-end
        "
      >
        <div class="flex-1">Candidato</div>
        <div
          v-for="(criterium, c) in criteriaList"
          :key="`criterium_header_${c}`"
          class="flex-shrink-0 w-24 text-right"
          :title="criterium.name[language]"
        >
          <v-clamp autoresize :max-lines="2">{{ criterium.name[language] }}</v-clamp>
          <div class="text-gray-400 text-xs">0-100%</div>
        </div>
      </div>
      <div v-if="filteredCandidates.length">
        <RecycleScroller v-slot="{ item }" :items="filteredCandidates" :item-size="42" :buffer="50" key-field="key">
          <div class="flex gap-2 justify-between border-b hover:bg-gray-50 group">
            <div class="flex-1 flex items-center gap-2 py-1 pl-3">
              <div><fw-avatar size="xs" :user="item"></fw-avatar></div>
              <div>
                <v-clamp autoresize :max-lines="1" class="font-semibold group-hover:text-primary">{{
                  item.full_name
                }}</v-clamp>
                <v-clamp autoresize :max-lines="1" class="text-xs text-gray-500 break-all">{{ item.email }}</v-clamp>
              </div>
            </div>
            <div class="flex gap-3 justify-end py-1 px-2" @click.stop>
              <div
                v-for="(criterium, c) in criteriaList"
                :key="`criterium_value_${c}_${item.key}`"
                class="flex-shrink-0 w-24 flex justify-end items-center"
              >
                <NumberInput
                  v-if="editable && editMode"
                  :value="candidatesEvals[item.candidate_key][criterium.key]"
                  :class="{
                    error: candidatesEvalsErrors[item.candidate_key][criterium.key],
                  }"
                  placeholder="0-100%"
                  :min="0.0"
                  :max="100.0"
                  :step="0.01"
                  class="w-full"
                  @input="evalChanged($event, item.candidate_key, criterium.key)"
                />
                <span v-else class="font-medium text-gray-800">
                  {{
                    candidatesEvals[item.candidate_key][criterium.key] === null
                      ? 'Sem avaliação'
                      : candidatesEvals[item.candidate_key][criterium.key]
                  }}
                </span>
              </div>
            </div>
          </div>
        </RecycleScroller>
      </div>
      <div v-else-if="filteredCandidates.length === 0 && !loading" class="py-16 text-center text-gray-400">
        {{ mapSearchQuery.length > 0 ? 'Sem resultados' : 'Sem candidatos' }}
      </div>
    </div>

    <fw-panel v-if="filteredCandidates.length" class="mt-3">
      <fw-label>Fundamentação</fw-label>
      <b-input
        v-if="editable && editMode"
        v-model="reason"
        placeholder="Escreva o texto aqui..."
        rows="4"
        type="textarea"
      ></b-input>
      <div v-else class="whitespace-pre-line">
        {{ reason || 'Não definido' }}
      </div>
      <fw-tip v-if="reasonError" error>Insira uma fundamentação</fw-tip>
    </fw-panel>
    <div class="flex gap-3 items-center justify-end mt-5">
      <div
        v-if="editMode && (errors > 0 || generalError)"
        class="p-1 mx-5 text-red-500 flex items-center gap-1 text-sm font-medium"
      >
        <fw-icon-error-warning class="w-6 h-6"></fw-icon-error-warning>
        <span v-if="generalError" class="hidden lg:block">{{ generalError }}</span>
        <span v-else class="hidden lg:block">Existem erros no formulário</span>
      </div>
      <div v-if="editable" class="flex gap-3">
        <fw-button type="link-muted" @click.native="$emit('close')">Cancelar</fw-button>
        <fw-button v-if="editMode" :disabled="errors > 0" type="primary" @click.native="saveEvaluations()"
          >Guardar</fw-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import NumberInput from '@/fw-modules/fw-core-vue/ui/components/form/NumberInput'
import { RecycleScroller } from 'vue-virtual-scroller'
import UsersConnected from '@/fw-modules/fw-core-vue/ui/components/users/UsersConnected'

import utils from '@/fw-modules/fw-core-vue/utilities/utils'

export default {
  name: 'EvaluationMap',
  components: {
    NumberInput,
    RecycleScroller,
    UsersConnected,
  },
  props: {
    evaluationMap: {
      type: Object,
      default: null,
    },
    jury: {
      type: Object,
      default: function() {
        return {}
      },
    },
    criteriaList: {
      type: Array,
      default: function() {
        return []
      },
    },
    candidates: {
      type: Array,
      default: function() {
        return []
      },
    },
    presentCandidatesKeys: {
      type: Array,
      default: function() {
        return []
      },
    },
    currentViewChecks: {
      type: Object,
      default: function() {
        return {}
      },
    },
    editable: {
      type: Boolean,
      default: false,
    },
    canToggleMap: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    language: {
      type: String,
      default: 'pt',
    },
    view: {
      type: String,
      default: 'curriculum',
    },
    procedureKey: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      mapSearchQuery: '',
      errors: 0,
      generalError: null,
      editMode: true,
      candidatesEvals: {},
      candidatesEvalsErrors: {},
      evalSubscription: null,
      reason: null,
      reasonError: null,
    }
  },
  computed: {
    connectedUsers() {
      return this.$store.getters.getConnectedUsers[this.evaluationMap.key] || []
    },

    api() {
      return this.$store.state.api.base
    },

    subtitle() {
      return this.view == 'curriculum' ? 'Avaliação curricular' : 'Entrevista'
    },

    filteredCandidates() {
      let list = this.candidates

      if (this.mapSearchQuery.length > 0) {
        const find = utils.existsInSearchFactory(this.mapSearchQuery)
        return list.filter(person => {
          return find(person.full_name)
        })
      }
      return list
    },

    mapIsOpen() {
      return this.evaluationMap[`${this.view}_status`] != 'closed'
    },

    mapIsClosed() {
      return this.evaluationMap[`${this.view}_status`] == 'closed'
    },

    mapIsIncomplete() {
      return this.evaluationMap[`${this.view}_status`] == 'incomplete'
    },

    canCloseMap() {
      return (
        this.mapIsOpen &&
        !this.mapIsIncomplete &&
        this.currentViewChecks.jury &&
        this.currentViewChecks.jury.includes(this.evaluationMap.key) &&
        this.currentViewChecks.meeting_started &&
        this.presentCandidatesKeys.length == this.candidates.length
      )
    },
  },

  created() {
    this.$store.commit('setDirtyData', false)
    if (!this.evalSubscription) {
      this.evalSubscription = this.api.createProcSubscription(this.procedureKey, this.evaluationMap.key)
      window.addEventListener('beforeunload', this.beforeUnloadAction, { capture: true })
      console.log(`Evaluation map subscription (${this.view} - ${this.evaluationMap.key})`, this.evalSubscription)
    }

    const len = this.candidates.length
    const criteriaLen = this.criteriaList.length
    const evals = this.evaluationMap.evaluations
    this.reason = this.evaluationMap[`${this.view}_reason`]

    console.log('Starting evals', evals)

    // For each candidate set each criteria value
    for (let index = 0; index < len; index++) {
      const candidateKey = this.candidates[index].candidate_key
      this.candidatesEvals[candidateKey] = {}
      this.candidatesEvalsErrors[candidateKey] = {}

      for (let criteriaIndex = 0; criteriaIndex < criteriaLen; criteriaIndex++) {
        const criteria = this.criteriaList[criteriaIndex]
        if (evals && candidateKey in evals && criteria.key in evals[candidateKey]) {
          this.candidatesEvals[candidateKey][criteria.key] = evals[candidateKey][criteria.key]
        } else {
          this.candidatesEvals[candidateKey][criteria.key] = null
        }
        this.candidatesEvalsErrors[candidateKey][criteria.key] = null
      }
    }

    console.log('current candidatesEvals', this.candidatesEvals)
  },

  beforeDestroy() {
    if (this.evalSubscription) {
      this.evalSubscription.destroy()
      window.removeEventListener('beforeunload', this.beforeUnloadAction)
      this.evalSubscription = null
    }
  },

  methods: {
    beforeUnloadAction(event) {
      event.preventDefault()
      if (this.evalSubscription) {
        this.evalSubscription.destroy()
      }
    },

    openApplication(candidateKey) {
      this.$emit('open-application', candidateKey)
    },

    saveEvaluations() {
      if (!this.reason || this.reason.length < 3) {
        this.reasonError = 'Insira uma fundamentação.'
      } else {
        this.reasonError = null
      }

      if (this.errors === 0) {
        this.$emit('save', this.candidatesEvals, this.reason)
      }
    },

    evalChanged(value, candidateKey, criteriumKey) {
      if (!/^\d+(?:\.\d{1,2})?$/.test(value)) {
        if (!this.candidatesEvalsErrors[candidateKey][criteriumKey]) {
          this.errors += 1
        }
        this.candidatesEvalsErrors[candidateKey][criteriumKey] = 'Insira no máximo 2 casas decimais'
      } else if (value == null || value > 100 || value < 0) {
        if (!this.candidatesEvalsErrors[candidateKey][criteriumKey]) {
          this.errors += 1
        }

        this.candidatesEvalsErrors[candidateKey][criteriumKey] = 'Insira um número entre 0 e 100'
      } else {
        if (this.candidatesEvalsErrors[candidateKey][criteriumKey]) {
          this.errors -= 1
        }

        this.candidatesEvalsErrors[candidateKey][criteriumKey] = null
      }

      this.candidatesEvals[candidateKey][criteriumKey] = value
      this.$store.commit('setDirtyData', true)
    },

    toggleEditMode() {
      this.editMode = !this.editMode
    },

    toggleMap(juryKey) {
      if (this.mapIsOpen && !this.isInvalid()) {
        this.$emit('lock-map', juryKey)
      } else if (this.mapIsClosed) {
        this.$emit('open-map', juryKey)
      }
    },

    isInvalid() {
      this.generalError = null
      for (const key in this.candidatesEvals) {
        const evaluations = this.candidatesEvals[key]

        console.log('evaluations', evaluations)

        for (const criteriaKey in evaluations) {
          if (evaluations[criteriaKey] != 0 && !evaluations[criteriaKey]) {
            this.generalError = 'Insira avaliação para todos os candidatos.'
            return true
          }
        }
      }
      return this.errors > 0 || this.reasonError
    },
  },
}
</script>

<style scoped>
.error {
  border-color: #f15f1fff !important;
  border-width: 1px !important;
  border-style: solid !important;
}
.error:focus {
  border-color: transparent;
  box-shadow: none;
}
.applications-window {
  max-height: calc(100vh - 500px);
}
</style>
