<template>
  <Person
    :key="jury.key"
    :person="jury"
    :clickable="canSeeMap"
    class="border-b border-gray-100"
    @click.native="openMap"
  >
    <template #secondline>
      <div class="flex text-sm text-gray-500">
        <div>{{ isPresident ? 'Presidente' : 'Vogal' }}</div>
        <div
          v-if="jury.as_president_substitute === true"
          class="text-xs bg-green-500 px-1.5 py-0.5 text-black text-opacity-60"
        >
          Suplente
        </div>
      </div>
    </template>
    <template #options>
      <div @click.stop>
        <div v-if="!hasCandidates" class="text-gray-500 text-sm px-3">
          Sem candidatos para avaliar
        </div>
        <fw-tag v-else-if="!isPresent" type="light" class="w-24 flex items-center justify-center">
          {{ $t('absent') }}
        </fw-tag>
        <div v-else-if="meetingHasBeenOpened" class="flex justify-end gap-3 items-center">
          <div>
            <fw-button v-if="canSeeMap" type="link" @click.native="openMap">Abrir mapa</fw-button>
          </div>
          <div>
            <b-tooltip v-if="mapIsOpen" type="is-light" position="is-bottom" label="Mapa aberto">
              <fw-tag type="xlight" size="sm"><fw-icon-unlock-line class="h-5 w-5"/></fw-tag>
            </b-tooltip>
            <b-tooltip v-else type="is-primary" position="is-bottom" label="Mapa fechado">
              <fw-tag type="light-primary" size="sm"><fw-icon-lock-solid class="h-5 w-5"/></fw-tag>
            </b-tooltip>
          </div>
        </div>
      </div>
    </template>
  </Person>
</template>

<script>
import Person from '@/fw-modules/fw-core-vue/ui/components/cards/Person'

export default {
  components: {
    Person,
  },
  props: {
    jury: {
      type: Object,
      required: true,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    isPresident: {
      type: Boolean,
      default: false,
    },

    canSeeAllMaps: {
      type: Boolean,
      default: false,
    },

    currentViewChecks: {
      type: Object,
      required: true,
    },

    hasCandidates: {
      type: Boolean,
      default: false,
    },

    view: {
      type: String,
      default: 'curriculum',
    },

    map: {
      type: Object,
      default() {
        return {}
      },
    },
  },

  computed: {
    me() {
      return this.$store.state.session && this.$store.state.session.user ? this.$store.state.session.user : null
    },

    meetingHasBeenOpened() {
      return this.currentViewChecks.meeting_started
    },

    mapStatus() {
      return this.map[`${this.view}_status`]
    },

    mapIsOpen() {
      return this.mapStatus != 'closed'
    },

    isPresent() {
      return this.map[`joined_${this.view}`]
    },

    canSeeMap() {
      return (
        this.currentViewChecks.meeting_started &&
        this.currentViewChecks.jury &&
        this.currentViewChecks.jury.includes(this.jury.jury_key) &&
        (this.canSeeAllMaps || this.jury.key == this.me.key)
      )
    },
  },

  methods: {
    openMap() {
      if (this.canSeeMap) {
        this.$emit('open-map', this.jury)
      }
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "absent": "Ausente"
  },
  "en": {
    "absent": "Absent"
  }
}
</i18n>
