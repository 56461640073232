var render = function render(){var _vm=this,_c=_vm._self._c;return _c('MeetingModal',{key:_vm.meetingData.key ? _vm.meetingData.key : 'new',attrs:{"type-options":_vm.meetingTypes,"modal-title":_vm.meetingGoalOverride === 'interview_notification'
      ? _vm.$t('notifyToInterview')
      : _vm.meetingGoalOverride === 'set_interview'
      ? _vm.$t('setInterview')
      : '',"save-text":_vm.meetingGoalOverride === 'interview_notification'
      ? _vm.$t('notifyCandidates')
      : _vm.meetingGoalOverride === 'set_interview'
      ? _vm.$t('setInterview')
      : _vm.$t('save'),"show-send-message-form":_vm.showSendMessageForm,"confirm-disabled":false,"meeting-data":_vm.meetingData,"message-text":_vm.meetingModalText,"virtual-meeting":_vm.virtualMeetingData,"subject-options":_vm.availableMeetingGoals,"min-date":_vm.minDate},on:{"save":_vm.saveMeeting,"close":function($event){return _vm.$emit('close')}},scopedSlots:_vm._u([(_vm.canNotifyCalendar)?{key:"message",fn:function(){return [_c('div',{staticClass:"px-0.5"},[_c('fw-label',[_vm._v(_vm._s(_vm.$t('message')))]),_c('b-input',{attrs:{"type":"textarea","placeholder":_vm.$t('writeMessage')},model:{value:(_vm.transferMessage),callback:function ($$v) {_vm.transferMessage=$$v},expression:"transferMessage"}}),_c('fw-tip',[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('messageInstructions'))}})])],1)]},proxy:true}:null],null,true)},[(_vm.canNotifyCalendar)?_c('b-checkbox',{model:{value:(_vm.notify),callback:function ($$v) {_vm.notify=$$v},expression:"notify"}},[_vm._v(_vm._s(_vm.$t('notifyCalendarUpdate')))]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }