<template>
  <div v-if="application" class="flex flex-col px-5 py-5 lg:px-8">
    <fw-panel title="Detalhe de candidatura">
      <template v-if="showNextApplication" #toolbar>
        <fw-button
          type="xlight"
          :label="$t('nextApplication')"
          class="flex items-start gap-2"
          @click.native="nextApplication"
        >
          {{ $t('next') }} <fw-icon-arrow-right class="w-5 h-5" />
        </fw-button>
      </template>
      <div class="flex gap-5">
        <fw-avatar :size="'lg'" :user="candidate"></fw-avatar>
        <div class="flex-1 my-2">
          <div class="flex gap-2">
            <fw-heading size="h2" class="flex-1">
              {{ candidate.display_name || candidate.full_name }}
            </fw-heading>
            <div class="flex gap-2 items-center">
              <div
                class="uppercase font-bold text-xs leading-5 py-1.5 px-3 rounded-xl text-gray-500 border border-gray-500"
              >
                {{ candidate.type === 'uc' ? $t('ucAccount') : $t('externalAccount') }}
              </div>
            </div>
          </div>
          <div class="md:flex gap-5">
            <div class="font-medium break-all max-w-lg">{{ candidate.email }}</div>
            <div class="font-medium text-sm flex gap-5 text-gray-500 flex-shrink-0 leading-6">
              <div v-if="candidate.type === 'uc' && candidate.number" class="flex-shrink-0">
                Nº {{ candidate.number }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </fw-panel>

    <template v-if="isApplicationSubmitted">
      <fw-panel
        v-if="canEditApplicationNotes && editingManagerNotes"
        :title="$t('preAdmission')"
        :loading="savingNotes"
        :after-loading-checked="true"
        class="mt-5"
      >
        <template #toolbar>
          <b-dropdown aria-role="list" position="is-bottom-left">
            <fw-button-dropdown slot="trigger" :chevron="false" type="xlight" class="flex flex-col" label="Ações">
              <fw-icon-more class="w-5 h-5" />
            </fw-button-dropdown>
            <b-dropdown-item custom aria-role="menuitem" class="paddingless w-52">
              <fw-button
                type="basic-action"
                size="sm"
                :disabled="!managerNotes.text"
                custom-class="w-full"
                @click.native="removeManagerNotes"
              >
                {{ $t('deletePreEval') }}
              </fw-button>
            </b-dropdown-item>
          </b-dropdown>
        </template>
        <div class="flex flex-col gap-3">
          <div>
            <fw-label>{{ $t('proposal') }}</fw-label>
            <div>
              <OnoffOption
                v-model="managerNotes.status"
                :options="[$t('admit'), $t('reject')]"
                :selected-option="managerNotes.status ? $t('reject') : $t('admit')"
                :disabled="!isCurrentProcManager"
              />
            </div>
          </div>
          <div>
            <fw-label>{{ $t('comments') }}</fw-label>
            <b-input
              v-model="$v.managerNotes.text.$model"
              type="textarea"
              :placeholder="$t('writeComments')"
              :disabled="!isCurrentProcManager"
            />

            <fw-tip v-if="$v.managerNotes.text.$error" error>
              {{ $t('addComment') }}
            </fw-tip>
          </div>
          <div class="flex gap-3 justify-end">
            <fw-button type="link-light" @click.native="editingManagerNotes = false">
              {{ $t('cancel') }}
            </fw-button>
            <fw-button type="light-primary" :disabled="savingNotes" @click.native="saveManagerNotes(false)">
              {{ $t('save') }}
            </fw-button>
            <fw-button type="primary" :disabled="savingNotes" @click.native="saveManagerNotes(true)">
              {{ $t('saveAndClose') }}
            </fw-button>
          </div>
        </div>
      </fw-panel>

      <fw-panel
        v-else
        :title="$t('preAdmissionInfo')"
        :custom-class="
          candidate.manager_admission_review === 'selected'
            ? '!bg-primary'
            : candidate.manager_admission_review === 'rejected'
            ? '!bg-gray-700'
            : '!bg-gray-100'
        "
        boxed="sm"
        class="mt-5"
        :loading="savingNotes"
        :after-loading-checked="true"
      >
        <template v-if="canEditApplicationNotes" #toolbar>
          <fw-button type="link" @click.native="openNotes">
            {{ $t('edit') }}
          </fw-button>
        </template>
        <div v-if="candidate.manager_notes" class="flex flex-col gap-2 font-medium text-white">
          <div>
            <fw-label color="text-white text-opacity-50" marginless>{{ $t('preAdmissionProposal') }}</fw-label>
            <div v-if="candidate.manager_admission_review === 'rejected'" class="font-semibold">
              {{ $t('rejectCandidate') }}
            </div>
            <div v-else-if="candidate.manager_admission_review === 'selected'" class="font-semibold">
              {{ $t('admitCandidate') }}
            </div>
          </div>
          <div>
            <fw-label color="text-white text-opacity-50" marginless>{{ $t('comments') }}</fw-label>
            <div>
              {{ candidate.manager_notes }}
            </div>
          </div>
        </div>
        <fw-panel-info v-else clean centered>{{ $t('noInformation') }}</fw-panel-info>
      </fw-panel>

      <CandidateEvaluations
        class="mt-5"
        :application="application"
        :curriculum-criteria-list="curriculumCriteriaList"
        :interview-criteria-list="interviewCriteriaList"
        :loading="savingData"
        :jury-list="juryList"
        :can-edit-candidates-admission="canEditCandidatesAdmission"
        :can-edit-candidates-presences="canEditCandidatesPresences"
        :can-edit-interview-info="canEditInterviewInfo"
        :with-interview-panel="withInterviewPanel"
        :with-curriculum-panel="withCurriculumPanel"
        @admit-user="admitUser"
        @set-interview-presence="setInterviewPresence"
        @open-rejected-reason="openRejectedReason"
        @set-candidates-interview="setCandidatesInterview"
      />

      <CandidateComplaints
        class="mt-5"
        :application="application"
        :complaint="application.complaints[0]"
        :can-respond="canRespondComplaint"
        :loading="savingMessage"
        @add-message="addMessage"
      />
    </template>

    <fw-panel-info v-else type="dark" centered class="mt-5">
      <div>{{ $t('cancelledApplication') }}</div>
    </fw-panel-info>

    <fw-heading size="h4" muted class="mt-5">{{ $t('metadata') }}</fw-heading>

    <!-- Metadata -->
    <fw-panel :title="$t('personalData')" class="my-3" custom-class="bg-gray-100" boxed="sm">
      <div class="flex flex-col gap-3">
        <div>
          <fw-label marginless>{{ $t('fullName') }}</fw-label>
          <div>{{ candidate.name }}</div>
        </div>

        <div>
          <fw-label marginless>{{ $t('notificationsEmail') }}</fw-label>
          <div v-if="candidate.email">{{ candidate.email }}</div>
          <fw-panel-info v-else clean>{{ $t('notDefined') }}</fw-panel-info>
        </div>

        <div>
          <fw-label marginless>{{ $t('address') }}</fw-label>
          <div v-if="candidate.address_line1">{{ candidate.address_line1 }}</div>
          <fw-panel-info v-else clean>{{ $t('notDefined') }}</fw-panel-info>
          <div v-if="candidate.address_line2">{{ candidate.address_line2 }}</div>
        </div>

        <div class="flex gap-5">
          <div>
            <fw-label marginless>{{ $t('postalCode') }}</fw-label>
            <div v-if="candidate.postal_code">{{ candidate.postal_code }}</div>
            <fw-panel-info v-else clean>{{ $t('notDefined') }}</fw-panel-info>
          </div>
          <div>
            <fw-label marginless>{{ $t('locality') }}</fw-label>
            <div v-if="candidate.locality">{{ candidate.locality }}</div>
            <fw-panel-info v-else clean>{{ $t('notDefined') }}</fw-panel-info>
          </div>
        </div>

        <div>
          <fw-label marginless>{{ $t('country') }}</fw-label>
          <div v-if="candidate.country?.title">{{ candidate.country.title }}</div>
          <fw-panel-info v-else clean>{{ $t('notDefined') }}</fw-panel-info>
        </div>

        <div>
          <fw-label marginless>{{ $t('phoneNumber') }}</fw-label>
          <div v-if="candidate.phone_number">{{ candidate.phone_country.code }} {{ candidate.phone_number }}</div>
          <fw-panel-info v-else clean>{{ $t('notDefined') }}</fw-panel-info>
        </div>

        <div class="flex flex-col">
          <fw-label marginless>{{ $t('identityDoc') }}</fw-label>
          <div class="inline-flex gap-5">
            <div>
              <fw-label marginless>{{ $t('type') }}</fw-label>
              <div v-if="candidate.identity_type">{{ $t(`idType.${candidate.identity_type}`) }}</div>
              <fw-panel-info v-else clean>{{ $t('notDefined') }}</fw-panel-info>
            </div>
            <div>
              <fw-label marginless>{{ $t('number') }}</fw-label>
              <div v-if="candidate.identity_code">{{ candidate.identity_code }}</div>
              <fw-panel-info v-else clean>{{ $t('notDefined') }}</fw-panel-info>
            </div>
            <div>
              <fw-label marginless>{{ $t('expirationDate') }}</fw-label>
              <div v-if="candidate.identity_expire_date">{{ candidate.identity_expire_date | formatDate }}</div>
              <fw-panel-info v-else clean>{{ $t('notDefined') }}</fw-panel-info>
            </div>
          </div>
        </div>

        <div>
          <fw-label marginless>{{ $t('nif') }}</fw-label>
          <div v-if="candidate.nif">{{ candidate.nif }}</div>
          <fw-panel-info v-else clean>{{ $t('notDefined') }}</fw-panel-info>
        </div>

        <div>
          <fw-label marginless>{{ $t('scienceId') }}</fw-label>
          <div v-if="candidate.fct_code">{{ candidate.fct_code }}</div>
          <fw-panel-info v-else clean>{{ $t('notDefined') }}</fw-panel-info>
        </div>
      </div>
    </fw-panel>

    <fw-panel title="Curriculum Vitae" class="my-3" custom-class="bg-gray-100" boxed="xs">
      <div v-if="candidate.files.cv">
        <RecordFileEntry
          v-for="file in candidate.files.cv"
          :key="file.key"
          :can-edit="false"
          :can-remove="false"
          :allow-classified="false"
          :file="file"
          @download="downloadFile(file)"
        />
      </div>
      <fw-panel-info v-else clean centered class="p-1.5">{{ $t('noFiles') }}</fw-panel-info>
    </fw-panel>

    <fw-panel :title="$t('habilitationsCertificate')" class="my-3" custom-class="bg-gray-100" boxed="sm">
      <div>
        <fw-label marginless>{{ $t('habilitationsLevel') }}</fw-label>
        <div v-if="candidate.habilitation_level">
          {{ $t(`certificates.options.${candidate.habilitation_level}`) }}
        </div>
        <fw-panel-info v-else clean>{{ $t('notDefined') }}</fw-panel-info>
      </div>

      <div class="my-3">
        <fw-label marginless>{{ $t('scientificArea') }}</fw-label>
        <div v-if="candidate.scientific_area">{{ candidate.scientific_area }}</div>
        <fw-panel-info v-else clean>{{ $t('notDefined') }}</fw-panel-info>
      </div>

      <div>
        <fw-label marginless>{{ $t('certificatesCopies') }}</fw-label>
        <div v-if="candidate.files.certificate">
          <RecordFileEntry
            v-for="file in candidate.files.certificate"
            :key="file.key"
            :can-edit="false"
            :can-remove="false"
            :allow-classified="false"
            :file="file"
            @download="downloadFile(file)"
          />
        </div>
        <fw-panel-info v-else clean>{{ $t('noFiles') }}</fw-panel-info>
      </div>
    </fw-panel>

    <fw-panel :title="$t('motivationLetter')" class="my-3" custom-class="bg-gray-100" boxed="xs">
      <div v-if="candidate.files.letter_of_interest">
        <RecordFileEntry
          v-for="file in candidate.files.letter_of_interest"
          :key="file.key"
          :can-edit="false"
          :can-remove="false"
          :allow-classified="false"
          :file="file"
          @download="downloadFile(file)"
        />
      </div>
      <fw-panel-info v-else clean centered class="p-1.5">{{ $t('noFiles') }}</fw-panel-info>
    </fw-panel>

    <fw-panel :title="$t('recommendationLetter')" class="my-3" custom-class="bg-gray-100" boxed="xs">
      <div v-if="candidate.files.recommendation_letter">
        <RecordFileEntry
          v-for="file in candidate.files.recommendation_letter"
          :key="file.key"
          :can-edit="false"
          :can-remove="false"
          :allow-classified="false"
          :file="file"
          @download="downloadFile(file)"
        />
      </div>
      <fw-panel-info v-else clean centered class="p-1.5">{{ $t('noFiles') }}</fw-panel-info>
    </fw-panel>

    <fw-panel :title="$t('otherFiles')" class="my-3" custom-class="bg-gray-100" boxed="xs">
      <div v-if="candidate.files.other">
        <RecordFileEntry
          v-for="file in candidate.files.other"
          :key="file.key"
          :can-edit="false"
          :can-remove="false"
          :allow-classified="false"
          :file="file"
          @download="downloadFile(file)"
        />
      </div>
      <fw-panel-info v-else clean centered class="p-1.5">{{ $t('noFiles') }}</fw-panel-info>
    </fw-panel>

    <fw-panel :title="$t('declarationHonor')" class="my-3" custom-class="bg-gray-100" boxed="sm">
      <fw-label marginless>{{ this.$t('acceptanceDocumentsAuthenticity') }}</fw-label>
      <div class="mb-2">{{ candidate.acceptance_documents_authenticity ? $t('yes') : $t('no') }}</div>

      <fw-label marginless>{{ this.$t('acceptRequirements') }}</fw-label>
      <div class="mb-2">{{ candidate.acceptance_requirements ? $t('yes') : $t('no') }}</div>

      <fw-label marginless>{{ this.$t('acceptAuthenticity') }}</fw-label>
      <div class="mb-2">{{ candidate.accept_authenticity ? $t('yes') : $t('no') }}</div>

      <fw-label marginless>{{ this.$t('acceptNotifications') }}</fw-label>
      <div class="mb-2">{{ candidate.accept_notifications ? $t('yes') : $t('no') }}</div>

      <fw-label marginless>{{ this.$t('acceptCourseVerify') }}</fw-label>
      <div class="mb-2">{{ candidate.accept_course_verify ? $t('yes') : $t('no') }}</div>

      <fw-label marginless>{{ this.$t('isHandicap') }}</fw-label>
      <div>{{ candidate.is_handycap ? $t('yes') : $t('no') }}</div>
    </fw-panel>

    <fw-panel :title="$t('teleconference')" class="my-3" custom-class="bg-gray-100" boxed="sm"
      ><fw-label marginless>{{ this.$t('acceptTeleconference') }}</fw-label>
      <div>{{ candidate.remote_interview ? $t('yes') : $t('no') }}</div></fw-panel
    >

    <fw-panel :title="$t('previousScholarships')" class="my-3" custom-class="bg-gray-100" boxed="sm">
      <fw-label marginless>{{ $t('previousScholarshipsReceived') }}</fw-label>
      <div>{{ candidate.has_previous_scholarships ? $t('yes') : $t('no') }}</div>

      <div v-if="candidate.has_previous_scholarships">
        <div v-for="entry in candidate.other_scholarships" :key="entry.key" class="mt-2 flex flex-col gap-3">
          <div>
            <fw-label marginless>{{ $t('scholarshipsType.label') }}</fw-label>
            <div>{{ $t(`scholarshipsType.${entry.type}`) }}</div>
          </div>
          <div>
            <fw-label marginless>{{ $t('entity') }}</fw-label>
            <div v-if="entry.entity">{{ entry.entity }}</div>
            <fw-panel-info v-else clean>{{ $t('notDefined') }}</fw-panel-info>
          </div>

          <div class="inline-flex gap-5">
            <div>
              <fw-label marginless>{{ $t('from') }}</fw-label>
              <div>{{ entry.start_date | formatDate }}</div>
            </div>

            <div>
              <fw-label marginless>{{ $t('to') }}</fw-label>
              <div>{{ entry.end_date | formatDate }}</div>
            </div>
          </div>

          <div>
            <fw-label marginless>{{ $t('entity') }}</fw-label>
            <div v-if="entry.description.length > 0" v-html="entry.description"></div>
            <fw-panel-info v-else clean>{{ $t('notDefined') }}</fw-panel-info>
          </div>
        </div>
      </div>
    </fw-panel>

    <fw-panel title="Curso" class="my-3" custom-class="bg-gray-100" boxed="sm">
      <fw-label marginless>{{ $t('type') }}</fw-label>
      <div v-if="candidate.enrolled_course?.type" class="mb-2">
        {{ $t(`enrolledCourse.${candidate.enrolled_course.type}`) }}
      </div>
      <fw-panel-info v-else clean class="mb-2">{{ $t('notDefined') }}</fw-panel-info>

      <div v-if="candidate.enrolled_course.type != 'not_applicable'">
        <fw-label marginless>{{ $t('designation') }}</fw-label>
        <div v-if="candidate.enrolled_course.name" class="mb-2">{{ candidate.enrolled_course.name }}</div>
        <fw-panel-info v-else clean class="mb-2">{{ $t('notDefined') }}</fw-panel-info>

        <fw-label marginless>{{ $t('academicYear') }}</fw-label>
        <div v-if="candidate.enrolled_course.academic_year" class="mb-2">
          {{ candidate.enrolled_course.academic_year }}
        </div>
        <fw-panel-info v-else clean class="mb-2">{{ $t('notDefined') }}</fw-panel-info>

        <fw-label marginless>{{ $t('institution') }}</fw-label>
        <div v-if="candidate.enrolled_course.institution" class="mb-2">{{ candidate.enrolled_course.institution }}</div>
        <fw-panel-info v-else clean class="mb-2">{{ $t('notDefined') }}</fw-panel-info>
      </div>
    </fw-panel>

    <fw-panel :title="$t('extraInfo')" class="my-3" custom-class="bg-gray-100" boxed="sm">
      <fw-panel-info v-if="!candidate.user_notes" clean>{{ $t('notDefined') }}</fw-panel-info>
      <div v-else v-html="candidate.user_notes"></div>
    </fw-panel>

    <slot></slot>

    <fw-panel-info debug label="Candidate data (raw)" class="my-5">
      <json-viewer :value="{ application }"></json-viewer>
    </fw-panel-info>
  </div>
</template>

<script>
import CandidateComplaints from '@/components/panels/CandidateComplaints'
import CandidateEvaluations from '@/components/panels/CandidateEvaluations'
import FwAvatar from '@/fw-modules/fw-core-vue/ui/components/FwAvatar'
import RecordFileEntry from '@/fw-modules/fw-core-vue/ui/components/form/RecordFileEntry'
import OnoffOption from '@/fw-modules/fw-core-vue/ui/components/form/onoffOption'
import { required, minLength } from 'vuelidate/lib/validators'

import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import ServiceStorage from '@/fw-modules/fw-core-vue/storage/services/ServiceStorage'

export default {
  components: {
    FwAvatar,
    RecordFileEntry,
    OnoffOption,
    CandidateEvaluations,
    CandidateComplaints,
  },

  props: {
    application: {
      type: Object,
      required: true,
    },
    curriculumCriteriaList: {
      type: Array,
      default: () => {
        return []
      },
    },
    interviewCriteriaList: {
      type: Array,
      default: () => {
        return []
      },
    },
    juryList: {
      type: Array,
      default: () => {
        return []
      },
    },
    showNextApplication: {
      type: Boolean,
      default: true,
    },
    withCurriculumPanel: {
      type: Boolean,
      default: false,
    },
    withInterviewPanel: {
      type: Boolean,
      default: false,
    },
    canEdit: {
      type: Boolean,
      default: false,
    },
    canEditApplicationNotes: {
      type: Boolean,
      default: false,
    },
    isCurrentProcManager: {
      type: Boolean,
      default: false,
    },
    canEditInterviewInfo: {
      type: Boolean,
      default: false,
    },
    canEditCandidatesAdmission: {
      type: Boolean,
      default: false,
    },
    canEditCandidatesPresences: {
      type: Boolean,
      default: false,
    },
    canRespondComplaint: {
      type: Boolean,
      default: false,
    },
    savingData: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      savingMessage: false,
      editingManagerNotes: false,
      savingNotes: false,
      managerNotes: {
        text: '',
        status: 0,
      },
    }
  },

  validations: {
    managerNotes: {
      text: { required, min: minLength(2) },
    },
  },

  computed: {
    candidate() {
      return this.application.candidate
    },
    applicationKey() {
      return this.candidate.candidate_key
    },
    isApplicationSubmitted() {
      return this.candidate.application_status == 'submitted'
    },
  },

  methods: {
    async downloadFile(file) {
      const url = ServiceStorage.getFileUrl(file, this.$store.state.session.user.token)
      utils.downloadFile(url, file.filename)
    },

    admitUser(rawValue) {
      const value = rawValue === 0
      if (value) {
        this.candidate.admission_status = 'selected'
      } else {
        this.candidate.admission_status = 'rejected'
      }

      this.$emit('admit-user', this.applicationKey, value)
    },

    setInterviewPresence(rawValue) {
      this.$emit('set-interview-presence', this.applicationKey, rawValue)
    },

    openRejectedReason() {
      this.$emit('open-rejected-reason', this.application.candidate)
    },

    nextApplication() {
      this.$emit('next-application', this.applicationKey)
    },

    removeManagerNotes() {
      this.candidate.manager_admission_review = null
      this.candidate.manager_notes = null
      this.managerNotes = {
        text: null,
        status: 0,
      }
      this.$emit('delete-manager-notes', this.applicationKey)
      this.editingManagerNotes = false
    },

    openNotes() {
      // this.$v.managerNotes.$reset()
      console.log('openNotes', this.application.candidate)
      this.managerNotes = {
        text: this.candidate.manager_notes || '',
        status: this.candidate.manager_admission_review !== 'selected',
      }
      this.editingManagerNotes = true
    },

    saveManagerNotes(close) {
      this.$v.managerNotes.$touch()

      if (!this.$v.managerNotes.$invalid) {
        this.savingNotes = true

        this.candidate.manager_admission_review = this.managerNotes.status == 0 ? 'selected' : 'rejected'
        this.candidate.manager_notes = this.managerNotes.text

        this.$emit('save-manager-notes', this.applicationKey, {
          manager_admission_review: this.managerNotes.status == 0 ? 'selected' : 'rejected',
          manager_notes: this.managerNotes.text,
        })

        if (close) this.editingManagerNotes = false

        setTimeout(() => {
          this.savingNotes = false
        }, 500)
      }
    },

    setCandidatesInterview(candidate) {
      this.$emit('set-candidates-interview', candidate)
    },

    addMessage(complaintKey, newMessage) {
      this.savingMessage = true

      this.$emit('add-complaint-message', complaintKey, newMessage)

      setTimeout(() => {
        this.savingMessage = false
      }, 500)
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "acceptRequirements": "Declaro que reúno todos os requisitos dispostos no aviso de abertura para a contratualização da presente bolsa, incluindo a inscrição em curso conferente/não conferente de grau.",
    "acceptCourseVerify": "Declaro que estou em condições de comprovar, no momento da contratualização da bolsa, a frequência no(s) curso(s) que integram os requisitos de admissão, definidos em edital.",
    "acceptanceDocumentsAuthenticity": "Declaro, sob compromisso de honra, que é autêntica toda a informação e documentação incluídas na candidatura, sem prejuízo da efetiva comprovação, sempre que solicitada.",
    "acceptNotifications": "Declaro que consinto que todas as notificações efetuadas no âmbito do procedimento a que ora me candidato sejam feitas através de correio eletrónico para o endereço indicado na candidatura e através de notificação eletrónica automaticamente gerada pela presente plataforma.",
    "acceptTeleconference": "Caso resida a mais de 500 km de Coimbra, e caso o pretenda, requer a possibilidade de a sua Audição Pública, a existir, ocorrer por teleconferência.",
    "acceptAuthenticity": "Declaro que possuo o grau/a inscrição em grau referido no aviso de abertura, para a admissão, à presente bolsa.",
    "isHandicap": "Declaro, sob compromisso de honra, que sou detentor de deficiência e indico no campo 'Informações adicionais' o respetivo grau de incapacidade, o tipo de deficiência e os meios de comunicação/expressão a utilizar no processo de seleção, nos termos do Decreto -Lei n.º 29/2001, de 3 de fevereiro, bem como anexo o comprovativo da minha condição.",
    "scholarshipsType": {
      "label": "Tipo de bolsa",
      "research_initiation": "Bolsa de iniciação à investigação",
      "research": "Bolsa de investigação",
      "post_doctoral": "Bolsa de investigação pós-doutoral",
      "other": "Outra"
    },
    "enrolledCourse": {
      "technical_course": "Curso técnico superior profissional",
      "other": "Curso não conferente de grau académico integrado no projeto educativo de uma instituição de ensino superior, desenvolvido em associação ou cooperação com uma ou várias unidades de I&D",
      "graduate": "Licenciatura",
      "integrated_master": "Mestrado integrado",
      "master": "Mestrado",
      "phd": "Doutoramento",
      "not_applicable": "Não aplicável"
    },
    "idType": {
      "cc": "Cartão de cidadão",
      "passport": "Passaporte"
    },
    "certificates": {
      "options": {
        "bachelor": "Bacharelato",
        "graduation": "Licenciatura",
        "master": "Mestrado",
        "phd": "Doutoramento",
        "not_applicable": "Não aplicável"
      }
    },
    "next": "Próxima",
    "nextApplication": "Próxima candidatura",
    "ucAccount": "Conta UC",
    "externalAccount": "Conta Externa",
    "preAdmission": "Análise prévia",
    "preAdmissionInfo": "Informações da análise prévia",
    "deletePreEval": "Limpar pré-análise dos requisitos legais de admissão",
    "proposal": "Proposta",
    "admit": "Admitir",
    "reject": "Excluir",
    "comments": "Comentários",
    "writeComments": "Escreva aqui os seus comentários sobre esta candidatura.",
    "addComment": "Insira um comentário.",
    "preAdmissionProposal": "Proposta da pré-análise dos requisitos legais de admissão",
    "rejectCandidate": "Excluir candidato/a",
    "admitCandidate": "Admitir candidato/a",
    "noInformation": "Não foi definida informação.",
    "cancelledApplication": "Candidatura cancelada ou anulada",
    "metadata": "Metadados",
    "noFiles": "Não existem ficheiros associados a esta secção.",
    "personalData": "Dados pessoais",
    "fullName": "Nome completo",
    "notificationsEmail": "Email para notificações",
    "address": "Morada",
    "postalCode": "Código Postal",
    "locality": "Localidade",
    "country": "País",
    "phoneNumber": "Telefone",
    "identityDoc": "Documentos de identificação",
    "type": "Tipo",
    "number": "Número",
    "expirationDate": "Data de expiração",
    "nif": "NIF",
    "scienceId": "Ciência ID",
    "habilitationsCertificate": "Certificados de habilitações",
    "habilitationsLevel": "Nível habilitacional",
    "scientificArea": "Área Científica",
    "certificatesCopies": "Cópias dos certificados",
    "motivationLetter": "Carta de motivação",
    "recommendationLetter": "Carta(s) de recomendação",
    "otherFiles": "Ficheiros adicionais",
    "declarationHonor": "Declaração de honra",
    "teleconference": "Teleconferência",
    "previousScholarships": "Bolsas anteriores",
    "previousScholarshipsReceived": "Bolsas anteriormente recebidas",
    "entity": "Entidade",
    "designation": "Designação",
    "academicYear": "Ano letivo",
    "institution": "Instituição",
    "extraInfo": "Informações adicionais",
    "notDefined": "Não definido.",
    "from": "De",
    "to": "A",
    "yes": "Sim",
    "no": "Não",
    "cancel": "Cancelar",
    "save": "Guardar",
    "saveAndClose": "Guardar e fechar",
    "edit": "Editar"
  },
  "en": {
    "acceptRequirements": "I declare that I meet all the requirements set out in the opening notice for the contracting of this scholarship, including enrollment in a degree granting/non-degree granting course.",
    "acceptCourseVerify": "I declare that I am able to prove, at the moment of the contractualization of the scholarship, the attendance in the course(s) that integrate the admission requirements, defined in the announcement.",
    "acceptTeleconference": "As I officially reside at more than 500 km from Coimbra, I request that the interview, if applicable, to be held by teleconference.",
    "acceptanceDocumentsAuthenticity": "I swear that all the information and documents provided in the application are authentic, without prejudice of making proof upon request",
    "acceptNotifications": "I accept all notifications made under the procedure to which I hereby apply shall be made by electronic mail to the address indicated in the application and by electronic notification automatically generated by this platform.",
    "acceptAuthenticity": "I declare that I have the degree/registration in the degree mentioned in the opening notice, for admission, to this scholarship.",
    "isHandicap": "I swear that I have a disability and I indicate in the 'Additional information' field the degree of my disability, the type of disability and the means of communication to be used in the selection process, in accordance with Decree-Law no. 29/2001 of 3rd February. I also attach proof of my disability.",
    "scholarshipsType": {
      "label": "Scholarship type",
      "research_initiation": "Research Initiation Scholarship",
      "research": "Research grant",
      "post_doctoral": "Post-doctoral research fellowship",
      "other": "Other"
    },
    "enrolledCourse": {
      "technical_course": "Professional higher technical course",
      "other": "Non-degree course integrated in the educational project of a higher education institution, developed in association or cooperation with one or more R&D units",
      "graduate": "Bachelor's Degree",
      "integrated_master": "Integrated Master",
      "master": "Master",
      "phd": "PHD",
      "not_applicable": "Not applicable"
    },
    "idType": {
      "cc": "Citizen ID card",
      "passport": "Passport"
    },
    "certificates": {
      "options": {
        "bachelor": "Bachelor's Degree",
        "graduation": "Graduation",
        "master": "Master",
        "phd": "PHD",
        "not_applicable": "Not applicable"
      }
    },
    "next": "Next",
    "nextApplication": "Next application",
    "ucAccount": "UC account",
    "externalAccount": "External Account",
    "preAdmission": "Pre-analysis of legal admission requirements",
    "preAdmissionInfo": "Pre-analysis of legal admission requirements information",
    "deletePreEval": "Delete pre-evaluation",
    "proposal": "Proposal",
    "admit": "Admit",
    "reject": "Exclude",
    "comments": "Comments",
    "writeComments": "Write here your comments about this application.",
    "addComment": "Enter a comment.",
    "preAdmissionProposal": "Pre-analysis of legal admission requirements proposal",
    "rejectCandidate": "Exclude candidate",
    "admitCandidate": "Admit candidate",
    "noInformation": "No information has been defined.",
    "cancelledApplication": "Cancelled or annulled application",
    "metadata": "Metadata",
    "noFiles": "There are no files associated with this section.",
    "personalData": "Personal data",
    "fullName": "Full Name",
    "notificationsEmail": "Notifications e-mail",
    "address": "Address",
    "postalCode": "Postal Code",
    "locality": "Location",
    "country": "Country",
    "phoneNumber": "Phone",
    "identityDoc": "ID Documents",
    "type": "Type",
    "number": "Number",
    "expirationDate": "Expiration Date",
    "nif": "VAT number",
    "scienceId": "Science ID",
    "habilitationsCertificate": "Qualification Certificates",
    "habilitationsLevel": "Qualification level",
    "scientificArea": "Scientific Area",
    "certificatesCopies": "Copies of certificates",
    "motivationLetter": "Motivation Letter",
    "recommendationLetter": "Letter(s) of recommendation",
    "otherFiles": "Additional files",
    "declarationHonor": "Declaration of Honor",
    "teleconference": "Teleconference",
    "previousScholarships": "Past scholarships",
    "previousScholarshipsReceived": "Previously received scholarships",
    "entity": "Entity",
    "designation": "Designation",
    "academicYear": "School Year",
    "institution": "Institution",
    "extraInfo": "Additional Information",
    "notDefined": "Not defined.",
    "from": "From",
    "to": "To",
    "yes": "Yes",
    "no": "No",
    "cancel": "Cancel",
    "save": "Save",
    "saveAndClose": "Save and close",
    "edit": "Edit"
  }
}
</i18n>
