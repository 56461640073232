<template>
  <PanelNotifications :inject-payload="activeFilters" />
</template>

<script>
import PanelNotifications from '@/fw-modules/fw-core-vue/ui/components/panels/PanelNotifications'

export default {
  components: {
    PanelNotifications,
  },

  props: {
    procedureKey: {
      type: String,
    },
  },

  data() {
    return {
      activeFilters: {
        procedure: this.procedureKey,
      },
    }
  },
}
</script>
