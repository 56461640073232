<template>
  <section v-if="procedure">
    <fw-panel :title="$t('updates')" featured boxed="xs" custom-class="bg-white p-0">
      <template v-if="canEdit" #toolbar>
        <fw-button type="link" @click.native="newUpdate">
          {{ $t('add') }}
        </fw-button>
      </template>
      <fw-panel-info v-if="!loading && !updates.length" type="basic" class="text-center text-gray-500">
        {{ $t('noUpdates') }}
      </fw-panel-info>
      <div v-else>
        <CardUpdateEntry
          v-for="(update, u) in updates"
          :key="'update_' + u"
          :entry-key="'update_' + u"
          :entry="update"
          :procedure="procedure"
          :can-edit="canEdit"
          @edited-update="$emit('get-procedure-data')"
          @delete-update="deleteUpdate"
        />
      </div>
    </fw-panel>

    <fw-panel-info v-if="updates && updates.length" debug label="Updates data (raw)" class="my-5">
      <json-viewer :value="{ updates }"></json-viewer>
    </fw-panel-info>

    <b-modal
      :active="activeModal == 'new_update'"
      scroll="keep"
      :can-cancel="true"
      trap-focus
      :destroy-on-hide="true"
      aria-role="dialog"
      aria-modal
      :width="700"
      :on-cancel="closeModal"
      :custom-class="'rounded-buefy-modal'"
    >
      <ModalProcedureUpdateEntry
        :procedure-key="procedure.key"
        :can-edit="canEdit"
        :procedure="procedure"
        class="min-h-full"
        @new-update="addNewUpdate"
        @edited-update="$emit('get-procedure-data')"
        @close="closeModal"
      ></ModalProcedureUpdateEntry>
    </b-modal>
  </section>
</template>

<script>
import ModalProcedureUpdateEntry from '@/components/modals/ModalProcedureUpdateEntry'
import CardUpdateEntry from '@/components/cards/CardUpdateEntry'

export default {
  components: {
    ModalProcedureUpdateEntry,
    CardUpdateEntry,
  },

  props: {
    procedure: {
      type: Object,
      require: true,
    },
    canEdit: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      loading: true,
      updates: [],
      activeModal: null,
    }
  },

  computed: {
    api() {
      return this.$store.state.api.base
    },
  },

  created() {
    this.getUpdates()
  },

  methods: {
    addNewUpdate(data) {
      this.updates.push(data)
      this.$emit('get-procedure-data')
    },
    newUpdate() {
      this.editingUpdate = null
      this.activeModal = 'new_update'
    },
    async getUpdates() {
      this.loading = true
      this.updates = await this.api.getProcedureUpdates(this.procedure.key)
      setTimeout(() => {
        this.loading = false
      }, 500)
    },
    async deleteUpdate(update, u) {
      console.log(`Deleting update ${u}`)
      try {
        await this.api.deleteProcedureUpdate(this.procedure.key, update.key)
        // this.updates.splice(u, 1)
        this.getUpdates()

        this.$buefy.snackbar.open({
          message: 'Atualização eliminada com sucesso.',
          type: 'is-primary',
        })
        this.$emit('get-procedure-data')
      } catch (e) {
        console.error(e)
        this.$buefy.snackbar.open({
          message: 'Ocorreu um erro ao apagar uma atualização.',
          type: 'is-danger',
        })
      }
    },
    closeModal() {
      this.activeModal = null
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "updates": "Publicações",
    "add": "Adicionar",
    "noUpdates": "Não existem publicações para apresentar."
  },
  "en": {
    "updates": "Publications",
    "add": "Add",
    "noUpdates": "There are no publications to show."
  }
}
</i18n>
